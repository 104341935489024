import { parse } from 'query-string';
import appendQuery from 'append-query';

import { SINGLE_SHOE_COOKIE } from 'constants/cookies';
import { err, setError } from 'actions/errors';
import { generateSeoOptimizedData } from 'actions/seoOptimizedData';
import { setAndStoreCookie, setSessionCookies } from 'actions/session';
import { stripAppRoot } from 'history/AppRootUtils';
import { clearSavedFilters, updateSavedFilters } from 'actions/search';
import { fireSearchPixels, receiveAndDeferSearchResponse, receiveSearchResponse, requestSearch } from 'actions/products';
import { makeQueryStringSearchTerm } from 'helpers';
import { ALL_REDIRECTED_KEY, breakdownNonZso, combineQueryParams, formatSavedFilters, searchRedirectedNoResultsUrl } from 'helpers/SearchUtils';
import { convertPageParamToUrlPath } from 'helpers/ClientUtils';
import { slashSearchProducts, zsoSearchProducts } from 'apis/calypso';
import { deleteFiltersFromOpal, saveFiltersToOpal } from 'apis/opal';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import marketplace from 'cfg/marketplace.json';
import { redirectTo } from 'actions/redirect';
import { blacklistedSearch } from 'actions/blacklistedSearch';
import { OOS_REDIRECTED_RE, ZSO_URL_RE } from 'common/regex';
import { evSearchPageView } from 'events/search';
import { selectCalypsoConfig } from 'selectors/environment';

const {
  search: { codesTo404, hasSingleShoes }
} = marketplace;

export function formatGenderValueForSearchPixel(v) {
  return v
    .toLowerCase()
    .replace(/^(wo)?men'?s$/, '$1men')
    .replace(/^kid('s)?$/, 'kids');
}

/** helper function for pulling facet data out of the state for the search pixel */
export function makeSearchPixelFacetDataFromState(state) {
  const ret = {};

  // eslint-disable-next-line camelcase
  const genderFacet = state.filters?.selected?.singleSelects?.txAttrFacet_Gender;
  if (genderFacet && genderFacet.length === 1) {
    ret.gender = formatGenderValueForSearchPixel(genderFacet[0]);
  }

  const singleSelectZc1 = state.filters?.selected?.singleSelects?.zc1;
  if (singleSelectZc1) {
    ret.subcategories = singleSelectZc1;
  }
  if (!ret.subcategories) {
    const facets = state.facets?.toDisplay;
    if (facets?.length > 0) {
      const zc1List = facets.find(f => f.facetField === 'zc1');
      if (zc1List) {
        ret.subcategories = zc1List.values.map(obj => obj.name);
      }
    }
  }

  if (ret.subcategories?.length === 1) {
    const category = ret.subcategories[0];
    if (!/\s/.test(category)) {
      ret.category = category.toLowerCase();
    }
  }

  return ret;
}

/**
 * Determines if a failed calypso call should 404
 */
export function translatePatronErrorCode(statusCode) {
  return codesTo404.indexOf(statusCode) >= 0 ? 404 : 500;
}

/**
 * Dispatches a custom event intended for the ZFGA stack to control the text of the header term.
 * @param {String} term to set the header search to.
 * @param {Document} doc The doucment to dispatch the event from.
 */
export function setHeaderSearchTerm(term, doc = document, EventConstructor = CustomEvent) {
  doc.dispatchEvent(new EventConstructor('marty_set_search', { detail: { searchTerm: term } }));
}

export const shouldRedirectToPdp = location => parse(location.search)?.term || OOS_REDIRECTED_RE.test(location.search);

/**
 * Fetch search results and facets from a slash route
 * @param  {object}   location                             location object
 * @param  {function} [searchProducts=slashSearchProducts] slashSearch api call function
 * @return {object}                                        promise
 */
export function fetchFromSearch({
  location,
  isFresh,
  limit = null,
  bypassCache = false,
  shouldAppendResults = false,
  searchProducts = slashSearchProducts,
  isSearchHappeningServerSide
}) {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      filters: currentFilters,
      cookies,
      products: { productLimit }
    } = appState;
    const calypso = selectCalypsoConfig(appState);
    const { urlFilterMapping: filterCache, wasSaveFiltersToggled } = currentFilters;
    const path = stripAppRoot(location.pathname).replace('/filters', '');
    const completeUrl = path + location.search;
    const filterForPath = filterCache[completeUrl];
    const parsedParams = breakdownNonZso(location.pathname, location.search);

    if (filterForPath && !bypassCache && !wasSaveFiltersToggled) {
      return Promise.resolve();
    } else {
      dispatch(requestSearch({ url: completeUrl, isFresh }));
      const cleanLocation = Object.assign({}, location, { pathname: path });
      const criteria = {
        location: cleanLocation,
        page: parsedParams.page,
        limit: limit || productLimit
      };

      return searchProducts({ calypso, criteria, cookies, state: appState })
        .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
        .then(fetchErrorMiddleware)
        .then(response => {
          processReceivedSearchResponse(response, dispatch, getState, parsedParams, completeUrl, shouldAppendResults, isSearchHappeningServerSide);
        })
        .catch(e => dispatch(setError(err.GENERIC, e, translatePatronErrorCode(e.status))));
    }
  };
}

export function processReceivedSearchResponse(
  response,
  dispatch,
  getState,
  parsedParams,
  completeUrl,
  shouldAppendResults,
  isSearchHappeningServerSide = false
) {
  const originalTerm = response.originalTerm || parsedParams.originalTerm || '';

  // make sure we didn't do a term search
  if (!completeUrl?.includes('?term')) {
    const allRedirected = parsedParams[ALL_REDIRECTED_KEY];

    /*  no search results from a filtered search - redirect to a term search
     ** only redirect once, if url contains the redirected flag do not redirect again
     */
    if (response.results?.length === 0 && !allRedirected) {
      dispatch(redirectTo(searchRedirectedNoResultsUrl(originalTerm)));
      return;
    }
  }

  if (isSearchHappeningServerSide) {
    dispatch(receiveAndDeferSearchResponse({ response, parsedParams, completeUrl }));
    return;
  }

  if (response.blacklisted) {
    dispatch(blacklistedSearch(originalTerm));
    return;
  }

  const appState = getState();
  const {
    filters: { page }
  } = appState;

  const term = response.term || '';

  const { executedSearchUrl: originalExecutedSearchUrl } = response;
  let executedSearchUrl = ZSO_URL_RE.test(originalExecutedSearchUrl)
    ? originalExecutedSearchUrl
    : convertPageParamToUrlPath(originalExecutedSearchUrl);

  if (parsedParams.queryParams) {
    executedSearchUrl = appendQuery(executedSearchUrl, parsedParams.queryParams, { encodeComponents: false });
  }

  const seoOptimizedData = generateSeoOptimizedData(response);

  if (response?.singleShoeRedirect && hasSingleShoes) {
    const { cookies } = appState;
    const isRecognizedCustomer = !!cookies['x-main'];
    const curRedirectCount = cookies[SINGLE_SHOE_COOKIE];
    const newCookieVal = isRecognizedCustomer && curRedirectCount ? parseInt(curRedirectCount, 10) + 1 : 1;
    if (newCookieVal < 4) {
      if (isRecognizedCustomer) {
        // expire cookie in a month
        const curDate = new Date();
        curDate.setDate(curDate.getDate() + 30);
        dispatch(setAndStoreCookie(SINGLE_SHOE_COOKIE, newCookieVal, curDate));
      }
      dispatch(redirectTo(response.singleShoeRedirect));
    }
  }

  const resp = {
    ...response,
    executedSearchUrl,
    originalTerm,
    seoOptimizedData,
    term,
    customerPreferences: response.customerPreferences || null,
    sort: getAppliedSort(parsedParams, response),
    url: completeUrl,
    page,
    shouldAppendResults
  };

  dispatch(receiveSearchResponse(resp));
  const state = getState();
  evSearchPageView(state);
  const pixelFacetData = makeSearchPixelFacetDataFromState(state);
  dispatch(fireSearchPixels(term, state.products.list, pixelFacetData, state.filters));
  return response;
}

/**
 * Pulls the sort parameters off of the search response, if it exists, otherwise uses the sort provided by the input query.
 * @param  {Object} parsedParams   THe search parameters pulled from a location URL.
 * @param  {Object} searchResponse The slash search api response
 * @return {Object}                sort object with keys being the field and value being the direction
 */
function getAppliedSort(parsedParams, searchResponse) {
  if (searchResponse.sorts && searchResponse.sorts.length) {
    const sortVal = {};
    searchResponse.sorts.forEach(sortObj => (sortVal[sortObj.field] = sortObj.direction));
    return sortVal;
  }
  return parsedParams.sort;
}

/**
 * Fetch search results and facets from a ZSO
 * @param  {object}   location                      location object
 * @param  {function} [zsoSearch=zsoSearchProducts] zsoSearch api call function
 * @return {object}                                 promise
 */
export function fetchFromZso({ location, isFresh, bypassCache = false, shouldAppendResults = false, zsoSearch = zsoSearchProducts }) {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      filters: { urlFilterMapping: filterCache },
      cookies,
      products: { productLimit }
    } = appState;
    const calypso = selectCalypsoConfig(appState);

    const path = stripAppRoot(location.pathname);
    const query = parse(location.search);
    const completeUrl = path + location.search;
    const filterForPath = filterCache[completeUrl];

    const criteria = { path, query, limit: productLimit };

    if (filterForPath && !bypassCache) {
      return Promise.resolve();
    } else {
      dispatch(requestSearch({ url: completeUrl, isFresh }));
      return zsoSearch(calypso, criteria, cookies, appState)
        .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
        .then(fetchErrorMiddleware)
        .then(response => {
          processReceivedZsoResponse({ query, url: completeUrl, shouldAppendResults }, response, dispatch, getState);
          return response;
        })
        .catch(e => dispatch(setError(err.GENERIC, e, translatePatronErrorCode(e.status))));
    }
  };
}

export function processReceivedZsoResponse({ query = {}, term, url, shouldAppendResults = false }, response, dispatch, getState) {
  const { t, ot } = query;
  term = term || makeQueryStringSearchTerm(t || ot || '');
  const executedSearchUrl = combineQueryParams(response.executedSearchUrl, query);
  const seoOptimizedData = generateSeoOptimizedData(response);

  const resp = {
    ...response,
    executedSearchUrl,
    term,
    seoOptimizedData,
    customerPreferences: response.customerPreferences || null,
    sort: getAppliedSort({ sort: {} }, response),
    url,
    shouldAppendResults
  };

  dispatch(receiveSearchResponse(resp));
  const state = getState();
  evSearchPageView(state);
  const pixelFacetData = makeSearchPixelFacetDataFromState(state);
  dispatch(fireSearchPixels(term, state.products.list, pixelFacetData, state.filters));
}

export function saveFilters(excludeFilter, wasSavedCookie, saveCustomerFilters = saveFiltersToOpal) {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      environmentConfig: {
        api: { opal }
      },
      filters
    } = appState;

    const organizedFilters = formatSavedFilters(filters, excludeFilter, wasSavedCookie);
    if (organizedFilters) {
      return saveCustomerFilters(opal, {
        savedsizes: filters.savedsizes,
        organizedFilters
      })
        .then(fetchErrorMiddleware)
        .then(response => {
          if (response?.id) {
            dispatch(updateSavedFilters(response, filters.savedsizes.filters));
          }
        });
    }
  };
}

export function deleteSavedFilters(id, deleteCustomerFilters = deleteFiltersFromOpal) {
  return (dispatch, getState) => {
    const appState = getState();
    const {
      environmentConfig: {
        api: { opal }
      }
    } = appState;

    if (id) {
      return deleteCustomerFilters(opal, id).then(() => {
        dispatch(clearSavedFilters());
      });
    }
  };
}
