import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import LandingProductCardWrapper from 'components/landing/LandingProductCardWrapper';
import { evHeroWithProductStreamClick } from 'events/symphony';
import { track } from 'apis/amethyst';

export const ProductImageInline = ({ slotName, slotIndex, slotDetails, onComponentClick, slotHeartsData, shouldLazyLoad }) => {
  const onProductClick = useCallback(
    (evt, product) => {
      onComponentClick && onComponentClick(evt);
      track(() => [evHeroWithProductStreamClick, { slotDetails, slotIndex, slotName, product }]);
    },
    [onComponentClick, slotDetails, slotIndex, slotName]
  );

  return (
    <div data-slot-id={slotName}>
      <LandingProductCardWrapper
        isCarousel
        slotDetails={slotDetails}
        slotHeartsData={slotHeartsData}
        onComponentClick={onProductClick}
        shouldLazyLoad={shouldLazyLoad}
        maxDisplay={7}
      />
    </div>
  );
};

ProductImageInline.propTypes = {
  slotName: PropTypes.string,
  slotIndex: PropTypes.number,
  slotDetails: PropTypes.object,
  onComponentClick: PropTypes.func,
  slotHeartsData: PropTypes.object,
  shouldLazyLoad: PropTypes.bool,
  eventLabel: PropTypes.string
};

export default withErrorBoundary('ProductImageInline', ProductImageInline);
