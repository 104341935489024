import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import { track } from 'apis/amethyst';
import { evHeroClick, evHeroImpression } from 'events/symphony';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import ContentBox from 'components/common/ContentBox.jsx';
import LandingPageLink from 'components/landing/LandingPageLink';
import LandingPageMedia from 'components/landing/LandingPageMedia';

import css from 'styles/components/landing/zapposPromoGroup.scss';

const positionOutsideClassMap = {
  bottom: css.bottom,
  top: css.top,
  right: css.right,
  left: css.left
};

const ZapposPromo = props => {
  const {
    heading,
    copy,
    subCopy,
    links = [],
    onComponentClick,
    slotIndex,
    slotName,
    heroCount,
    componentName,
    itemsPerRow,
    itemsPerRowMobile,
    eventLabel,
    media = {},
    content: { useImageAsHeading, linkStyle, backgroundColor = 'transparent', color, textAlign, width, position = 'outside-bottom' } = {},
    shouldLazyLoad
  } = props;
  const { testId } = useMartyContext();
  const firstLink = links.length > 0 ? links[0] : null;
  const label = eventLabel || componentName;
  const { primary, content } = media;
  const [positionType, positionLocation] = (position || '').split('-');

  const positionClass = positionType === 'outside' ? positionOutsideClassMap[positionLocation] : css.bottom;

  const onClick = useCallback(
    evt => {
      onComponentClick?.(evt);
      const {
        target: {
          dataset: { eventvalue: identifier }
        }
      } = evt;
      track(() => [evHeroClick, { slotDetails: props, slotIndex, slotName, heroCount, identifier }]);
    },
    [heroCount, onComponentClick, props, slotIndex, slotName]
  );

  useEffect(() => {
    track(() => [
      evHeroImpression,
      {
        slotDetails: props,
        slotIndex,
        slotName,
        heroCount,
        name: componentName
      }
    ]);
  }, [componentName, heroCount, props, slotIndex, slotName]);

  const style = {
    width,
    '--desktopWidth': itemsPerRow,
    '--mobileWidth': itemsPerRowMobile
  };

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  return (
    <article className={cn(css.zapposPromoContainer, positionClass)} data-test-id={testId('zapposPromoSection')} style={style}>
      <LandingPageMedia {...primary} shouldLazyLoad={shouldLazyLoad} slotName={slotName} slotIndex={slotIndex} />
      <ContentBox
        className={css.contentBox}
        heading={heading}
        copy={copy}
        subCopy={subCopy}
        links={links}
        imageData={content}
        HeadingTag="h3"
        linkStyle={linkStyle}
        eventLabel={label}
        onClick={onClick}
        useImageAsHeading={useImageAsHeading}
        color={color}
        backgroundColor={backgroundColor}
        textAlign={textAlign}
        shouldLazyLoad={shouldLazyLoad}
      />
      {firstLink && (
        <LandingPageLink
          className={css.coverLink}
          onClick={onClick}
          aria-hidden={true}
          tabIndex="-1"
          newWindow={firstLink.newWindow}
          url={firstLink.href}
          data-eventlabel={label}
          data-eventvalue={firstLink.gae || firstLink.text}
        >
          {firstLink.text}
        </LandingPageLink>
      )}
    </article>
  );
};

export const ZapposPromoGroup = ({ slotDetails = {}, onComponentClick, slotIndex, slotName, shouldLazyLoad }) => {
  const {
    heading,
    background = '',
    containerBreak = false,
    scrollable = false,
    sections,
    componentName,
    itemsPerRow = 3,
    itemsPerRowMobile = 2
  } = slotDetails;

  return (
    <section
      className={cn(css.zapposPromoGroupContainer, css.containerOverride, {
        [css.scrollable]: scrollable
      })}
      data-slot-id={slotName}
      data-slotindex={slotIndex}
      style={{ '--outer-promo-bg': background || 'transparent' }}
    >
      <div
        className={cn(css.containerFull, {
          [css.bgColor]: background
        })}
        style={{ '--inner-promo-bg': background && containerBreak ? background : 'transparent' }}
      >
        {heading && <h2>{heading}</h2>}
        <div className={css.innerContainer}>
          <div className={cn(css.promos)}>
            {sections.map((section, index) => (
              <ZapposPromo
                {...section}
                heaing={heading}
                key={section.heading + section.copy || index}
                shouldLazyLoad={shouldLazyLoad}
                componentName={componentName}
                itemsPerRow={itemsPerRow}
                itemsPerRowMobile={itemsPerRowMobile}
                slotIndex={slotIndex}
                slotName={slotName}
                heroCount={sections.length}
                onComponentClick={onComponentClick}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

ZapposPromoGroup.propTypes = {
  slotIndex: PropTypes.number,
  slotName: PropTypes.string,
  onComponentClick: PropTypes.func,
  shouldLazyLoad: PropTypes.bool,
  slotDetails: PropTypes.shape({
    componentName: PropTypes.string,
    background: PropTypes.string,
    containerBreak: PropTypes.bool,
    scrollable: PropTypes.bool,
    heading: PropTypes.string,
    itemsPerRow: PropTypes.number,
    itemsPerRowMobile: PropTypes.number,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        copy: PropTypes.string,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            gae: PropTypes.string
          })
        ),
        tracks: PropTypes.array
      })
    )
  })
};

ZapposPromo.propTypes = {
  heading: PropTypes.string,
  copy: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      gae: PropTypes.string
    })
  ),
  onComponentClick: PropTypes.func,
  shouldLazyLoad: PropTypes.bool,
  slotIndex: PropTypes.number,
  slotName: PropTypes.string,
  itemsPerRow: PropTypes.number,
  itemsPerRowMobile: PropTypes.number,
  heroCount: PropTypes.number,
  eventLabel: PropTypes.string,
  componentName: PropTypes.string,
  content: PropTypes.shape({
    linkStyle: PropTypes.string,
    position: PropTypes.string,
    useImageAsHeading: PropTypes.bool,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    textAlign: PropTypes.string,
    width: PropTypes.string
  }),
  media: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      src: PropTypes.string,
      alt: PropTypes.string
    })
  )
};

export default withErrorBoundary('ZapposPromoGroup', ZapposPromoGroup);
