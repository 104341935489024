import React, { useCallback, useEffect, useState } from 'react';

import type { GenericSizeBiases } from 'types/product';
import { track } from 'apis/amethyst';
import { sizeBiasImpression } from 'events/product';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';
import HtmlToReact from 'components/common/HtmlToReact';
import RecommendedSizingLayout from 'components/productdetail/stylepicker/RecommendedSizingLayout/RecommendedSizingLayout';

export interface GenericSizeBiasRecoProps {
  currentProductId: string;
  genericSizeBiases: Partial<GenericSizeBiases>;
  children?: React.ReactNode;
}

export const GenericSizeBiasReco = ({ currentProductId, genericSizeBiases }: GenericSizeBiasRecoProps) => {
  const { testId } = useMartyContext();
  const { productId, sizeBiases, text } = genericSizeBiases;
  const [copyText, setCopyText] = useState(null);

  useEffect(() => {
    if (copyText && currentProductId === productId) {
      track(() => [sizeBiasImpression, { text: copyText, productId, sizeBiases }]);
    }
  }, [copyText, currentProductId, productId, sizeBiases, text]);

  const copyTextRef = useCallback(
    (node: any) => {
      if (node) {
        setCopyText(node.textContent);
      }
    },
    [setCopyText]
  );

  if (currentProductId !== productId) {
    return null;
  }

  return text ? (
    <RecommendedSizingLayout>
      <div data-test-id={testId('genericSizeBiasReco')}>
        <HtmlToReact data-test-id={testId('genericSizeBias')} ref={copyTextRef}>
          {text}
        </HtmlToReact>
      </div>
    </RecommendedSizingLayout>
  ) : null;
};

export default withErrorBoundary('GenericSizeBiasReco', GenericSizeBiasReco);
