import { useCallback, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import withJsonLdProduct from 'components/common/JsonLDProductWrapper';
import CardGender from 'components/common/card/CardGender';
import { cn } from 'helpers/classnames';
import Badge from 'components/common/Badge';
import useMartyContext from 'hooks/useMartyContext';
import Link from 'components/common/MartyLink';
import { fetchProductDetail } from 'actions/productDetail';
import { makeAscii } from 'helpers';
import Card from 'components/common/card/Card';
import CardMedia from 'components/common/card/CardMedia';
import CardPrice from 'components/common/card/CardPrice';
import Rating from 'components/Rating';
import SponsoredBanner from 'components/common/SponsoredBanner';
import getCardData from 'data/getCardData';
import Hearts from 'components/common/Hearts';
import LowStockLabel from 'components/common/melodyCard/LowStockLabel';
import { SEARCH_IMGS_NOT_LAZY_LOADED } from 'constants/appConstants';
import { getCardFlag } from 'helpers/cardUtils';
import { track } from 'apis/amethyst';
import { evBadgeCategoryClick } from 'events/product';
import ProductUtils from 'helpers/ProductUtils';
import useWindowSize from 'hooks/useWindowSize';
import Tag from 'tailwind/components/Tag/Tag';
import { isMobileViewport } from 'utils/viewportUtil';
import { FEATURE_LEFT_IN_STOCK } from 'constants/features';
import { ZAPPOS_MERCHANTID } from 'constants/crossSiteMerchantIdMapping';
import { selectUnleashToggle } from 'selectors/features';

// eslint-disable-next-line css-modules/no-unused-class
import css from 'styles/components/common/productCard.scss';
/**
 * Attach to onMouseEntered and onMouseLeave to have a debounced notification.
 * use with `const [hovered, setHovered] = useState(false);` to get a react re-render
 *
 *  onMouseEntered: (e) => handleMouseHover(e, true, setHovered)
 *  onMouseLeave:   (e) => handleMouseHover(e, false, setHovered)
 *
 * @param event    event that fired
 * @param hovered  boolean
 * @param setHovered (hovered: boolean) => any   function to call when state changes
 */
function handleMouseHovered(event, hovered, setHovered) {
  const element = event.target;
  if (element.__productCardHoverTimer) {
    clearTimeout(element.__productCardHoverTimer);
    element.__productCardHoverTimer = null;
  }
  if (hovered) {
    element.__productCardHoverTimer = setTimeout(() => {
      setHovered(true);
    }, 500);
  } else {
    setHovered(false);
  }
}

const ProductCard = props => {
  const {
    index,
    linkProps,
    className,
    brandName,
    intersectionRef,
    isSponsored,
    onClick,
    onMediaHovered,
    productName,
    productSeoUrl,
    productUrl,
    styleColor,
    styleId,
    CardDetailsTopSlot,
    CardDetailsTopSlot2,
    CardBottomSlot,
    onHand,
    productId,
    imageClassName,
    isLinkDisabled = false,
    mediaChildren,
    showPrice = true,
    showColorName = false,
    makeStarsBlock,
    badges = [],
    showRecosBadge = false,
    imageBadgeClassName,
    amethystRecoType,
    isMobileBlockLayout = false,
    showProductTag = false,
    productTagCount = 0,
    dateLabel,
    preLabel = '',
    includeSwatchRowSpace = false,
    productType,
    clickThruUrl
  } = props;

  const {
    testId,
    marketplace: {
      merchantId,
      search: { showRatingStars, showLowStockLabel }
    }
  } = useMartyContext();

  const unleashLeftInStockFeature = useSelector(state => selectUnleashToggle(FEATURE_LEFT_IN_STOCK, state));

  const [isHovered, setHovered] = useState(() => false);
  const [badgeAccessibilityLabel, setBadgeAccessibilityLabel] = useState('');
  const displayRatingStars = showRatingStars;
  const { media, price, reviews, gender } = getCardData({
    ...props,
    isHovered,
    showRatingStars: displayRatingStars
  });
  const isProductTypeShoeAndClothing = ProductUtils.isProductTypeShoesOrClothing(productType);

  const productLink = productSeoUrl || productUrl || null;
  const encodedBrandName = makeAscii(brandName);
  const encodedProductName = makeAscii(productName);
  const encodedPreLabel = makeAscii(preLabel);

  // take a 'HeartsData' | 'HeartProps' type as the 'hearts' argument.
  // todo: remove the 'HeartsData' type from the code.
  const heartsData = props.hearts?.heartsData || props.hearts;
  const heartProps = {
    // todo: flatten these 'hearts' properties
    onHeartClick: heartsData?.onHeartClick,
    showFavoriteHeart: heartsData?.showFavoriteHeart,
    isDisplayCount: false,
    styleId,
    productId
  };
  const { width: windowWidth = 0 } = useWindowSize();
  const isMobile = isMobileViewport(windowWidth);

  const clickHandler = e => {
    onClick?.(e, styleId);
  };

  const flags = {
    sponsored: <SponsoredBanner newCard={true} index={index} />
  };

  const { variant: { name, enabled } = { name: '', enabled: false } } = unleashLeftInStockFeature || {};
  const isLeftInStockEnabled = merchantId === ZAPPOS_MERCHANTID && enabled && name === '1';
  const flagType = getCardFlag({ ...props });
  const flag = flags[flagType] || null;
  const hasLowStock = onHand <= 10;
  const displayLowStockLabel = !!showLowStockLabel && hasLowStock && !isLeftInStockEnabled;
  const productLabel = ProductUtils.getProductLabel(
    encodedProductName,
    encodedBrandName,
    styleColor,
    isSponsored,
    !!badges.length,
    displayLowStockLabel,
    encodedPreLabel
  );

  const makeStars = () => {
    if (!displayRatingStars) {
      return null;
    }

    return (
      <>
        <dt>Rating</dt>
        <dd>
          <Rating
            rating={reviews.roundedRating}
            additionalClasses={makeStarsBlock && css.pdpCardReviews}
            reviewCount={reviews.ratingCount}
            hasDisplayReviewCount={true}
          />
        </dd>
      </>
    );
  };

  const setBadgeLabelHandler = useCallback(
    label => {
      setBadgeAccessibilityLabel(label);
    },
    [setBadgeAccessibilityLabel]
  );

  const onBadgeCategoryClick = () => {
    track(() => [evBadgeCategoryClick, { badges, styleId, amethystRecoType }]);
  };

  return (
    <Card
      className={className}
      // For helping site merch collect styleIds https://github01.zappos.net/mweb/marty/pull/9699
      data-style-id={styleId}
      data-test-id={props['data-test-id']}
      data-low-stock={displayLowStockLabel}
      ref={intersectionRef}
    >
      <Link
        className={cn(css.productLink, { [css.mobileBlock]: isMobileBlockLayout })}
        data-style-id={styleId}
        to={clickThruUrl ?? (productLink || '')}
        data-test-id={testId('searchResultLink')}
        onClick={clickHandler}
        onMouseEnter={e => handleMouseHovered(e, true, setHovered)}
        onMouseLeave={e => handleMouseHovered(e, false, setHovered)}
        isLinkDisabled={isLinkDisabled}
        {...linkProps}
      >
        {badges.length > 0 && badgeAccessibilityLabel}
        {productLabel} {price.label}. {reviews.label}
      </Link>
      <CardMedia
        onHover={onMediaHovered}
        forceLoadIndex={SEARCH_IMGS_NOT_LAZY_LOADED}
        {...media}
        imageClassName={cn(imageClassName, imageBadgeClassName)}
        disableHoverImage={isSponsored}
      >
        <div className={css.top}>
          {!showRecosBadge && !flag && badges.length > 0 && (
            <Badge
              setLabel={setBadgeLabelHandler}
              id={badges[0].bid}
              category={badges[0].zc}
              url={badges[0].url}
              productLink={productLink}
              onBadgeCategoryClick={onBadgeCategoryClick}
            />
          )}
          {showRecosBadge && badges.length > 0 && (
            <Badge
              setLabel={setBadgeLabelHandler}
              id={badges[0].bid}
              category={badges[0].zc}
              url={badges[0].url}
              productLink={productLink}
              onBadgeCategoryClick={onBadgeCategoryClick}
            />
          )}
          {flag}
          {!!dateLabel && (
            <div className={cn(css.dateLabel, { [css.disableLink]: isLinkDisabled })}>
              <Tag size={isMobile ? 'medium' : 'large'} variant="white">
                {dateLabel}
              </Tag>
            </div>
          )}
          <Hearts {...heartProps} />
        </div>
        {mediaChildren}
        <div className={css.bottom}>
          {displayLowStockLabel && <LowStockLabel />}
          {showProductTag && <div className={css.tagBadge}>{productTagCount}</div>}
        </div>
      </CardMedia>
      <div className={css.detailsWrapper}>
        {!!CardDetailsTopSlot && <CardDetailsTopSlot {...props} />}
        {!!CardDetailsTopSlot2 && <CardDetailsTopSlot2 {...props} />}
        {includeSwatchRowSpace && <span className={css.mimicSwatchWrapper} />}
        <Link
          className={cn(css.details, {
            [css.mobileBlock]: isMobileBlockLayout
          })}
          onClick={clickHandler}
          tabIndex={-1}
          to={clickThruUrl ?? (productLink || '')}
          isLinkDisabled={isLinkDisabled}
        >
          <dl>
            <dt>Brand Name</dt>
            <dd className={css.mainText} data-test-id={testId('brand')}>
              <span>{encodedBrandName}</span>
            </dd>
            <dt>Product Name</dt>
            <dd className={css.subText} data-test-id={testId('productName')}>
              {encodedProductName}
            </dd>
            {gender && isProductTypeShoeAndClothing && <CardGender gender={gender} />}
            <dt>Color</dt>
            <dd className={showColorName ? css.colorText : css.colorName} data-test-id={testId('colorName')}>
              {styleColor}
            </dd>
            {showPrice && <CardPrice {...price} isAvailable={onHand > 0} url={productLink} />}
            {makeStars()}
          </dl>
        </Link>
        {!!CardBottomSlot && <CardBottomSlot {...props} onClick={clickHandler} />}
      </div>
    </Card>
  );
};

const mapDispatchToProps = {
  fetchProductDetail
};

const mapStateToProps = state => {
  const { headerFooter: { content } = {}, filters: { term } = {} } = state;

  return {
    term,
    content
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withJsonLdProduct(ProductCard));
