import React from 'react';
// eslint-disable-next-line import/no-namespace
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@mweb/zappos-ui/Button';
import { InputInline } from '@mweb/zappos-ui/InputInline';
import { Checkbox } from '@mweb/zappos-ui/Checkbox';
import { InputMessage } from '@mweb/zappos-ui/InputMessage';

import { contentClasses, minimizedButtonClasses } from './SignUpDrawer.classes';

import { cn } from 'helpers/classnames';
import Link from 'components/hf/HFLink';
import type { SignUpDrawerProps } from 'components/common/SignUpDrawer/SignUpDrawer.types';
import IconButton from 'components/common/IconButton';
import UtilityStrokeCloseMediumIcon from 'tailwind/components/Icons/UtilityStrokeCloseMediumIcon';
import emailSignUpData from 'data/emailSignUpData.json';
import {
  EMAIL_SIGNUP_GENERAL_ERROR,
  INVALID_FIRST_TIME_SUBSCRIBER_MSG,
  PLEASE_CHECK_THIS_BOX,
  SIGN_UP_FOR_ZAPPOS_EMAILS
} from 'constants/emailSignupDrawer';

const SignUpDrawer = ({
  experience,
  invalidEmailMsg,
  isAccepted,
  isCustomer,
  isEmailValid,
  isMinimizedShown,
  isOpen,
  isSubmitted,
  isSubmitting,
  labelText,
  isFirstTimeSubscriber,
  hasError,
  position,
  shouldRenderMinimized,
  testId,
  handleChange,
  handleFormSubmit,
  handleFocus,
  handleBlur,
  openDrawer,
  checkboxClick,
  closeDrawer,
  closeDrawerFinal
}: SignUpDrawerProps) => {
  const { title, description, cards, minimizedButtonText, submitButtonText, successDescription, successImg, successTitle } = emailSignUpData;

  const variantType = (): 'error' | undefined => {
    if (!isEmailValid || !isFirstTimeSubscriber) {
      return 'error';
    }
    return undefined;
  };
  const helpTextMsg = (): string | undefined => {
    if (!isEmailValid) {
      return invalidEmailMsg;
    } else if (!isFirstTimeSubscriber) {
      return INVALID_FIRST_TIME_SUBSCRIBER_MSG;
    }
    return undefined;
  };

  return (
    <Dialog.Root open={isOpen} data-test-id={testId('sign-up-drawer')}>
      {!isSubmitted && shouldRenderMinimized && (
        <div className={cn(minimizedButtonClasses({ experience }))} data-state-visible={isMinimizedShown}>
          <Dialog.Trigger asChild>
            <Button
              className="my-auto rounded-none rounded-tr-2xl pb-[7px] pr-[48px] text-base"
              onClick={openDrawer}
              data-test-id={testId('minimizedSignUpDrawer')}
            >
              {minimizedButtonText}
            </Button>
          </Dialog.Trigger>
          {/* Override global :focus { z-index: 1 } applied in global.scss and ensure close button is visible on top of drawer - comment applies to all high z-indexes */}
          <IconButton
            className="fixed bottom-0 left-[96px] z-[1000] inline-flex w-[25px] appearance-none rounded-full text-button-reversed outline-offset-2 hover:text-button-disabled-subtle focus:z-[1000]"
            label={'minimizeCloseLabel'}
            size="base"
            variant="icon"
            onClick={closeDrawerFinal}
          >
            <UtilityStrokeCloseMediumIcon className="text-icon-reversed" size={24} data-test-id={testId('minimized-close')} />
          </IconButton>
        </div>
      )}
      <Dialog.Portal>
        {/* Override global :focus { z-index: 1 } applied in global.scss - z-index to not interfere with AddToBagDrawer and Filters on Mobile View */}
        <Dialog.Overlay
          className="fixed inset-0 bg-[rgb(0_0_0_/_0.25)] ease-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 max-[800px]:z-[998] min-[801px]:z-[20]"
          onClick={closeDrawer}
        />
        <Dialog.Content aria-describedby={undefined} className={cn(contentClasses({ position }))} data-test-id={testId('signUpDrawer')}>
          <Dialog.Close
            asChild
            className={cn(
              'absolute right-[10px] top-[10px] z-[999] inline-flex w-[25px] appearance-none items-center justify-center rounded-full text-button-reversed outline-offset-2 focus:z-[999]',
              { ['text-button top-[-587px]']: isSubmitted }
            )}
          >
            <IconButton
              className={cn({ ['hover:text-button-disabled-subtle']: !isSubmitted })}
              label={'closeLabel'}
              size="base"
              variant="icon"
              onClick={closeDrawer}
            >
              <UtilityStrokeCloseMediumIcon size={32} data-test-id={testId('close')} />
            </IconButton>
          </Dialog.Close>

          {!isSubmitted ? (
            <>
              <div className="flex shrink-0 flex-col items-center gap-3 rounded-t-2xl border-b bg-default-contrast px-7 pb-6 pt-8 md:rounded-tl-none md:rounded-tr-2xl md:px-11 md:py-6">
                <Dialog.Title className="md:leading-12 mb-1 flex pt-2.5 text-center text-[34px] font-medium leading-[43px] text-button-reversed md:mb-2 md:text-4xl">
                  {title}
                </Dialog.Title>
                <Dialog.Description className="flex text-center text-base text-button-reversed">{description}</Dialog.Description>
              </div>

              <div className="bg-button-disabled-minimal">
                <div className="flex justify-between px-3 py-8 md:px-6">
                  {cards.map(card => (
                    <div key={card.title} className="flex">
                      <div className="h-20">
                        <img className="m-auto h-10" src={card.img} alt={card.title}></img>
                        <p className="m-1 text-center text-base font-medium">{card.title}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <form
                  className="grid gap-2"
                  method="post"
                  onSubmit={handleFormSubmit}
                  action="/subscription.do"
                  data-test-id={testId('emailSignUpDrawerForm')}
                >
                  {!isCustomer && (
                    <InputInline
                      name="emailAddress"
                      className={cn('mx-auto h-[56px] w-11/12 md:w-[380px]', { ['mb-6']: !isEmailValid || !isFirstTimeSubscriber })}
                      label={labelText}
                      disabled={isSubmitting}
                      variant={variantType()}
                      helpText={helpTextMsg()}
                      data-test-id={testId('emailSignupDrawerInput')}
                      id="emailSignupDrawerEmail"
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  )}
                  {isCustomer && (
                    <div className="mx-auto mb-4 mt-2">
                      <Checkbox
                        onClick={checkboxClick}
                        required
                        label={SIGN_UP_FOR_ZAPPOS_EMAILS}
                        data-test-id={testId('emailSignupDrawerCheckbox')}
                      />
                      {!isAccepted && isSubmitting && (
                        <InputMessage className="mx-auto mt-2.5 text-base text-alert-inline" id="checkboxError" variant="error">
                          {PLEASE_CHECK_THIS_BOX}
                        </InputMessage>
                      )}
                    </div>
                  )}
                  <Button
                    className="m-auto h-[56px] w-11/12 rounded-[8px] px-[10px] text-base leading-none outline-none focus:shadow-[0_0_0_2px] md:w-[380px]"
                    variant="filled"
                    id="emailSubmit"
                    type="submit"
                    data-test-id={testId('emailSignUpDrawerSubmit')}
                  >
                    {submitButtonText}
                  </Button>
                  {hasError && (
                    <div className="mx-auto mt-2.5" data-test-id={testId('newEmailSignupError')}>
                      <InputMessage className="text-base text-alert-inline" id="generalSignupError" variant="error">
                        {EMAIL_SIGNUP_GENERAL_ERROR}
                      </InputMessage>
                    </div>
                  )}
                </form>

                <div className="px-[1.35rem] py-[1rem]">
                  <p className="text-xs">
                    *Sign up to receive promotional emails from Zappos to receive 10% off a future Zappos purchase. Code will be emailed to the email
                    address provided. Promotion is limited to new email subscribers only, is subject to change without notice and other terms and
                    conditions may apply. One Code per email address. By providing your email address you agree to the Zappos{' '}
                    <Link onClick={closeDrawer} className="underline" to={'/c/terms-of-use'}>
                      Terms of Use
                    </Link>{' '}
                    and our{' '}
                    <Link onClick={closeDrawer} className="underline" to={'/c/privacy-policy'}>
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="fixed bottom-0 flex h-[600px] w-full flex-col rounded-t-2xl bg-default-minimal ease-out data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out md:w-[420px] md:rounded-tl-none md:rounded-tr-2xl">
              <div className="my-auto flex shrink-0 flex-col items-center gap-3 px-14 py-6">
                <img src={successImg} alt={successTitle}></img>
                <h3 className="mt-[12px] px-5 text-center text-[30px] font-medium leading-[36px]">
                  {successTitle.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== successDescription.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </h3>
                <p className="text-center text-base" data-test-id={testId('newEmailSignupSuccess')}>
                  {successDescription.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== successDescription.split('\n').length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SignUpDrawer;
