import type { Dispatch } from 'react';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { AnyAction } from 'redux';
import { weakMapMemoize } from 'reselect';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import ProductCard from 'components/common/ProductCard';
import { ProductDetailCarousel } from 'components/productdetail/ProductDetailCarousel';
import LandingPageImage from 'components/landing/LandingPageImage';
import LandingPageLink from 'components/landing/LandingPageLink';
import { track } from 'apis/amethyst';
import { evProductInteract } from 'events/search';
import { convertProductLandingToProductWithRelations } from 'helpers/RecoUtils';
import { evHeroWithProductStreamClick, evSearchProductStreamClick, evSearchProductStreamImpression } from 'events/symphony';
import { getBadgeId, getRowHasBadgeClassname } from 'helpers/BadgeUtils';
import type { SlotDetails } from 'types/landing';
import { setHFSearchTerm, updateOriginalTerm } from 'actions/headerfooter';
import type { HeartProps } from 'types/hearts';
import type { ProductWithRelations, ProductWithRelationsFromCalypso } from 'types/calypso';
import type { Product } from 'constants/searchTypes';

import css from 'styles/components/landing/landingProductCardWrapper.scss';

interface LandingProductProps {
  slotDetails: SlotDetails;
  slotIndex?: number;
  slotName?: string;
  slotHeartsData: HeartProps;
  shouldLazyLoad: boolean;
  isFullWidth?: boolean;
  componentStyling?: string;
  isCarousel?: boolean;
  maxDisplay?: number;
}
const onProductMediaHovered = (product: Product | ProductWithRelations, styleId: string) => {
  track(() => [
    evProductInteract,
    {
      mainStyleId: styleId,
      interactedProduct: product,
      interactionType: 'HOVER'
    }
  ]);
};

const msaImageParams = {
  autoCrop: true
} as const;

const productCardOnProductClick = weakMapMemoize(
  (
    slotDetails: SlotDetails,
    slotIndex: number | undefined,
    slotName: string | undefined,
    productWithRelatedStyles: any,
    heroCount: number,
    dispatch: Dispatch<AnyAction>
  ) =>
    () => {
      const filterCondition = slotDetails.style === 'default-results' || slotDetails.style === 'melody';

      if (!filterCondition) {
        return track(() => [
          evHeroWithProductStreamClick,
          {
            slotDetails,
            slotIndex,
            slotName,
            productWithRelatedStyles,
            heroCount
          }
        ]);
      }

      track(() => [evSearchProductStreamClick, { slotDetails, slotIndex, slotName, productWithRelatedStyles }]);

      dispatch(setHFSearchTerm(''));
      dispatch(updateOriginalTerm());
    }
);

export const LandingProductCardWrapper = (props: LandingProductProps) => {
  const { slotDetails, slotIndex, slotName, shouldLazyLoad, slotHeartsData, isFullWidth, componentStyling, isCarousel, maxDisplay } = props;
  const {
    style,
    gae,
    alt,
    image,
    products,
    eventLabel: eventLabelCustom,
    componentName,
    siteName,
    title,
    link,
    retina,
    mobilesrc,
    mobileretina,
    tabletsrc,
    tabletretina,
    sources
  } = slotDetails || {};

  const { testId } = useMartyContext();
  const heroCount = image ? 1 : 0;
  const eventLabel = eventLabelCustom || style || componentName;
  const commonProps = { ...props, eventLabel, shouldLazyLoad };

  const formattedProducts = products.map(product => convertProductLandingToProductWithRelations(product));

  const dispatch = useDispatch();

  let commonPropsWithBadges: {
    eventLabel: string | undefined;
    shouldLazyLoad: boolean;
    slotDetails: SlotDetails;
    slotIndex?: number;
    slotName?: string | undefined;
    slotHeartsData: HeartProps;
    isFullWidth?: boolean | undefined;
    componentStyling?: string | undefined;
    isCarousel?: boolean | undefined;
    maxDisplay?: number | undefined;
    formattedBadgeProducts: { productId: string | undefined; styleId: string; colorId: any; badgeId: string | undefined }[]; // TODO ts colorId needs to be looked at. Some places string, others it is a number.
  };

  if (formattedProducts.length && style === 'melody') {
    const formattedProductsWithBadgeIds = formattedProducts.map(formattedProduct => ({
      ...formattedProduct,
      badgeId: getBadgeId(formattedProduct.badges)
    }));
    commonPropsWithBadges = { formattedBadgeProducts: formattedProductsWithBadgeIds, ...commonProps };
  }

  useEffect(() => {
    if (isCarousel) {
      track(() => [evSearchProductStreamImpression, commonPropsWithBadges]);
    }
  }, [formattedProducts.length]);

  if (!products?.length) {
    return null;
  }

  const rowHasBadge = products.some(product => !!product.badges);

  const rowBadgeClassname = getRowHasBadgeClassname(style);

  const productCards = [];

  const shouldAddLandingPageImage = isCarousel && image;

  if (shouldAddLandingPageImage) {
    productCards.push(
      <LandingPageLink
        className="m-0 p-0"
        url={link}
        onClick={() => {}}
        data-eventlabel={eventLabel}
        data-slotindex={slotIndex}
        fallbackNode="div"
        data-eventvalue={gae || title || alt}
      >
        <LandingPageImage
          // @ts-ignore —  LandingPageImage needs typing
          src={image}
          retina={retina}
          mobilesrc={mobilesrc}
          mobileretina={mobileretina}
          tabletsrc={tabletsrc}
          tabletretina={tabletretina}
          sources={sources}
          alt={alt}
          shouldLazyLoad={shouldLazyLoad}
        />
      </LandingPageLink>
    );
  }

  for (const [index, product] of formattedProducts.entries()) {
    const { styleId } = product;
    const { relatedStyles, ...productInfo } = product;
    const swatchRelatedStyles: ProductWithRelationsFromCalypso[] = relatedStyles ? [productInfo].concat(relatedStyles || []) : [];
    for (const relatedStyle of swatchRelatedStyles) {
      relatedStyle.relatedStyles = swatchRelatedStyles;
    }
    const { badges } = product;

    const onProductClick = productCardOnProductClick(slotDetails, slotIndex, slotName, product, heroCount, dispatch);

    if (relatedStyles?.length) {
      if (maxDisplay) {
        if (index > maxDisplay) {
          return;
        }
      }

      productCards.push(
        <ColorSwatchWrapper
          {...product}
          className={cn(componentStyling, { [css.card]: !isCarousel })}
          relatedStyles={swatchRelatedStyles}
          heartsInfo={slotHeartsData}
          key={styleId + eventLabel}
          eventLabel={eventLabel}
          msaImageParams={msaImageParams}
          onProductMediaHovered={onProductMediaHovered}
          onClick={onProductClick}
          testId={testId(eventLabel)}
          siteName={siteName}
          imageBadgeClassName={(rowHasBadge && rowBadgeClassname) || undefined}
          badges={badges}
        />
      );
      continue;
    }

    productCards.push(
      <ProductCard
        {...product}
        className={cn(componentStyling, { [css.card]: !isCarousel })}
        hearts={slotHeartsData}
        key={styleId + eventLabel}
        eventLabel={eventLabel}
        msaImageParams={msaImageParams}
        onProductMediaHovered={onProductMediaHovered}
        onClick={onProductClick}
        testId={testId(eventLabel)}
        siteName={siteName}
        imageBadgeClassName={(rowHasBadge && rowBadgeClassname) || undefined}
        badges={badges}
        index={index}
        isFullWidth={isFullWidth}
        includeSwatchRowSpace={true}
      />
    );
  }

  if (isCarousel) {
    return (
      <div className={css.carouselContainer}>
        <div className={css.carouselContent}>
          <ProductDetailCarousel
            title={title}
            slides={productCards}
            overrides={style === 'image-inline' ? { item: '@7xl/carousel:basis-1/4' } : undefined} // override 5 column desktop default with 4 columns
          />
        </div>
      </div>
    );
  }

  return <>{productCards}</>;
};

export default LandingProductCardWrapper;
