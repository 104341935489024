import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducerFactory from 'reducers';
import AppEnvironment from 'helpers/AppEnvironment';
import { browserMetadataMiddlewareFactory } from 'middleware/browserMetadataMiddleware';

const { isClient, showDevTools } = AppEnvironment;

const noopSaga = () => ({ toPromise: () => Promise.resolve() });

const reduxStateSyncConfig = {
  whitelist: ['RECEIVE_CART_ITEMS']
};

export default function configureStore(initialState, history, storeOptions = { reducers: {}, rootSaga: undefined }) {
  const sagaMiddleware = createSagaMiddleware();
  const finalCreateStore = compose(
    applyMiddleware(thunk),
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(history)),
    isClient ? applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig)) : f => f,
    // Browser metadata middleware must be after the routerMiddleware
    isClient ? applyMiddleware(browserMetadataMiddlewareFactory(history)) : f => f,
    showDevTools ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )(createStore);

  const reduxStore = finalCreateStore(rootReducerFactory({ ...storeOptions.reducers, router: connectRouter(history) }), initialState);
  reduxStore.runSaga = storeOptions.rootSaga ? () => sagaMiddleware.run(storeOptions.rootSaga) : noopSaga;
  reduxStore.close = () => reduxStore.dispatch(END);

  initStateWithPrevTab(reduxStore);

  return reduxStore;
}
