// @ts-nocheck
// Disable typechecking in this file so we can migrate it piecemeal, due to the file's sheer size
// While typing these functions in developement, remove the @ts-nocheck directive so you can see potential code errors/squigglies,
// and re-add it when you're finished.
import 'isomorphic-fetch';
import { stringify } from 'query-string';
import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';

import {
  MAFIA_CAPTCHA_ANSWER,
  MAFIA_CAPTCHA_AUDIO_REQ,
  MAFIA_CAPTCHA_REQ,
  MAFIA_CAPTCHA_TOKEN,
  MAFIA_EMAIL_TOKEN,
  MAFIA_RECOGNIZED_TOKEN
} from 'constants/apis';
import timedFetch from 'middleware/timedFetch';
import { formatCreditCard, formatCreditCardExpiration } from 'helpers/MyAccountUtils';
import { buildErrorQueryString } from 'helpers/CheckoutUtils';
import { ENDPOINT_OPAL_EXPLICITFITS } from 'apis/opal';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { setSessionCookies } from 'actions/session';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import marketplace from 'cfg/marketplace.json';
import type { AppState } from 'types/app';
import type { Cookies } from 'types/cookies';
import type { JanusParams, Mafia, OrderQueryParams, OrdersResponse, Recos, SearchOrderQueryParams } from 'types/mafia';
import type {
  BoundingBoxApiResponse,
  ImageMatchSearchResponse,
  UploadImageResponse
} from 'components/VisualSearch/VisualSearchResults/visualSearchResults.types';
import { authHeadersInUse, fetchOpts, sendJsonDataWithCreds } from 'apis/mafia/common';
import { recordToSplunk } from 'apis/recordToSplunk';
import { EMAIL_TOKEN_COOKIE } from 'constants/cookies';

const {
  cart: { cartName }
} = marketplace;

// Outfit Recommendations and VTO APIs

export function getOutfitRecos({ url }: Mafia, styleId, credentials: Cookies = {}, fetcher = timedFetch('getOutfitRecos')) {
  const params = new URLSearchParams({ styleId: styleId, model: 'occasion' });
  return fetcher(`${url}/slotz/fashion/outfitRecommendations?${params}`, fetchOpts({}, credentials));
}

const cartErrorTranslator = {
  'cart.validation.exception': 'Oops, something went wrong while updating your cart.',
  'cart.no.stock': 'Sorry, this product is out of stock.',
  'cart.no.style': 'Sorry, this style is out of stock.',
  'asin.not.available': 'Sorry, one of the products in your cart is out of stock, and has been removed.',
  'cannotAddMixedRetailAndEgc':
    'Sorry, but e-Gift Cards may not be combined with other retail items. Please complete your purchase before adding to cart.',
  'max.cart.size.exceeded': `Oops! Your ${cartName} is too full. Please remove some items and try again.`
};

export function translateCartError(response) {
  // Check if the response exists before we try to access a property of it
  if (!response) {
    recordToSplunk(
      buildErrorQueryString('martyCartError', {
        error: 'Sorry, the line went dead!'
      })
    );
    return null;
  }
  // error responses from cart api contain a status code, otherwise it's an OK response
  if (response.statusCode) {
    return cartErrorTranslator[response.id] || 'Sorry, something went wrong!';
  }
  return null;
}

function captchaHeaders({ token, answer }) {
  return {
    [MAFIA_CAPTCHA_TOKEN]: token,
    [MAFIA_CAPTCHA_ANSWER]: answer
  };
}

export function cartData({ amazonUrl }: Mafia, querystring = '', credentials: Cookies, fetcher = timedFetch('cart')) {
  const reqUrl = `${amazonUrl}/v1/cart${querystring}`;

  // if a customer has logged in, pass the header that syncs between desktop and mobile
  const headers = 'x-main' in credentials ? { [MAFIA_RECOGNIZED_TOKEN]: credentials['x-main'] } : {};

  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'get',
        headers: headers
      },
      credentials
    )
  );
}

export function changeCart({ amazonUrl }: Mafia, querystring = '', data, credentials: Cookies, fetcher = timedFetch('cart')) {
  const reqUrl = `${amazonUrl}/v1/cart${querystring}`;
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  if ('x-main' in credentials) {
    headers[MAFIA_RECOGNIZED_TOKEN] = credentials['x-main'];
  }

  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'post',
        headers: headers,
        body: JSON.stringify(data)
      },
      credentials
    )
  );
}

export function addresses({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getAddresses')) {
  const reqUrl = `${url}/v1/address`;
  return fetcher(reqUrl, fetchOpts({ method: 'get' }, credentials));
}

export function paymentTypes({ amazonUrl, shippingAddressId }, credentials: Cookies = {}, fetcher = timedFetch('getPaymentInstruments')) {
  let reqUrl = `${amazonUrl}/v1/paymentInstruments`;

  if (shippingAddressId) {
    reqUrl += `?associatedAddressId=${shippingAddressId}`;
  }

  return fetcher(reqUrl, fetchOpts({ method: 'get' }, credentials));
}

export function shipOptions({ url }: Mafia, purchaseId, credentials: Cookies = {}, fetcher = timedFetch('shipmentOptions')) {
  const reqUrl = `${url}/v1/shipmentOptions?purchaseId=${purchaseId}`;
  return fetcher(reqUrl, fetchOpts({ method: 'get' }, credentials));
}

export function getPin({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getPin')) {
  const reqUrl = `${url}/freja-laas/pin`;
  return fetcher(reqUrl, fetchOpts({ method: 'post' }, credentials));
}

export function validatePin({ url }: Mafia, pin, credentials: Cookies = {}, fetcher = timedFetch('validatePin')) {
  const reqUrl = `${url}/freja-laas/whitelist?pin=${pin}`;
  return fetcher(reqUrl, fetchOpts({ method: 'get' }, credentials));
}

export function getTrackingLabel({ url }: Mafia, data, credentials: Cookies = {}, fetcher = timedFetch('getTrackingLabel')) {
  const reqUrl = `${url}/freja-laas/generateLabel`;
  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      },
      credentials
    )
  );
}

export function logoutCustomer({ url }: Mafia, credentials: Cookies, fetcher = timedFetch('logoutCustomer')) {
  return fetcher(`${url}/v1/zap/logout`, fetchOpts({ method: 'get', mode: 'no-cors' }, credentials));
}

interface ChangeAddressParams {
  mafiaConfig: Mafia;
  address: any;
  forceOriginal: boolean;
}

export function changeAddress(
  params: ChangeAddressParams,
  credentials: Cookies = {},
  saveInvalidAddress = true,
  fetcher = timedFetch('changeAddress')
) {
  const {
    mafiaConfig: { url },
    address,
    forceOriginal
  } = params;
  const reqUrl = `${url}/v1/address?saveInvalidAddress=${saveInvalidAddress}${forceOriginal ? '&forceOriginal=true' : ''}`;
  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: address.addressId ? 'put' : 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(address)
      },
      credentials
    )
  );
}

export function deleteAddress({ url }: Mafia, credentials: Cookies = {}, addressId, fetcher = timedFetch('deleteAddress')) {
  return fetcher(`${url}/v1/address?addressIds=${addressId}`, fetchOpts({ method: 'DELETE' }, credentials));
}

export function savePayment({ amazonUrl }: Mafia, params, credentials: Cookies = {}, fetcher = timedFetch('savePaymentInstrument')) {
  const { instrument, addressId, purchaseId, useV2PaymentInstruments } = params;
  const reqUrl = `${amazonUrl}/${useV2PaymentInstruments ? 'v2' : 'v1'}/paymentInstruments`;

  const { cc, expiration, name, cvv, fullName, isPrimary, paymentInstrumentId } = instrument;

  let expirationMonth;
  let expirationYear;

  if (expiration) {
    ({ expirationMonth, expirationYear } = formatCreditCardExpiration(expiration));
  } else {
    ({ expirationMonth, expirationYear } = instrument);
  }

  let payment = `fullName=${fullName || name}&expirationMonth=${expirationMonth}&expirationYear=${expirationYear}&addressId=${addressId}`;
  if (paymentInstrumentId) {
    payment += `&paymentInstrumentId=${paymentInstrumentId}`;
  } else {
    const formattedCreditCardValue = formatCreditCard(cc);
    const creditCardNumber = formattedCreditCardValue.length ? formattedCreditCardValue : cc;
    payment += `&addCreditCardNumber=${creditCardNumber}`;
  }

  if (cvv) {
    payment += `&addCreditCardVerificationNumber=${cvv}`;
  }

  if (purchaseId) {
    payment += `&purchaseId=${purchaseId}`;
  }

  // Always true since it isn't possible to set primary payment as non-primary, i.e. isPrimary=false
  if (isPrimary) {
    payment += '&isPrimary=true';
  }

  const isTesting = credentials.hasOwnProperty('zappalytics-exclude');
  if (isTesting) {
    payment += '&bypassCardVerification=true';
  }

  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: paymentInstrumentId ? 'put' : 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: payment
      },
      credentials
    )
  );
}

export function creditCard(
  { url }: Mafia,
  credentials: Cookies = {},
  number,
  paymentInstrumentId,
  addressId,
  purchaseId,
  fetcher = timedFetch('associatePaymentInstrument')
) {
  const reqUrl = `${url}/v1/associatePaymentInstrument`;

  const formattedCreditCardValue = formatCreditCard(number);
  const creditCardNumber = formattedCreditCardValue.length ? formattedCreditCardValue : number;
  let data = `paymentInstrumentId=${paymentInstrumentId}&addCreditCardNumber=${creditCardNumber}`;

  if (addressId) {
    data += `&addressId=${addressId}`;
  }

  if (purchaseId) {
    data += `&purchaseId=${purchaseId}`;
  }

  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: data
      },
      credentials
    )
  );
}

export function primaryPayment(
  { amazonUrl }: Mafia,
  fullName,
  pid,
  credentials: Cookies = {},
  fetcher = timedFetch('makePrimaryPaymentInstrument')
) {
  return fetcher(
    `${amazonUrl}/v1/paymentInstruments`,
    fetchOpts(
      {
        method: 'put',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `paymentInstrumentId=${pid}&isPrimary=true&fullName=${fullName}`
      },
      credentials
    )
  );
}

export function getCustomer({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('customerInfo')) {
  return fetcher(`${url}/v1/customerInfo`, fetchOpts({}, credentials));
}

export function getCustomerAuthentication({ url }: Mafia, credentials, fetcher = timedFetch('customerAuth')) {
  // if at-main is available, use it and ubid-main via queryString, else pass it via cookies
  let queryParams = '';
  if (authHeadersInUse(credentials)) {
    queryParams = `?${stringify({
      'at-main': credentials['at-main'],
      'ubid-main': credentials['ubid-main']
    })}`;
  }
  return fetcher(`${url}/auth/v1/atvalid${queryParams}`, fetchOpts({ method: 'get' }, credentials));
}

export function getGiftCard({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getGiftCard')) {
  const sessionId = credentials['session-id'];
  return fetcher(`${url}/v1/giftCard?sessionId=${sessionId}`, fetchOpts({}, credentials));
}

export function claimGiftCard({ url }: Mafia, giftCardRedeemCode, credentials: Cookies = {}, fetcher = timedFetch('getGiftCard')) {
  return fetcher(
    `${url}/v1/giftCard`,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          claimCode: giftCardRedeemCode,
          sessionId: credentials['session-id']
        })
      },
      credentials
    )
  );
}

// My Account API Proxy
export function getOrdersV3(
  { url }: Mafia,
  { page = 1, pageSize = 10, gteDate, lteDate, mode, search }: Partial<OrderQueryParams>,
  credentials: Cookies = {},
  fetcher = timedFetch('getOrdersV3')
): Promise<Response<OrdersResponse>> {
  const searchParams = new URLSearchParams();
  searchParams.append('pageSize', pageSize.toString());
  searchParams.append('pageNum', page.toString());

  if (gteDate) {
    searchParams.append('gteDate', gteDate);
  }
  if (lteDate) {
    searchParams.append('lteDate', lteDate);
  }
  searchParams.append('includeTracking', 'true');
  if (mode) {
    searchParams.append('mode', mode);
  }
  if (search) {
    searchParams.append('search', search);
  }

  const queryString = searchParams.toString();
  const fullUrl = `${url}/v3/orders?${queryString}`;

  return fetcher(fullUrl, fetchOpts({}, credentials));
}

// My Account API Proxy
export function getOrderV3({ url }: Mafia, orderId, credentials: Cookies = {}, fetcher = timedFetch('getOrderV3')) {
  return fetcher(`${url}/v3/order?orderId=${orderId}&includeTracking=true`, fetchOpts({ method: 'get' }, credentials));
}

export function getGuestOrder({ url }: Mafia, orderId, credentials: Cookies = {}, fetcher = timedFetch('getGuestOrder')) {
  return fetcher(
    `${url}/accountapi/e/orders/getOrder?orderId=${orderId}&includeTracking=true`,
    fetchOpts(
      {
        method: 'get',
        headers: {
          [MAFIA_EMAIL_TOKEN]: credentials[EMAIL_TOKEN_COOKIE]
        }
      },
      credentials
    )
  );
}

// My Account Order History Search
export function getOrdersBySearch(
  { url }: Mafia,
  { search, page = 1, pageSize = 10, gteDate, lteDate, range, mode }: Partial<SearchOrderQueryParams>,
  credentials: Cookies = {},
  fetcher = timedFetch('getOrdersV3')
): Promise<Response<OrdersResponse>> {
  return fetcher(
    `${url}/accountapi/a/orders?${stringify({
      search,
      pageSize,
      page,
      startDate: gteDate,
      endDate: lteDate,
      includeTracking: true,
      range,
      mode
    })}`,
    fetchOpts({}, credentials)
  );
}

export function getOrdersByPurchaseIdV2({ url }: Mafia, pId, credentials: Cookies = {}, fetcher = timedFetch('getOrdersByPurchaseIdV2')) {
  return getOrdersByPurchaseIdByVersion(url, pId, credentials, fetcher, 'v2');
}

function getOrdersByPurchaseIdByVersion(url, pId, credentials: Cookies, fetcher, version) {
  return fetcher(`${url}/${version}/ordersByPurchaseId?includeTracking=true&purchaseId=${pId}`, fetchOpts({ method: 'get' }, credentials));
}

export function getPixelData({ url }: Mafia, purchaseId, credentials: Cookies = {}, fetcher = timedFetch('getPixel')) {
  const reqUrl = `${url}/v1/pixel?purchaseId=${purchaseId}`;
  return fetcher(reqUrl, fetchOpts({ method: 'get' }, credentials));
}

export function cancelGuestOrder({ url }: Mafia, { orderId, items }, credentials: Cookies = {}, fetcher = timedFetch('cancelGuestOrder')) {
  return fetcher(
    `${url}/accountapi/e/orders/cancelItems`,
    fetchOpts(
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          [MAFIA_EMAIL_TOKEN]: credentials[EMAIL_TOKEN_COOKIE]
        },
        body: JSON.stringify({ orderId, items })
      },
      credentials
    )
  );
}

export function cancelOrder({ url }: Mafia, { orderId, items }, credentials: Cookies = {}, fetcher = timedFetch('cancelOrder')) {
  return fetcher(
    `${url}/v2/order/cancel`,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderId, items })
      },
      credentials
    )
  );
}

// Delete payment instrument for a customer
export function deletePaymentInstrument(
  { amazonUrl }: Mafia,
  paymentId,
  credentials: Cookies = {},
  fetcher = timedFetch('deletePaymentInstrument')
) {
  return fetcher(`${amazonUrl}/v1/paymentInstruments/${paymentId}`, fetchOpts({ method: 'DELETE' }, credentials));
}

// Update payment method on an order
export function updatePayment({ url }: Mafia, { orderId, paymentInstrumentId }, credentials: Cookies = {}, fetcher = timedFetch('updatePayment')) {
  return fetcher(
    `${url}/v1/updatePayment?orderId=${orderId}&paymentInstrumentId=${paymentInstrumentId}`,
    fetchOpts({ method: 'POST' }, credentials)
  );
}

export function fetchTransportationOptions({ url }: Mafia, data, credentials: Cookies = {}, fetcher = timedFetch('fetchTransportationOptions')) {
  const opts = fetchOpts(
    {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(data)
    },
    credentials
  );
  return fetcher(`${url}/v1/return/transportOptions`, opts);
}

export function fetchGuestTransportationOptions(
  { url }: Mafia,
  data,
  credentials: Cookies = {},
  fetcher = timedFetch('fetchGuestTransportationOptions')
) {
  const opts = fetchOpts(
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        [MAFIA_EMAIL_TOKEN]: credentials[EMAIL_TOKEN_COOKIE]
      },
      body: JSON.stringify(data)
    },
    credentials
  );
  return fetcher(`${url}/v1/return/transportOptions`, opts);
}

// New Subscription service calls

const AUTH_ZSUBSCRIPTION_PATH = '/emailsubscriptions/subscriptionsapi/v2/a';
const TOKEN_ZSUBSCRIPTION_PATH = '/emailsubscriptions/subscriptionsapi/v2/n';
const CAPTCHA_ZSUBSCRIPTION_PATH = '/emailsubscriptions/subscriptionsapi/v2/c';

export function getSubscriptionVillageIdiot(
  { url }: Mafia,
  credentials: Cookies = {},
  emailCredentials = {},
  fetcher = timedFetch('getSubscriptionVillageIdiot')
) {
  const opts = fetchOpts(
    {
      method: 'GET',
      headers: { Accept: 'application/json' }
    },
    credentials,
    emailCredentials
  );
  return fetcher(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions?isCustomerFacing=true`, opts);
}

export function updateSubscriptionVillageIdiot(
  subscriptionsDelta,
  { url }: Mafia,
  credentials: Cookies = {},
  emailCredentials = {},
  fetcher = timedFetch('updateSubscriptionVillageIdiot')
) {
  const subscriptionData = {
    brandSubscriptions: subscriptionsDelta.brandSubscriptions,
    emailSubscriptions: subscriptionsDelta.emailLists,
    stockSubscriptions: subscriptionsDelta.stockSubscriptions,
    upcomingStyleSubscriptions: subscriptionsDelta.upcomingStyleSubscriptions
  };
  const opts = fetchOpts(
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(subscriptionData)
    },
    credentials,
    emailCredentials
  );
  return fetcher(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions`, opts);
}

export function unsubscribeFromAll({ url }: Mafia, credentials: Cookies = {}, emailCredentials = {}, fetcher = timedFetch('unsubscribeFromAll')) {
  const opts = fetchOpts(
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    },
    credentials,
    emailCredentials
  );
  return fetcher(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions`, opts);
}

function generateZSubscriptionUrl(mafiaUrl, token) {
  if (token) {
    return `${mafiaUrl}${TOKEN_ZSUBSCRIPTION_PATH}/subscriptions?tokenType=${token.tokenTypeOrAction}&token=${token.token}`;
  } else {
    return `${mafiaUrl}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions`;
  }
}

export function getSubscriptionMyAccount(
  { url }: Mafia,
  credentials: Cookies = {},
  token?: Record<string, any> | undefined,
  fetcher = timedFetch('getSubscriptionMyAccount')
) {
  const opts = fetchOpts(
    {
      method: 'GET',
      headers: { Accept: 'application/json' }
    },
    credentials
  );
  if (token) {
    return fetcher(`${generateZSubscriptionUrl(url, token)}`, opts);
  }
  return fetcher(`${generateZSubscriptionUrl(url, token)}?isCustomerFacing=true`, opts);
}

export function updateSubscriptionMyAccount(
  subscriptionsDelta,
  { url }: Mafia,
  credentials: Cookies = {},
  token = null,
  fetcher = timedFetch('updateSubscriptionMyAccount')
) {
  const opts = fetchOpts(
    {
      method: token ? 'POST' : 'PUT',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(subscriptionsDelta)
    },
    credentials
  );
  return fetcher(`${generateZSubscriptionUrl(url, token)}`, opts);
}

export function updateSubscriptionMyAccountUnsubscribeAll(
  { url }: Mafia,
  credentials: Cookies,
  token,
  fetcher = timedFetch('updateSubscriptionMyAccountUnsubscribeAll')
) {
  const opts = fetchOpts(
    {
      method: 'DELETE',
      headers: { Accept: 'application/json' }
    },
    credentials
  );
  return fetcher(`${url}${TOKEN_ZSUBSCRIPTION_PATH}/subscriptions/${token.tokenTypeOrAction}/${token.token}`, opts);
}

export function updateSubscriptionMyAccountOptOut(
  { url }: Mafia,
  credentials: Cookies,
  token,
  fetcher = timedFetch('updateSubscriptionMyAccountOptOut')
) {
  const opts = fetchOpts(
    {
      method: 'POST',
      headers: { Accept: 'application/json' }
    },
    credentials
  );
  return fetcher(`${url}${TOKEN_ZSUBSCRIPTION_PATH}/subscriptions/opt-out?token=${token}`, opts);
}

export function requestSubscriptionSignupCaptcha({ url }: Mafia, fetcher = timedFetch('requestSubscriptionSignupCaptcha')) {
  return requestCaptcha(url, `${CAPTCHA_ZSUBSCRIPTION_PATH}/subscribeToMarketingList`, fetcher, 'POST');
}

export function submitSubscriptionSignup(
  { url }: Mafia,
  credentials: Cookies,
  { recipientEmail, answer, token },
  fetcher = timedFetch('submitSubscriptionSignup')
) {
  const opts = fetchOpts(
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        ...captchaHeaders({ token, answer })
      },
      body: JSON.stringify({ emailAddress: recipientEmail })
    },
    credentials
  );
  return fetcher(`${url}${CAPTCHA_ZSUBSCRIPTION_PATH}/subscribeToMarketingList`, opts);
}

// Returns
export function getPrereturnInfo({ url }: Mafia, orderId, orderItemIds, credentials: Cookies = {}, fetcher = timedFetch('getPrereturnInfo')) {
  const baseUrl = new URL(`${url}/v1/return/initiate`);

  if (Array.isArray(orderId)) {
    baseUrl.searchParams.append('orderIds', orderId.join(','));
  } else {
    baseUrl.searchParams.append('orderId', orderId);
  }
  baseUrl.searchParams.append('asins', orderItemIds);

  return fetcher(baseUrl, fetchOpts({}, credentials));
}

export function placeGuestReturn({ url }: Mafia, reqData, credentials: Cookies, fetcher = timedFetch('placeGuestReturn')) {
  const reqUrl = `${url}/v1/return/submit`;
  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          [MAFIA_EMAIL_TOKEN]: credentials[EMAIL_TOKEN_COOKIE]
        },
        body: JSON.stringify(reqData)
      },
      credentials
    )
  );
}

export function placeReturn({ url }: Mafia, reqData, credentials: Cookies, fetcher = timedFetch('placeReturn')) {
  const reqUrl = `${url}/v1/return/submit`;
  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqData)
      },
      credentials
    )
  );
}

export function returnLabelInfo(
  { url }: Mafia,
  { rmaId, contractId, isReturnFetchOnlyEndpoint },
  credentials: Cookies = {},
  queryParamCredentials: Record<string, string> = {},
  fetcher = timedFetch('returnLabelInfo')
) {
  let baseUrl = '';

  //this is only to cover testing because new URL() will fail for non URL values for url property
  if (url === 'mafia') {
    const labelParam = contractId ? `contractId=${contractId}` : `rmaId=${rmaId}`;
    baseUrl = isReturnFetchOnlyEndpoint ? `${url}/v1/return/fetch?contractIds=${contractId}` : `${url}/v1/return/fetch/label?${labelParam}`;
  } else {
    baseUrl = new URL(`${url}/v1/return/fetch`);
    if (isReturnFetchOnlyEndpoint) {
      baseUrl.searchParams.append('contractIds', contractId);
    } else {
      baseUrl.pathname += '/label';
      if (contractId) {
        baseUrl.searchParams.append('contractId', contractId);
      } else {
        baseUrl.searchParams.append('rmaId', rmaId);
      }
    }
  }

  return fetcher(
    baseUrl,
    fetchOpts(
      { headers: { 'Content-Type': 'application/json', [MAFIA_EMAIL_TOKEN]: credentials[EMAIL_TOKEN_COOKIE] } },
      credentials,
      queryParamCredentials
    )
  );
}

export function getReturnLabelImageObjectUrl(
  labelImageUrl,
  credentials: Cookies = {},
  queryParamCredentials = {},
  fetcher = timedFetch('getReturnLabelImageObjectUrl')
) {
  if (!labelImageUrl) {
    return Promise.resolve(labelImageUrl);
  }
  return fetcher(labelImageUrl, fetchOpts({}, credentials, queryParamCredentials))
    .then(fetchApiNotAuthenticatedMiddleware)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob));
}

export function getRewardsV2({ url }: Mafia, akitaKey: string, credentials: Cookies = {}, fetcher = timedFetch('getRewardsV2')) {
  return fetcher(
    `${url}/akitav2/slotz/recognized/v1/vip_membership`,
    fetchOpts(
      {
        headers: { 'x-api-key': akitaKey }
      },
      credentials
    )
  );
}

export function getPerks(orderId: string, { url }: Mafia, akitaKey: string, credentials: Cookies = {}, fetcher = timedFetch('getPerks')) {
  return fetcher(
    `${url}/akitav2/slotz/recognized/v1/perks`,
    fetchOpts(
      {
        headers: {
          'x-api-key': akitaKey,
          'x-order-id': orderId
        }
      },
      credentials
    )
  );
}

export function enrollRewardsV2({ url }: Mafia, akitaKey: string, credentials: Cookies = {}, fetcher = timedFetch('signupForRewardsV2')) {
  return fetcher(
    `${url}/akitav2/slotz/full/v1/vip_membership/enroll`,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'x-api-key': akitaKey }
      },
      credentials
    )
  );
}

export function enrollRewardsWithEmailTokenV2(
  { url }: Mafia,
  akitaKey: string,
  credentials: Cookies = {},
  emailAuthToken,
  fetcher = timedFetch('signupForRewardsV2')
) {
  const queryParamCredentials = emailAuthToken ? { auth_token: emailAuthToken } : {};
  return fetcher(
    `${url}/akitav2/slotz/full/v1/vip_membership/enroll`,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'x-api-key': akitaKey }
      },
      credentials,
      queryParamCredentials
    )
  );
}

export function getRewardsEstimateV2(
  { url }: Mafia,
  akitaKey: string,
  credentials: Cookies = {},
  data,
  fetcher = timedFetch('getRewardsEstimateV2')
) {
  return fetcher(
    `${url}/akitav2/slotz/optional/v1/vip_points/estimate`,
    fetchOpts(
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': akitaKey
        },
        body: JSON.stringify(data)
      },
      credentials
    )
  );
}

export function setRefundOnFirstScanPreference(
  { url }: Mafia,
  akitaKey: string,
  credentials: Cookies = {},
  rofsPreference,
  fetcher = timedFetch('setRefundOnFirstScanPreference')
) {
  const headers = {
    'x-api-key': akitaKey,
    'Content-Type': 'application/json'
  };

  const opts = fetchOpts(
    {
      method: 'post',
      headers,
      body: JSON.stringify({ preference: rofsPreference })
    },
    credentials
  );

  const reqUrl = `${url}/akita/slotz/full/v1/customers/rofs_preference`;
  return fetcher(reqUrl, opts);
}

export function getEGiftCardDesigns({ url }: Mafia, fetcher = timedFetch('getEGCDesigns')) {
  return fetcher(`${url}/v1/eGiftCardDesigns`);
}

export function getPrimeStatus({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getPrimeStatus')) {
  const headers = {};

  if ('x-main' in credentials) {
    headers[MAFIA_RECOGNIZED_TOKEN] = credentials['x-main'];
  }

  return fetcher(`${url}/zap/lwa/customer/primeStatus`, {
    credentials: 'include',
    headers,
    fetcher
  });
}

// new ZSubscriptionService APIs

export function subscribeToStockNotificationZSub(
  { url }: Mafia,
  data,
  credentials: Cookies = {},
  fetcher = timedFetch('subscribeToStockNotificationZSub')
) {
  const additionalHeaders = {};
  if ('x-main' in credentials) {
    // for cart OOS notify me without being authed
    additionalHeaders[MAFIA_RECOGNIZED_TOKEN] = credentials['x-main'];
  }
  return sendJsonDataWithCreds(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions/stocks`, data, { credentials, fetcher, additionalHeaders });
}

export function subscribeToUpcomingStylesNotificationZSub(
  { url }: Mafia,
  data,
  credentials: Cookies = {},
  fetcher = timedFetch('subscribeToUpcomingStylesNotificationZSub')
) {
  const additionalHeaders = {};
  if ('x-main' in credentials) {
    // for cart OOS notify me without being authed
    additionalHeaders[MAFIA_RECOGNIZED_TOKEN] = credentials['x-main'];
  }

  return sendJsonDataWithCreds(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions/upcoming-styles`, data, { credentials, fetcher, additionalHeaders });
}

export function subscribeToListZSub({ url }: Mafia, data, credentials: Cookies = {}, fetcher = timedFetch('subscribeToListZSub')) {
  return sendJsonDataWithCreds(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions/lists`, data, { credentials, fetcher });
}

export function subscribeToBrandZSub({ url }: Mafia, data, credentials: Cookies = {}, fetcher = timedFetch('subscribeToBrandZSub')) {
  return sendJsonDataWithCreds(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscriptions/brands`, data, { credentials, fetcher });
}

export function subscribeToMarketingListZSub({ url }: Mafia, data, credentials: Cookies = {}, fetcher = timedFetch('subscribeToMarketingListZSub')) {
  return sendJsonDataWithCreds(`${url}${AUTH_ZSUBSCRIPTION_PATH}/subscribeToMarketingList`, data, { credentials, fetcher });
}

function requestCaptcha(mafiaBaseUrl, path, fetcher, method = 'get', credentials: Cookies = {}) {
  return fetcher(
    `${mafiaBaseUrl}${path}`,
    fetchOpts(
      {
        method,
        headers: { [MAFIA_CAPTCHA_REQ]: 'v1', [MAFIA_CAPTCHA_AUDIO_REQ]: 'v1' }
      },
      credentials
    )
  );
}

// Called when MelodyNewsfeed component mounts
export function postNewsfeedImpression(
  { url }: Mafia,
  { type, eventId, lineItemId, completed },
  credentials: Cookies = {},
  fetcher = timedFetch('newsfeedImpression')
) {
  const data = {
    [type]: [
      {
        lineItemId,
        eventId,
        completed
      }
    ]
  };
  return sendJsonDataWithCreds(`${url}/cronkite/newsFeed`, data, {
    credentials,
    fetcher
  });
}

export function postFitSurveyReply({ url }: Mafia, data, credentials: Cookies = {}, fetcher = timedFetch('newsfeedFitSurveyReply')) {
  return sendJsonDataWithCreds(`${url}${ENDPOINT_OPAL_EXPLICITFITS}`, data, {
    credentials,
    fetcher
  });
}

export function updateFitSurveyReply(
  { url }: Mafia,
  { id, deleteFit },
  data,
  credentials: Cookies = {},
  fetcher = timedFetch('newsfeedUpdateFitSurveyReply')
) {
  return sendJsonDataWithCreds(`${url}${ENDPOINT_OPAL_EXPLICITFITS}/${id}`, data, { credentials, fetcher, method: deleteFit ? 'delete' : 'put' });
}

export function dismissNewsfeed({ url }: Mafia, { type, eventId, lineItemId }, credentials: Cookies = {}, fetcher = timedFetch('dismissNewsfeed')) {
  const reqUrl = `${url}/cronkite/newsFeed?type=${type}&eventId=${eventId}&lineItemId=${lineItemId}`;
  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'delete',
        headers: { 'Content-Type': 'application/json' }
      },
      credentials
    )
  );
}

export function cartCount({ amazonUrl }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getCartCount')) {
  return fetcher(`${amazonUrl}/v1/getCartItemsCount`, fetchOpts({}, credentials));
}

export function recommendationsSearch(
  { url }: Mafia,
  credentials: Cookies = {},
  numberOfRecos = 25,
  slotName = 'zap-hp-vh',
  fetcher = timedFetch('recommendationsSearch')
) {
  const janusUrl = `${url}/janus/recos/get?limit=${numberOfRecos}&widgets=${slotName}`;

  return fetcher(janusUrl, fetchOpts({}, credentials));
}

export function getExplicitFits({ url }: Mafia, credentials: Cookies = {}, fetcher = timedFetch('getExplicitFits')) {
  return fetcher(`${url}${ENDPOINT_OPAL_EXPLICITFITS}`, fetchOpts({}, credentials));
}

interface JanusFetchOpts {
  params: JanusParams;
  widgets: string;
  limit?: number | string;
  credentials?: any; // TODO ts type this with creds;
  dispatch: ThunkDispatch<AppState, void, AnyAction>;
  getState: () => AppState;
}

export function getJanusRecos(
  { url }: Mafia,
  { params, widgets, limit = 5, credentials = {}, dispatch, getState }: JanusFetchOpts,
  fetcher = timedFetch('janusRecos')
): Promise<Recos> {
  const query = stringify(
    {
      widgets,
      limit,
      ...params
    },
    { encode: false }
  );
  return fetcher(
    `${url}/janus/recos/get?${query}`,
    fetchOpts(
      {
        credentials: 'include'
      },
      credentials
    )
  )
    .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
    .then(fetchErrorMiddleware);
}

export async function getSurveyQuestions({ url }: Mafia, surveyName, fetcher = timedFetch('getSurveyQuestions'), abortController?) {
  const reqUrl = `${url}/auscult/unauth/survey/take/${surveyName}`;
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  const response = await fetcher(reqUrl, { headers: headers, signal: abortController?.signal });
  const json = await response.json();
  return json;
}

export async function saveSurveyQuestionResponse({ url }: Mafia, params, credentials: Cookies, fetcher = timedFetch('saveSurveyQuestionResponse')) {
  const { csrfToken, feedback, source, surveyId, questionId, sessionReplayLink } = params;
  const reqUrl = `${url}/auscult/unauth/survey/take/${surveyId}`;

  const headers = { 'Content-Type': 'application/json' };
  const submission = {
    _csrf_token: csrfToken,
    id: surveyId,
    take_survey: {
      [`q${questionId}`]: feedback,
      [`qid${questionId}`]: `${questionId}`
    },
    metadata: { source, session_replay_link: sessionReplayLink }
  };
  const opts = fetchOpts(
    {
      method: 'post',
      headers,
      body: JSON.stringify(submission)
    },
    credentials
  );
  const response = await fetcher(reqUrl, opts);
  const json = await response.json();
  return json;
}

export async function saveMultiSurveyQuestionResponse(
  { url }: Mafia,
  params,
  credentials: Cookies,
  fetcher = timedFetch('saveSurveyQuestionResponse')
) {
  const { csrfToken, responses, surveyId } = params;
  const reqUrl = `${url}/auscult/unauth/survey/take/${surveyId}`;

  const takeSurveyData = Object.entries(responses).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [`q${key}`]: val,
      [`qid${key}`]: key
    }),
    []
  );

  const headers = { 'Content-Type': 'application/json' };
  const submission = {
    _csrf_token: csrfToken,
    id: surveyId,
    take_survey: {
      ...takeSurveyData
    }
  };
  const opts = fetchOpts(
    {
      method: 'post',
      headers,
      body: JSON.stringify(submission)
    },
    credentials
  );
  const response = await fetcher(reqUrl, opts);
  const json = await response.json();
  return json;
}

// Exchanges

export function initiateExchange(
  { url }: Mafia,
  orderId: string,
  orderItemId: string,
  credentials: Cookies = {},
  fetcher = timedFetch('initiateExchange')
) {
  return fetcher(`${url}/v1/exchange/initiate?orderId=${orderId}&asins=${orderItemId}`, fetchOpts({}, credentials));
}

export function placeExchange({ url }: Mafia, reqData: object, credentials: Cookies = {}, fetcher = timedFetch('placeExchange')) {
  const reqUrl = `${url}/v1/exchange/submit`;
  return fetcher(
    reqUrl,
    fetchOpts(
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqData)
      },
      credentials
    )
  );
}

export function previewExchangeEligibility(
  { url }: Mafia,
  orderId: string,
  credentials: Cookies = {},
  fetcher = timedFetch('previewExchangeEligibility')
) {
  return fetcher(`${url}/v1/exchange/previewEligibility?orderId=${orderId}`, fetchOpts({}, credentials));
}

export function fetchExchangeOrderId({ url }: Mafia, contractId: string, credentials: Cookies = {}, fetcher = timedFetch('viewExchangeOrderId')) {
  return fetcher(`${url}/v1/exchange/fetch?contractId=${contractId}`, fetchOpts({}, credentials));
}

// Zappos At Work APIs
export function getZawPromotions(
  { url }: Mafia,
  promotionIds: string[],
  extractedAsins: string[],
  orderId: string,
  credentials: Cookies = {},
  fetcher = timedFetch('getZawPromotions')
) {
  const dedupedPromotionIds = [...new Set(promotionIds)];
  const params = new URLSearchParams();
  params.append('promotionIds', JSON.stringify(dedupedPromotionIds));
  params.append('asins', JSON.stringify(extractedAsins));
  params.append('orderId', orderId);
  return fetcher(
    `${url}/slotz/zawSubsidyReissue/v1/checkEligibility?${params.toString()}`,
    fetchOpts({ credentials: 'include', method: 'get' }, credentials)
  );
}

// Visual Search
export function getVsPresignedUrl(
  { url }: Mafia,
  queryId: string,
  credentials: Cookies = {},
  signal: AbortSignal,
  fetcher = timedFetch('getVsPresignedUrl')
): Promise<Response<UploadImageResponse>> {
  return sendJsonDataWithCreds(
    `${url}/slotz/vs/imageUploadUrl`,
    { queryId: queryId, clientId: 'VisualSearch' },
    {
      credentials,
      fetcher,
      method: 'post',
      signal
    }
  );
}

export function getBoundingBoxes(
  { url }: Mafia,
  reqData: object,
  credentials: Cookies = {},
  signal: AbortSignal,
  fetcher = timedFetch('getBoundingBoxes')
): Promise<Response<BoundingBoxApiResponse>> {
  return fetcher(
    `${url}/slotz/vs/getImageBoundingBox`,
    fetchOpts(
      {
        credentials: 'include',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqData),
        signal
      },
      credentials
    )
  );
}

export function getStyleIdsForBB(
  { url }: Mafia,
  reqData: object,
  credentials: Cookies = {},
  signal: AbortSignal,
  fetcher = timedFetch('getImageMatchSearch')
): Promise<Response<ImageMatchSearchResponse>> {
  return fetcher(
    `${url}/slotz/vs/imageMatchSearch`,
    fetchOpts(
      {
        credentials: 'include',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqData),
        signal
      },
      credentials
    )
  );
}

export const cancelReturnGuest = async (
  { url }: Mafia,
  data: object,
  credentials: Cookies = {},
  fetcher = timedFetch('placeCancelReturnGuest')
): Promise<Response> => {
  const fetchUrl = `${url}/v1/return/cancelReturnItems`;

  const options = fetchOpts(
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        [MAFIA_EMAIL_TOKEN]: credentials[EMAIL_TOKEN_COOKIE]
      },
      body: JSON.stringify(data)
    },
    credentials
  );

  return fetcher(fetchUrl, options);
};

export const cancelReturn = async (
  { url }: Mafia,
  data: object,
  credentials: Cookies = {},
  fetcher = timedFetch('placeCancelReturn')
): Promise<Response> => {
  const fetchUrl = `${url}/v1/return/cancelReturnItems`;
  const options = fetchOpts({ method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) }, credentials);

  return fetcher(fetchUrl, options);
};
