import { PromotionType } from 'constants/promotions';
import { track } from 'apis/amethyst';
import { evZawReturn } from 'events/returns';
import { ZAW_PROGRAM_NAME, ZawSubsidyReissueEligibility } from 'constants/zaw';

export const promotionsAlreadyFetched = zawPromotions => zawPromotions !== null && zawPromotions !== undefined;

export const shouldFetchPromotions = ({ zawPromotions, isRequestingZawPromotions }) =>
  !promotionsAlreadyFetched(zawPromotions) && !isRequestingZawPromotions;

export const extractPromoIds = ordersMap => Object.values(ordersMap).flatMap(mapOrderToNonEmptyPromoIds);

export const containsZawPromotions = promosMap => {
  const zawPromos = Object.values(promosMap).filter(filterOutNonZawPromos);
  return zawPromos.length > 0;
};

export const filterOutNonZawPromos = promo => {
  const isSubsidy = promo.promotionType === PromotionType.SUBSIDY;
  const isExchange = promo.promotionType === PromotionType.EXCHANGE;
  return isSubsidy || isExchange;
};

export const filterOutExpiredPromos = promo => promo.expirationDate > Date.now();

export const filterOutNonReissuablePromos = promo => {
  const hasReissuablePromoId = Boolean(getReissuablePromoId(promo));
  return hasReissuablePromoId;
};

export const shouldReissuePromos = (enabled, zawSubsidyReissueEligibility) =>
  enabled && zawSubsidyReissueEligibility === ZawSubsidyReissueEligibility.SUBSIDY_WILL_BE_REISSUED;

export const extractReissuablePromosForBox = (items, ordersMap, promotionsMap, promotionsAlreadyUsedInOtherBoxes) => {
  const mapPromotionToApiExpectedShape = promo => {
    const formattedPromo = {
      promotionId: getReissuablePromoId(promo),
      shouldIssueClaimCode: true,
      claimAmount: promo.amount,
      program: ZAW_PROGRAM_NAME
    };
    if (promo?.obfPromotionId) {
      formattedPromo.originalPromoId = promo.obfPromotionId;
    }
    return formattedPromo;
  };

  const filterOutPromosAlreadyUsedInOtherBoxes = promo => !promotionsAlreadyUsedInOtherBoxes.includes(promo.promotionId);

  return items
    .map(item => item.orderId)
    .map(orderId => ordersMap[orderId])
    .filter(order => Boolean(order))
    .flatMap(order => getPromosMergedWithZawDataForOrder(order, promotionsMap))
    .filter(filterOutNonZawPromos)
    .filter(filterOutNonReissuablePromos)
    .map(mapPromotionToApiExpectedShape)
    .filter(filterOutPromosAlreadyUsedInOtherBoxes);
};

export const isZAWReturnsEnabled = ({ marketplace }) => {
  const {
    returns: { enableZAWReturns: isZAWReturnsEnabledInMarketplace }
  } = marketplace;
  return isZAWReturnsEnabledInMarketplace;
};

export const getPromosMergedWithZawDataForOrder = (order, promotionsMap) => {
  const promosWithAmounts = [];
  order.pricing.pretax.forEach(({ promoId, amount }) => {
    const parsedAmount = amount ? getParsedPromoAmount(amount) : null;
    if (promoId && parsedAmount) {
      const promoWithAmount = {
        promoId,
        amount: parsedAmount,
        ...promotionsMap[promoId]
      };
      promosWithAmounts.push(promoWithAmount);
    }
  });
  return promosWithAmounts;
};

export const addRawPromoAmountsFromOrderToPromos = (promotionsMap, orders) => {
  const promosIds = Object.keys(promotionsMap);
  const promotionsMapWithAmounts = {};
  promosIds.forEach(promoId => {
    const promoInfo = promotionsMap[promoId];
    const amountsPerOrder = getPromoAmountUsedPerOrder(promoId, orders);
    promotionsMapWithAmounts[promoId] = {
      ...promoInfo,
      amountsPerOrder
    };
  });
  return promotionsMapWithAmounts;
};

export const getAsinsFromOrdersMap = ordersMap => {
  const ordersList = Object.values(ordersMap);
  const asins = [];
  ordersList.forEach(({ lineItems }) => {
    lineItems.forEach(lineItem => {
      if (lineItem?.product?.asin) {
        asins.push(lineItem.product.asin);
      }
    });
  });

  return asins;
};

const getPromoAmountUsedPerOrder = (promoId, orders) => {
  const amountsPerOrder = {};
  orders.forEach(order => {
    const orderPromos = order.pricing.pretax;
    const amount = orderPromos.find(orderPromo => orderPromo.promoId === promoId)?.amount;
    const rawAmount = amount ? getParsedPromoAmount(amount) : null;
    if (rawAmount) {
      amountsPerOrder[order.orderId] = rawAmount;
    }
  });
  return amountsPerOrder;
};

export const getReissuablePromos = promotions => {
  const allZawPromos = Object.values(promotions).filter(filterOutNonZawPromos);
  const reissuableZawPromos = allZawPromos.filter(filterOutNonReissuablePromos);
  return reissuableZawPromos;
};

export const getTotalSubsidyAmount = promos => {
  const subsidyAndExchangePromos = promos.filter(filterOutNonZawPromos);
  return getTotalPromoAmount(subsidyAndExchangePromos);
};

export const getTotalDiscountAmount = promos => {
  const discountPromos = promos.filter(filterOutNonDiscountPromos);
  return getTotalPromoAmount(discountPromos);
};

export const shouldSetZawSubsidyReissueEligibility = (enabled, account, zawSubsidyReissueEligibility) => {
  if (!enabled) {
    return false;
  }
  if (zawSubsidyReissueEligibility !== null && zawSubsidyReissueEligibility !== undefined) {
    return false;
  }

  const { zawPromotions } = account;
  const hasZawPromos = promotionsAlreadyFetched(zawPromotions) && containsZawPromotions(zawPromotions);

  return hasZawPromos;
};

export const getZawSubsidyReissueEligibility = (account, ordersMap) => {
  const { zawPromotions: promos, cancelOrReturnItems: returnItems, zawPromotionsStatus } = account;

  if (!promotionsAlreadyFetched(promos)) {
    return null;
  }

  if (!containsZawPromotions(promos)) {
    return null;
  }

  // used for zaw fraud status
  if (zawPromotionsStatus?.toLowerCase().includes('fraud detected')) {
    return ZawSubsidyReissueEligibility.RETURN_NEEDED;
  }

  if (zawPromotionsStatus?.toLowerCase().includes('return beyond time limit')) {
    return ZawSubsidyReissueEligibility.SUBSIDY_EXPIRED_NOT_REISSUED;
  }

  const isSingleItemOrder = returnContainsOneItemFromSingleItemOrder(ordersMap, returnItems);

  if (!isSingleItemOrder) {
    return ZawSubsidyReissueEligibility.ORDER_CONTAINS_MULTIPLE_ITEMS;
  }

  const allZawPromos = Object.values(promos).filter(filterOutNonZawPromos);
  const reissuableZawPromos = allZawPromos.filter(filterOutNonReissuablePromos);

  const noZawPromosCanBeReissued = reissuableZawPromos.length === 0;
  if (noZawPromosCanBeReissued) {
    return ZawSubsidyReissueEligibility.GENERIC_WILL_NOT_BE_REISSUED;
  }

  return ZawSubsidyReissueEligibility.SUBSIDY_WILL_BE_REISSUED;
};

const getReissuablePromoId = promo => {
  if (!promo) {
    return null;
  }
  if (promo.promotionType === PromotionType.SUBSIDY) {
    return promo.relatedPromoExchangeId;
  }
  if (promo.promotionType === PromotionType.EXCHANGE) {
    return promo.obfPromotionId;
  }
  return null;
};

export const returnContainsOneItemFromSingleItemOrder = (ordersMap, returnItems) => {
  if (!returnItems || returnItems.length !== 1) {
    return false;
  }
  const { orderId } = returnItems[0];
  const order = ordersMap?.[orderId];
  return order?.lineItems?.length === 1;
};

const getParsedPromoAmount = amount => {
  const parsedAmount = amount.replace(/[^\d.-]/g, '');
  return parsedAmount ? parseFloat(parsedAmount) : null;
};

export const mapNewEndpointToOldFormat = promotions => {
  const promotionMap = {};
  promotions?.forEach(promotion => {
    promotionMap[promotion.obfPromotionId] = promotion;
  });
  return promotionMap;
};

const getTotalPromoAmount = promos => promos.reduce((acc, promo) => acc + promo.amount, 0);

const filterOutNonDiscountPromos = promo => promo.promotionType === PromotionType.DISCOUNT;

const mapOrderToNonEmptyPromoIds = order => order.pricing.pretax.map(pretax => pretax.promoId).filter(promoId => !!promoId);

export const trackMetric = ({ scenario, reissuableZawPromos, step }, tracker = track) => {
  const trackFullEvent = Boolean(reissuableZawPromos && reissuableZawPromos.length > 0);
  if (trackFullEvent) {
    reissuableZawPromos.forEach(promo => {
      const subsidyValue = getPromoAmount(promo);
      const event = { step, scenario, subsidyValue };
      tracker(() => [evZawReturn, event]);
    });
  } else {
    tracker(() => [evZawReturn, { step, scenario }]);
  }
};

/**
 * TODO: eventually, we'll need to know the right order for which this promo amount will be reissued
 * right now, given the restriction of zaw returns with only one item, we can safely get the first and only element
 * https://sim.amazon.com/issues/P63396184 [Follow-up] Multi-item Order Returns
 */
const getPromoAmount = promo => {
  const amounts = Object.values(promo.amountsPerOrder || {});
  return amounts[0];
};

export default {
  promotionsAlreadyFetched,
  shouldFetchPromotions,
  extractPromoIds,
  containsZawPromotions,
  filterOutNonZawPromos,
  filterOutNonReissuablePromos,
  shouldReissuePromos,
  extractReissuablePromosForBox,
  isZAWReturnsEnabled,
  getPromosMergedWithZawDataForOrder,
  getTotalSubsidyAmount,
  getTotalDiscountAmount,
  trackMetric,
  shouldSetZawSubsidyReissueEligibility,
  getZawSubsidyReissueEligibility,
  addRawPromoAmountsFromOrderToPromos,
  getReissuablePromos,
  returnContainsOneItemFromSingleItemOrder,
  mapNewEndpointToOldFormat,
  getAsinsFromOrdersMap
};
