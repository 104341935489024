import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { deepEqual } from 'fast-equals';

// constants & regex
import { NON_SEARCH_PREFIXES, NULL_SEARCH_RE, OOS_REDIRECTED_SEARCH_RE, PRODUCT_ASIN, ZSO_URL_RE } from 'common/regex';
// actions
import { setError } from 'actions/errors';
import { redirectWithAppRoot } from 'actions/redirect';
import usePrevious from 'hooks/usePrevious';
import { fetchFromSearch, fetchFromZso, processReceivedSearchResponse } from 'actions/fancyUrls';
import NoSearchResults from 'components/search/NoSearchResults';
import useMartyContext from 'hooks/useMartyContext';
// resources
import { productBundle } from 'apis/cloudcatalog';
import { logDebug } from 'middleware/logger';
import { trackEvent, trackLegacyEvent } from 'helpers/analytics';
import { FetchError, fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { setSessionCookies } from 'actions/session';
import ProductUtils from 'helpers/ProductUtils';
import { isDesktop } from 'helpers/ClientUtils';
import { isPrettySearchOrSlashSearchPath, normalizeSearchLocation, shouldSearchServiceBeCalled } from 'helpers/SearchUtils';
import { evSearchPageView } from 'events/search';
import { PAGE_TYPES } from 'constants/appConstants';

const SearchLogic = props => {
  // marketplace
  const {
    router,
    marketplace: {
      search: { oosMessaging, useAutoCorrect },
      checkout: { allowMoveToFavorites },
      hasBannerAds,
      hasHeartCounts
    }
  } = useMartyContext();

  const {
    params: { seoName }
  } = useRouteMatch();

  const {
    children,
    location,
    setHFSearchTerm,
    filters,
    setOosMessaging,
    getHearts,
    pageTypeChange,
    fetchSymphonySearchComponents,
    fetchLandingPageInfo,
    getHeartCounts,
    setUrlUpdated,
    filters: { autocorrect, executedSearchUrl: filtersExecutedSearchUrl, term, originalTerm, staleProducts, shouldUrlUpdate },
    products: { executedSearchUrl, list, isLoading, inlineRecos, recommendations, isBlacklisted, requestedUrl, oosMessaging: productOosMessaging },
    fetchFromSearch,
    fetchFromZso,
    makeScrollhandler,
    isVip,
    landingPage,
    facets,
    fireSearchPixels,
    pixelFacetData
  } = props;

  // Search loaded
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollHandlerLoaded, setScrollHandlerLoaded] = useState(false);
  const prevLocation = usePrevious(location);
  const prevStale = usePrevious(staleProducts);

  const requestSearchResultsForLocation = useCallback(
    ({ location, isFresh, bypassCache, shouldAppendResults }) => {
      if (ZSO_URL_RE.test(location.pathname)) {
        return fetchFromZso({
          location,
          isFresh,
          bypassCache,
          shouldAppendResults
        });
        // this should not run on PDP forward transtion
      } else if (!location.pathname.includes('/p/')) {
        return fetchFromSearch({
          location: normalizeSearchLocation(location),
          isFresh,
          bypassCache,
          shouldAppendResults
        });
      }
    },
    [fetchFromSearch, fetchFromZso]
  );

  const hasProductResults = () => !!list?.length;

  const shouldShowNoResults = () => !!requestedUrl && !isLoading && !hasProductResults();

  const hasProducts = hasProductResults();

  const getPageFromUrlSearch = search => {
    const nextUrlSearchParsed = parse(search);
    return nextUrlSearchParsed?.p && parseInt(nextUrlSearchParsed.p, 10);
  };

  const nextUrlSearchPageParam = getPageFromUrlSearch(location.search);

  const mobileScrollToTop = useCallback(() => !isDesktop() && window.scrollTo(0, 0), []);

  useEffect(() => {
    if (originalTerm || autocorrect?.termBeforeAutocorrect) {
      // keep the header term in sync with the searched for term.
      const autoCorrectTerm = autocorrect?.termBeforeAutocorrect;
      setHFSearchTerm((useAutoCorrect && autoCorrectTerm) || originalTerm); // For Marty HF
    }

    if (originalTerm || (!isLoaded && filtersExecutedSearchUrl)) {
      setIsLoaded(true);
    }
  }, [originalTerm, filtersExecutedSearchUrl, isLoaded, setHFSearchTerm, useAutoCorrect, autocorrect.termBeforeAutocorrect]);

  useEffect(() => {
    if (allowMoveToFavorites) {
      getHearts();
      if (!hasHeartCounts) return;
      if (list.length) {
        const styles = list.reduce((arr, val) => {
          const related = val?.relatedStyles?.filter((_, i) => i > 0) || [];
          return arr.concat(val, related);
        }, []);

        getHeartCounts(styles);
      }

      if (inlineRecos?.recos) {
        getHeartCounts(inlineRecos.recos);
      }
    }
  }, [list, getHeartCounts, recommendations, allowMoveToFavorites, getHearts, inlineRecos]);

  useEffect(() => {
    if (hasBannerAds) {
      fetchSymphonySearchComponents();
    }
  }, [hasBannerAds, filtersExecutedSearchUrl, fetchSymphonySearchComponents]);

  // Search tracking once on load since first search results are server side (client side tracking is done on async search response)
  useEffect(() => {
    const isNullSearchPage = NULL_SEARCH_RE.test(`${location.pathname}${location.search}`);
    const isZSOPageWithoutSearch = ZSO_URL_RE.test(location.pathname) && !location.search.includes('term') && !location.search;
    if (isNullSearchPage || isZSOPageWithoutSearch) {
      evSearchPageView(props);
    }
  }, []);

  // componentDidMount
  useEffect(() => {
    pageTypeChange(PAGE_TYPES.SEARCH_PAGE);
  }, [pageTypeChange]);

  useEffect(() => {
    // This entire effect is a massive antipattern
    // The URL should be entirely dictating the state of the page, rather than
    if (shouldUrlUpdate) {
      const isTermSearch = !!(
        requestedUrl &&
        (NULL_SEARCH_RE.test(requestedUrl) || OOS_REDIRECTED_SEARCH_RE.test(requestedUrl)) &&
        (term || originalTerm)
      );
      if (isTermSearch) {
        setUrlUpdated();
        // no nothing
      } else if (
        filtersExecutedSearchUrl &&
        (!nextUrlSearchPageParam || prevStale) &&
        (!location.action || (!!location.action && location.action === 'PUSH') || !!prevLocation || !!location.search.length || !term)
      ) {
        logDebug(`pushing browser url to ${executedSearchUrl}`);
        // Order matters here to prevent a double push to the same URL
        setUrlUpdated();
        router.forceBrowserPush(executedSearchUrl);
      }
    }
  }, [requestedUrl, executedSearchUrl, router, prevStale, shouldUrlUpdate, location, setUrlUpdated, term, originalTerm, filtersExecutedSearchUrl]);

  useEffect(() => {
    if (!isBlacklisted && shouldSearchServiceBeCalled(location, seoName, executedSearchUrl) && !deepEqual(location, prevLocation)) {
      // breadcrumb/page update
      logDebug('calling products due to breadcrumb or page update');
      requestSearchResultsForLocation({ location });
      mobileScrollToTop();
    }
  }, [location, requestSearchResultsForLocation, prevLocation, isBlacklisted, mobileScrollToTop, executedSearchUrl, seoName]);

  useEffect(() => {
    if (!isBlacklisted && staleProducts && staleProducts !== prevStale) {
      // facet/sort update
      // not yet requested
      logDebug('stale products due to facet or sort update');
      const { facetUrlQueryParams, facetUrlPath } = facets;
      const mockLocation = { pathname: facetUrlPath, search: facetUrlQueryParams ? `?${facetUrlQueryParams}` : '' };
      requestSearchResultsForLocation({ location: mockLocation });
      mobileScrollToTop();
    }
  }, [
    requestedUrl,
    router,
    location,
    staleProducts,
    requestSearchResultsForLocation,
    setOosMessaging,
    filters,
    isBlacklisted,
    mobileScrollToTop,
    prevLocation,
    prevStale
  ]);

  useEffect(() => {
    // Watch scrolling for scrollToTop
    if (!scrollHandlerLoaded && makeScrollhandler && hasProducts) {
      setScrollHandlerLoaded(true);
      makeScrollhandler();
    }
  }, [hasProducts, scrollHandlerLoaded, makeScrollhandler]);

  useEffect(() => {
    // if they we redirected to search due to an oos item
    const oosRedirected = new URLSearchParams(location.search).get('oosRedirected');
    if (!productOosMessaging && oosRedirected) {
      setOosMessaging(oosMessaging);
    } else if (productOosMessaging && !oosRedirected) {
      setOosMessaging(null);
    }
    // Empty array ensures that oosMessaging is updated only once regardless of fancy url update
  }, [productOosMessaging]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fireSearchPixels(term, list, pixelFacetData, filters);
  }, []);

  return (
    <>
      {shouldShowNoResults() && (
        <NoSearchResults filters={filters} landingPage={landingPage} isVip={isVip} fetchLandingPageInfo={fetchLandingPageInfo} />
      )}
      {children({
        hasProductResults,
        shouldShowNoResults
      })}
    </>
  );
};

SearchLogic.build404ErrorMessage = () => setError('404 not found', new Error('Not a Search URL.'), 404);

SearchLogic.fetchDataOnServer = (
  store,
  location,
  params,
  { zsoFetch = fetchFromZso, searchFetch = fetchFromSearch, redirect = redirectWithAppRoot, asinFetch = productBundle } = {}
) => {
  const { dispatch, getState } = store;
  const qs = location.pathname === '/search' ? parse(location.search) : {};
  const potentialAsin = (params.seoName || qs.term || '').toUpperCase();

  if (ZSO_URL_RE.test(location.pathname)) {
    if (NON_SEARCH_PREFIXES.test(location.pathname)) {
      return dispatch(SearchLogic.build404ErrorMessage());
    } else {
      return dispatch(zsoFetch({ location, keepParams: true }));
    }
  } else if (PRODUCT_ASIN.test(potentialAsin)) {
    const state = getState();
    const {
      environmentConfig: {
        api: { cloudcatalog: cloudcatalogInfo }
      }
    } = state;
    return asinFetch(cloudcatalogInfo, { asin: potentialAsin })
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState, true))) // will skip setting cookies if there are missing cookies
      .then(fetchErrorMiddleware)
      .then(response => {
        const productReceived = response.statusCode === '200' && response.product.length;
        if (productReceived) {
          const productUrl = ProductUtils.getProductUrlFromAsin(response.product[0], potentialAsin);
          if (productUrl) {
            return dispatch(redirect(productUrl));
          }
        }
        throw new FetchError(location.pathname, response.statusCode, `Unexpected CloudCatalogAPI response for ASIN: ${potentialAsin}`);
      })
      .catch(() => {
        if (isPrettySearchOrSlashSearchPath(location.pathname)) {
          return dispatch(
            searchFetch({
              location: normalizeSearchLocation(location),
              keepParams: true
            })
          );
        } else {
          return dispatch(SearchLogic.build404ErrorMessage());
        }
      });
  } else if (isPrettySearchOrSlashSearchPath(location.pathname)) {
    return dispatch(
      searchFetch({
        location: normalizeSearchLocation(location),
        keepParams: true,
        isSearchHappeningServerSide: true
      })
    );
  } else {
    return dispatch(SearchLogic.build404ErrorMessage());
  }
};

SearchLogic.afterFetchDataOnServer = ({ dispatch, getState, doProcessReceivedSearchResponse = processReceivedSearchResponse }) => {
  const {
    products: { deferredSearchResponse }
  } = getState();
  if (!deferredSearchResponse) {
    return;
  }
  const { response, parsedParams, completeUrl } = deferredSearchResponse;

  doProcessReceivedSearchResponse(response, dispatch, getState, parsedParams, completeUrl);
};

SearchLogic.defaultProps = {
  trackEvent,
  trackLegacyEvent
};

SearchLogic.propTypes = {
  products: PropTypes.object.isRequired,
  filters: PropTypes.object,
  facets: PropTypes.object,
  setOosMessaging: PropTypes.func
};

export default SearchLogic;
