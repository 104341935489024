import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchSubscriptionsInfo, REQUEST_TYPE_VILLAGEIDIOT } from 'actions/account/subscriptions';
import type { AppDispatch } from 'entrypoints/bootstrapOnClient';

const useFetchSubscriptions = ({ background = false, isCustomer }: { background?: boolean; isCustomer: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (isCustomer) {
      dispatch(
        fetchSubscriptionsInfo({
          background,
          requestSpecs: {},
          requestType: REQUEST_TYPE_VILLAGEIDIOT
        })
      );
    }
  }, [isCustomer]);
};

export default useFetchSubscriptions;
