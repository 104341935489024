import { Component, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deepEqual } from 'fast-equals';

import { cn } from 'helpers/classnames';
import { SEARCH_PAGE } from 'constants/amethystPageTypes';
import Rating from 'components/Rating';
import { constructMSAImageUrl, makeAscii } from 'helpers';
import { isProductHearted, makeHandleHeartButtonClick } from 'helpers/HeartUtils';
import ProductUtils from 'helpers/ProductUtils';
import { SmallLoader } from 'components/Loader';
import SocialCollectionsWidget from 'components/account/Collections/SocialCollectionsWidget';
import Image from 'components/common/Image';
import Heart from 'components/common/Heart';
import marketplace from 'cfg/marketplace.json';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { MartyContext } from 'utils/context';
import SponsoredBanner from 'components/common/SponsoredBanner';
import { usdToNumber } from 'helpers/NumberFormats';

// order matters
import commonCardCss from 'styles/components/common/melodyCard.scss';
import css from 'styles/components/common/melodyCardProduct.scss';

const {
  melodyProductCard: { defaultNoBackground, defaultComponentStyle, forceHighRes, hasStyleRoomProductFlag, hideMsrp = false }
} = marketplace;

const MakeSocialCollectionWidget = ({ styleId, isCollectionShown, productId, colorId, price }) => {
  const getStyleId = useCallback(() => styleId, [styleId]);

  if (!(styleId && isCollectionShown)) {
    return null;
  }

  return (
    <div className={css.collection}>
      <SocialCollectionsWidget getStyleId={getStyleId} productId={productId} colorId={colorId} price={price} sourcePage={SEARCH_PAGE} />
    </div>
  );
};

/* eslint-disable css-modules/no-undef-class */
export const getComponentStyling = (componentStyle, showFavoriteHeart) => {
  switch (componentStyle) {
    case 'recommender':
    case 'productSearchNoStyle':
      return cn(css.melodyCardInCarousel, {
        [css.heartListContainer]: showFavoriteHeart
      });
    case 'melodyCurated':
      return cn(css.melodyCurated, {
        [css.heartListContainer]: showFavoriteHeart
      });
    case 'melodyGrid':
      return cn(css.melodyGrid, {
        [css.heartListContainer]: showFavoriteHeart
      });
    case 'oos':
      return css.melodyCardOosModal;
    case 'melodySimple':
      return css.melodySimple;
    case 'fullBleed':
      return css.fullBleed;
    case 'searchProduct':
    case 'searchProductInlineRecos':
    case 'brandTrendingStyle':
      return cn({ [css.heartListContainer]: showFavoriteHeart });
    default:
      return null;
  }
};
/* eslint-disable css-modules/no-undef-class */

export class MelodyCardProduct extends Component {
  static displayName = 'MelodyCardProduct';

  state = {
    hasProductBeenHovered: false,
    isProductFocused: false,
    isProductHovered: false,
    isCollectionShown: false,
    parentImageLoaded: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { isProductFocused, hasProductBeenHovered, isProductHovered, isCollectionShown, parentImageLoaded } = this.state;
    const {
      additionalClasses,
      msaImageParams,
      heartsData,
      cardData: { animationImages, price, originalPrice },
      belowImageOptions,
      belowImageRenderer
    } = this.props;
    const { hearts, heartsList } = heartsData || {};
    const {
      heartsData: nextHeartsData,
      cardData: nextCardData,
      belowImageOptions: nextBelowImageOptions,
      belowImageRenderer: nextBelowImageRenderer
    } = nextProps;
    const { hearts: nextHearts, heartsList: nextHeartsList } = nextHeartsData || {};

    if (animationImages?.length > 0) {
      return true;
    }
    return (
      !deepEqual(nextProps.additionalClasses, additionalClasses) ||
      !deepEqual(nextProps.msaImageParams, msaImageParams) ||
      !deepEqual(nextHearts, hearts) ||
      !deepEqual(nextHeartsList, heartsList) ||
      nextState.isProductFocused !== isProductFocused ||
      nextState.isProductHovered !== isProductHovered ||
      nextState.hasProductBeenHovered !== hasProductBeenHovered ||
      nextState.isCollectionShown !== isCollectionShown ||
      nextState.parentImageLoaded !== parentImageLoaded ||
      nextCardData.price !== price ||
      nextCardData.originalPrice !== originalPrice ||
      nextBelowImageOptions !== belowImageOptions ||
      nextBelowImageRenderer !== belowImageRenderer
    );
  }

  testId;

  makeProductNameText = () => {
    const {
      cardData: { name, productName }
    } = this.props;
    return makeAscii(name || productName || '');
  };

  makeBrandNameText = () => {
    const {
      cardData: { brand, brandName }
    } = this.props;
    return makeAscii(brand || brandName || '');
  };

  makeBrandName = () => {
    const {
      testId,
      props: { additionalClasses }
    } = this;
    const brandName = this.makeBrandNameText();
    return (
      brandName && (
        <p
          className={cn(css.brandName, additionalClasses.brandName)}
          itemProp="brand"
          itemScope
          itemType="https://schema.org/Brand"
          data-test-id={testId('brand')}
        >
          {/* This span is unfortunately necessary for the brand schema  */}
          <span itemProp="name">{brandName}</span>
        </p>
      )
    );
  };

  makeProductName = () => {
    const { testId } = this;
    const productName = this.makeProductNameText();
    return (
      productName && (
        <p className={css.productName} itemProp="name" data-test-id={testId('productName')}>
          {productName}
        </p>
      )
    );
  };

  makeColorName = () => {
    const { testId } = this;
    const {
      cardData: { colorName }
    } = this.props;
    return (
      colorName && (
        <p className={css.colorName} itemProp="color" data-test-id={testId('colorName')}>
          {makeAscii(colorName)}
        </p>
      )
    );
  };

  makePrice = url => {
    const { testId } = this;
    const {
      cardData: { price, originalPrice },
      additionalClasses,
      alwaysShowOriginalPrice
    } = this.props;
    if (price) {
      const isSale = ProductUtils.isStyleOnSale({ price, originalPrice });
      const msrp = hideMsrp === true ? null : <span className={css.msrpLabel}>MSRP: </span>;
      // do not use itemProp="price" because it is volatile
      return (
        <p
          className={cn(css.priceContainer, additionalClasses.priceContainer, {
            [css.alwaysShowOriginalPrice]: alwaysShowOriginalPrice
          })}
          itemProp="offers"
          itemScope
          itemType="https://schema.org/Offer"
        >
          <meta itemProp="priceCurrency" content="USD" />
          <span className={cn(css.price, { [css.priceSale]: isSale })} itemProp="price" content={usdToNumber(price)} data-test-id={testId('price')}>
            {price}
          </span>
          {isSale && (
            <span className={css.originalPrice} data-test-id={testId('originalPrice')}>
              {msrp}
              {originalPrice}
            </span>
          )}
          <meta itemProp="url" content={url} />
        </p>
      );
    }
    return null;
  };

  makeProductRating = () => {
    const {
      cardData: { productRating = '0', reviewCount },
      showRatingStars
    } = this.props;
    const showStars = typeof showRatingStars === 'boolean' ? showRatingStars : true;
    if (productRating !== '0' && showStars) {
      return (
        <p>
          <Rating rating={productRating} additionalClasses={css.fromMCP} reviewCount={reviewCount || 0} />
        </p>
      );
    }
    return null;
  };

  makeEventValue = () => {
    const {
      eventLabel,
      cardData: { productId, styleId, gae }
    } = this.props;
    if (eventLabel) {
      if (eventLabel === 'melodyGrid') {
        return gae;
      } else {
        return `product-${productId}${styleId ? `-style-${styleId}` : ''}`;
      }
    }
    return false;
  };

  makeProductBanner = hasStyleRoomProductFlag => {
    const { testId } = this;
    const {
      cardData: { isCouture, isNew, isSponsored, styleId }
    } = this.props;

    if (isSponsored) {
      return <SponsoredBanner id={styleId} />;
    } else if (hasStyleRoomProductFlag && isCouture) {
      return (
        <p className={css.styleRoomBanner} data-test-id={testId('styleRoomBanner')}>
          Style Room
        </p>
      );
    } else if (`${isNew}` === 'true') {
      // isNew not always be bool from patron TODO: is this still true given all the datasources this component pulls from?
      return (
        <p className={css.newBanner} data-test-id={testId('newBanner')}>
          New
        </p>
      );
    }
    return null;
  };

  makeImageUrl = () => {
    const {
      msaImageParams,
      cardData: { imageId, thumbnailImageId, msaImageId, msaImageUrl, thumbnailImageUrl, src }
    } = this.props;
    const msaId = imageId || thumbnailImageId || msaImageId || null;
    if (forceHighRes) {
      const msaImg = msaId && msaImageParams ? constructMSAImageUrl(msaId, msaImageParams) : null;
      return msaImg || msaImageUrl || thumbnailImageUrl || src;
    }
    const msaImg = !msaImageUrl && msaId && msaImageParams ? constructMSAImageUrl(msaId, msaImageParams) : null;
    return msaImageUrl || msaImg || thumbnailImageUrl || src;
  };

  getSecondaryImageFromImageMap = imageMap => {
    const { PT01, PT03, TOPP, LEFT, RGHT } = imageMap || {};
    return RGHT || TOPP || LEFT || PT03 || PT01;
  };

  parentImageLoadedCallback = () => {
    this.setState({ parentImageLoaded: true });
  };

  makeProductImage = () => {
    const {
      additionalClasses,
      animationTimerIndex,
      cardData: { alt, productName, name, styleColor, animationImages }
    } = this.props;
    const image = this.makeImageUrl();
    const altString = alt === '' ? alt : alt || styleColor || productName || name || '';
    const imgProps = {
      src: image,
      alt: altString,
      className: additionalClasses.image
    };

    const IMAGE_PLACEHOLDER = (
      <div className={css.imgPlaceholder}>
        <SmallLoader />
      </div>
    );

    // If the product has an animation, we want to cycle through the photos, timings provided by Products.jsx for syncing
    if (animationImages?.length > 0) {
      let activeIndex;

      // If the image has less animation frames than the timer count, just show the last one
      if (animationTimerIndex > animationImages.length - 1) {
        activeIndex = animationImages.length - 1;
      } else {
        activeIndex = animationTimerIndex;
      }
      const { msaId, attrs } = animationImages[activeIndex];
      const imageSource = `https://m.media-amazon.com/images/I/${msaId}._${attrs}_SX255_AC_.jpg`;
      imgProps.src = imageSource;
      imgProps.className = css.autoZoom;
      return (
        <>
          <Image placeholder={IMAGE_PLACEHOLDER} onLoad={this.parentImageLoadedCallback} {...imgProps} />
        </>
      );
    }

    return (
      imgProps.src && (
        <>
          <Image placeholder={IMAGE_PLACEHOLDER} onLoad={this.parentImageLoadedCallback} {...imgProps} />
        </>
      )
    );
  };

  handleCollectionToggle = isShowing => {
    this.setState({ isCollectionShown: isShowing });
  };

  handleHeartClick = () => {
    const {
      heartsData = {},
      componentStyle,
      cardData: style,
      cardData: { productId }
    } = this.props;

    const heartClick = componentStyle === 'searchProduct' ? this.handleCollectionToggle : null;

    const { onHeartClick, hearts, showFavoriteHeart } = heartsData;
    const isHearted = isProductHearted(showFavoriteHeart, hearts, style.styleId);
    return makeHandleHeartButtonClick({
      isHearted,
      onHeartClick,
      onCollectionToggle: heartClick,
      productId,
      showFavoriteHeart,
      style
    });
  };

  makeFavoriteHeart = () => {
    const {
      heartsData,
      cardData: style,
      cardData: { productId },
      extraRecoStyle = null
    } = this.props;

    const { testId } = this;

    const { hearts, heartsList, showFavoriteHeart } = heartsData || {};

    const isHearted = isProductHearted(showFavoriteHeart, hearts, style.styleId);
    const heartListCount = (heartsList?.[style.styleId] || 0) > 0 ? heartsList?.[style.styleId] : 0; // the count from the redux store.
    const count = heartListCount || (isHearted ? 1 : 0); //count from the display

    const heartProps = {
      cssHeartContainer: css.heartContainer,
      cssHeartActive: css.heartActive,
      extraRecoStyle,
      handleHeartClick: this.handleHeartClick,
      productId,
      showFavoriteHeart,
      style,
      testId: testId('heartButton'),
      isHearted,
      count
    };
    return <Heart {...heartProps} />;
  };

  handleClick = e => {
    const { onComponentClick } = this.props;
    if (typeof onComponentClick === 'function') {
      onComponentClick(e, this.props);
    }
  };

  makeProductLabel = () => {
    const {
      cardData: { price, styleColor, productRating, reviewCount }
    } = this.props;
    const productName = this.makeProductNameText();
    const brandName = this.makeBrandNameText();

    let label = '';
    if (productName) {
      label = `${productName}. `;
    }
    if (brandName) {
      label += `By ${brandName}. `;
    }
    if (price) {
      label += `${price}. `;
    }
    if (styleColor) {
      label += `Style: ${styleColor}. `;
    }
    if (reviewCount && typeof productRating === 'number') {
      label += `Rated ${productRating} out of 5 stars. `;
    }
    return label;
  };

  createSponsoredRef = () => {
    const { isSponsored, styleId } = this.props;
    if (isSponsored) {
      return { 'aria-describedby': `sponsoredBanner-${styleId}` };
    }
    return null;
  };

  render() {
    const { isProductFocused, isProductHovered, isCollectionShown } = this.state;
    const {
      eventLabel,
      componentStyle,
      additionalClasses,
      vertical,
      noBackground,
      linkElOverride,
      melodyCardTestId,
      hideBanner,
      cardData: { productSeoUrl, productUrlRelative, link, styleId, productId, colorId, price },
      heartsData = {},
      bottomOfImageRenderer: BottomOfImage,
      belowImageRenderer: BelowImage,
      intersectionRef,
      isFullWidth
    } = this.props;
    // Only add event attributes if we need them
    const { showFavoriteHeart = false } = heartsData || {};
    const dataAttributes = {};
    if (eventLabel) {
      dataAttributes['data-eventlabel'] = eventLabel;
      dataAttributes['data-eventvalue'] = this.makeEventValue();
    }

    // Reassign Link element if necessary
    const LinkComponent = linkElOverride || Link;
    const url = productSeoUrl || productUrlRelative || link;

    return (
      <MartyContext.Consumer>
        {({ testId }) => {
          this.testId = testId;
          return (
            <article
              className={cn(
                commonCardCss.mCard,
                css.productCardShared,
                additionalClasses.container,
                getComponentStyling(componentStyle, showFavoriteHeart),
                {
                  [css.vertical]: vertical,
                  [css.productIsFocused]: isProductFocused,
                  [commonCardCss.fullWidth]: isFullWidth
                }
              )}
              itemScope
              itemType="https://schema.org/Product"
              data-test-id={testId(melodyCardTestId)}
              ref={intersectionRef}
            >
              <LinkComponent
                to={url || ''}
                onClick={this.handleClick}
                aria-label={this.makeProductLabel()}
                // For helping site merch collect styleIds https://github01.zappos.net/mweb/marty/pull/9699
                data-style-id={styleId}
                data-test-id={testId(`${melodyCardTestId}Link`)}
                itemProp="url"
                onMouseOver={() =>
                  this.setState({
                    hasProductBeenHovered: true,
                    isProductHovered: true
                  })
                }
                onMouseOut={() => this.setState({ isProductHovered: false })}
                onBlur={() => this.setState({ isProductFocused: false })}
                onFocus={() =>
                  this.setState({
                    isProductFocused: true,
                    hasProductBeenHovered: true
                  })
                }
                className={css.link}
                {...dataAttributes}
                {...this.createSponsoredRef()}
              >
                <meta itemProp="image" content={this.makeImageUrl()} />
                <meta itemProp="sku" content={productId} />
                <div
                  className={cn(
                    commonCardCss.image,
                    {
                      [commonCardCss.imageNoBackground]: noBackground
                    },
                    css.productImage
                  )}
                >
                  {!hideBanner && this.makeProductBanner(hasStyleRoomProductFlag)}
                  {this.makeProductImage()}
                </div>
              </LinkComponent>
              {BottomOfImage && (
                <div className={css.bottomOfImage}>
                  <BottomOfImage
                    onClick={this.handleClick}
                    isProductFocused={isProductFocused}
                    isProductHovered={isProductHovered}
                    cardData={this.props.cardData}
                  />
                </div>
              )}
              <div className={cn(css.productContent, additionalClasses.textContainer)}>
                <MakeSocialCollectionWidget
                  styleId={styleId}
                  isCollectionShown={isCollectionShown}
                  productId={productId}
                  colorId={colorId}
                  price={price}
                />
                {BelowImage ? (
                  <BelowImage
                    onClick={this.handleClick}
                    isProductFocused={isProductFocused}
                    cardData={this.props.cardData}
                    isProductHovered={isProductHovered}
                    options={this.props.belowImageOptions}
                  />
                ) : (
                  this.makeFavoriteHeart()
                )}
                {this.makeBrandName()}
                {this.makeProductName()}
                {this.makeColorName()}
                {this.makePrice(url)}
                {this.makeProductRating()}
              </div>
            </article>
          );
        }}
      </MartyContext.Consumer>
    );
  }
}

MelodyCardProduct.propTypes = {
  cardData: PropTypes.object.isRequired,
  eventLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onComponentClick: PropTypes.func,
  additionalClasses: PropTypes.shape({
    container: PropTypes.string,
    image: PropTypes.string,
    textContainer: PropTypes.string,
    priceContainer: PropTypes.string
  }),
  bottomOfImageRenderer: PropTypes.func
};

MelodyCardProduct.defaultProps = {
  additionalClasses: {},
  componentStyle: defaultComponentStyle,
  eventLabel: false,
  noBackground: defaultNoBackground
};

const mapStateToProps = state => ({
  content: state?.headerFooter?.content || null
});

export default connect(mapStateToProps)(withErrorBoundary('MelodyCardProduct', MelodyCardProduct));
