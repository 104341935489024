import type { ReactElement } from 'react';
import { isValidElement } from 'react';
import { Parser } from 'html-to-react';
import appendQuery from 'append-query';

import { A11Y_IMAGE_TRANSLATIONS, DESKTOP_PDP_VIDEO, PRODUCT_CARD_BREAKPOINT_MAX, PRODUCT_CARD_BREAKPOINT_MIN } from 'constants/appConstants';
import marketplace from 'cfg/marketplace.json';
import { capitalize, constructMSAImageUrl, constructMSASrcset, indefiniteArticleSelector, makeAscii } from 'helpers/index';
import { getAbsoluteMarketplaceUrl, MERCHANTID_MARKET_NAME_MAPPING } from 'helpers/MarketplaceUtils';
import type {
  DimensionId,
  ProductBundle,
  ProductImage,
  ProductSizing,
  ProductStock,
  ProductStockData,
  ProductStyle,
  ProductVideo,
  SizingValue,
  ValueIDToName
} from 'types/cloudCatalog';
import type { FeaturedImage, PDP_GALLERY_CONFIG_TYPE, PDPFeaturedVideo, ProductBundleDescription, SelectedSizing } from 'types/product';
import { evProductDimensionSelected, evShareIconTooltip, evSocialIcon } from 'events/product';
import type { FormattedProductBundle, FormattedProductSizing, ProductDetailState } from 'reducers/detail/productDetail';
import { track } from 'apis/amethyst';
import { trackEvent } from 'helpers/analytics';
import Vegan from 'components/icons/Vegan';
import EcoFriendly from 'components/icons/EcoFriendly';
import Recycled from 'components/icons/Recycled';
import Organic from 'components/icons/Organic';
import GiveBack from 'components/icons/GiveBack';
import VipOnlyLogo from 'components/icons/vipDashboard/VipOnlyLogo';
import { BADGE_MULTIPLIER_VERBIAGE } from 'constants/rewardsInfo';
import type { FormattedJanusReco } from 'types/mafia';
import { stockSelectionCompleted } from 'store/ducks/productDetail/actions';
import { CUSTOMER_DROPDOWN_DIMENSION_SELECTION } from 'constants/productDimensionSelectionSourceTypes';
import type { LowestStylePrice } from 'types/opal';
import type { Product } from 'constants/searchTypes';
import type ImageMap from 'types/imageMap';
import type { InStockMap, SelectedDimensions, SelectMenuOption } from 'types/outfitRecos';
import type { AirplaneCache } from 'types/AirplaneCache';
import { makeSizeLegendHeadingFromState } from 'components/productdetail/stylepicker/AirplaneSeatSizing';
import type { ProductWithRelations } from 'types/calypso';

const VEGAN_ATTR = 'Vegan';
const RECYCLED_ATTR = 'Recycled Material';
const ORGANIC_ATTR = 'Organic';
const ECO_FRIENDLY_ATTR = 'Sustainably Certified';
const GIVE_BACK_ATTR = 'Give Back Products';
export const BONUS_POINTS_ATTR = 'VIP Point Multiplier';

const htmlToReactParser = new Parser();

interface AttributeDetails {
  Icon: React.ComponentType;
  label: string;
  tooltipText: string;
  amethystEnum: string;
}

export const productCalloutIconMap = new Map<string, AttributeDetails>([
  [
    VEGAN_ATTR,
    {
      Icon: Vegan,
      label: VEGAN_ATTR,
      tooltipText: '100% cruelty-free products constructed with zero animal parts.',
      amethystEnum: 'VEGAN'
    }
  ],
  [
    RECYCLED_ATTR,
    {
      Icon: Recycled,
      label: RECYCLED_ATTR,
      tooltipText: 'Composed of all or parts of materials from recycled goods, such as water bottles.',
      amethystEnum: 'RECYCLED'
    }
  ],
  [
    ECO_FRIENDLY_ATTR,
    {
      Icon: EcoFriendly,
      label: 'Sustainably Certified',
      tooltipText:
        'Certified by an industry-certifying organization to comply with at least one industry standard for environmental or socioeconomic impact.',
      amethystEnum: 'ECO_FRIENDLY'
    }
  ],
  [
    ORGANIC_ATTR,
    {
      Icon: Organic,
      label: ORGANIC_ATTR,
      tooltipText: 'Composed of organic cotton and other materials with a lower environmental impact.',
      amethystEnum: 'ORGANIC'
    }
  ],
  [
    GIVE_BACK_ATTR,
    {
      Icon: GiveBack,
      label: 'Give Back',
      tooltipText: 'Charitable programs where brands make donations in kind or assist with humanitarian projects or organizations.',
      amethystEnum: 'GIVE_BACK'
    }
  ],
  [
    BONUS_POINTS_ATTR,
    {
      Icon: VipOnlyLogo,
      label: 'Bonus Points',
      tooltipText: BADGE_MULTIPLIER_VERBIAGE,
      amethystEnum: 'BONUS_POINTS'
    }
  ]
]);

const hasRewards = (hasRewardsTransparency: boolean, rewardsBrandPromos: String[], brandId: any) =>
  hasRewardsTransparency && !!rewardsBrandPromos[brandId];

/**
 * A collection of pure helper functions for working with styles and stocks
 */
const {
  pdp: { percentOffThreshold }
} = marketplace;
const NON_NUMERIC_MATCHER = /[^\d^.]+/g;

/**
 * Since the API doesn't return the styles keyed by styleId, this helper function
 * iterates through the array of styles and creates a dictionary
 */
function getStyleMap(styles: ProductStyle[]) {
  const styleMap = {} as Record<string, ProductStyle>;
  styles.forEach(style => {
    styleMap[style.styleId] = style;
  });
  return styleMap;
}
/**
 * Returns the corresponding style for the provided color.  If color is not provided or is no longer in stock, returns the first available style
 */
function getStyleByColor(styles: ProductStyle[], colorId?: string) {
  if (!colorId) {
    return styles[0]!;
  }

  for (const style of styles) {
    if (style.colorId === colorId) {
      return style;
    }
  }
  // if that colorId doesn't exist anymore, just return the first style
  return styles[0]!;
}

/**
 * Returns all the ProductStyle objects from the styles array that are present in relatedStyleIds,
 * maintaining the same order as they appear in relatedStyleIds
 */
function filterStylesByRelatedStyleIdsInOrder(styles: ProductStyle[], relatedStyleIds: number[]) {
  const relatedStylesMap = new Map<number, ProductStyle>();

  styles.forEach(style => {
    relatedStylesMap.set(Number(style.styleId), style);
  });

  const resultStyles: ProductStyle[] = relatedStyleIds.map(styleId => relatedStylesMap.get(styleId)!);

  return resultStyles.filter(style => style !== undefined);
}

function hasMatchingDimensions(stock: ProductStockData, selectedSizing: SelectedSizing) {
  return Object.keys(selectedSizing).every(dimId => stock[dimId] === selectedSizing[dimId]);
}

/**
 * Gets  the stock object for a given  color, and sizing combination.
 *
 * Returns null if there is no stock for that combination of colors and sizing,
 * or if it is out of stock and includeOos is not set.
 *
 * @param stockData {Array} list of stock information for the product
 * @param colorId {String} color to match against
 * @param selectedSizing {Object} map of dimensionId -> dimensionValues represented the selected sizes.
 * @param includeOos {boolean=false} If true, will return stocks that have 0 onHand.
 */
function getStockBySize(stockData: ProductStockData[], colorId: string, selectedSizing: SelectedSizing, includeOos = false) {
  return (
    stockData.find(stock => stock.color === colorId && hasMatchingDimensions(stock, selectedSizing) && (includeOos || stock.onHand !== '0')) || null
  );
}

/**
 * Gets  the stock object for a given  color, and sizing combination.
 *
 * Returns null if there is no stock for that combination of colors and sizing, or if it is out of stock and includeOos is not set.
 *
 * @param stockData {Array} List of stocks of currently selected selected
 * @param selectedSizing {Object} map of dimensionId -> dimensionValues represented the selected sizes.
 * @param includeOos { boolean=false } If true, will return stocks that have 0 onHand.
 */
function getSelectedStyleStockBySize(
  stockData: ProductStockData[] = [],
  sizing: ProductSizing,
  selectedSizing: SelectedSizing = {},
  productData: ProductStock[] = []
) {
  const sizeName = getCurrentSelectedSizeName(sizing, selectedSizing);
  if (sizeName && Object.keys(selectedSizing).length) {
    if (productData.length) {
      return productData.find(({ size }) => sizeName === size);
    } else if (stockData.length) {
      return stockData.find(({ size }) => sizeName === size);
    }
  }
  return null;
}

/**
 * Returns available colors for given sizing.
 *
 * @param stockData {Array} list of stock information for the product
 * @param selectedSizing {Object} map of dimensionId -> dimensionValues represented the selected sizes.
 */
function getColorsBySize(stockData: ProductStockData[], selectedSizing: SelectedSizing) {
  return stockData.filter(stock => hasMatchingDimensions(stock, selectedSizing)) || [];
}

function getPriceForProduct(styles: ProductStyle[]) {
  let price;
  for (const style of styles) {
    if (!price) {
      ({ price } = style);
    } else if (price !== style.price) {
      return undefined;
    }
  }
  return price;
}

export function priceToFloat(price: string | number) {
  if (typeof price === 'string') {
    return parseFloat(price.replace(NON_NUMERIC_MATCHER, ''));
  }
  return price;
}

// can't actually trust onSale flag to mean price is lower than originalPrice
function isStyleOnSale(style: { originalPrice?: number | string; price: number | string }) {
  if (style?.originalPrice) {
    const originalPrice = priceToFloat(style.originalPrice);
    const currentPrice = priceToFloat(style.price);
    const percentOff = (originalPrice - currentPrice) / originalPrice;
    return originalPrice > currentPrice && percentOff > percentOffThreshold;
  }
  return false;
}

/**
 * Returns the payload for a legacy StockEventHC
 */
function getStockEventPayload(
  productId: string,
  styleId: string,
  colorId: string,
  sizing: ProductSizing,
  selectedSizing: SelectedSizing,
  stock: ProductStockData,
  inStock: boolean
) {
  let payload = `${inStock ? 'IS' : 'OOS'}|p=${productId}&c=${colorId}&y=${styleId}&k=${stock ? stock.id : ''}`;
  for (const dimId of sizing.dimensionsSet) {
    payload += `&r=${selectedSizing[dimId]}`;
  }
  return payload;
}

/**
 * Returns the user friendly name for the first invalid dimension the user has selected.
 * @param selectedSizing {Object}  map of dimensionId to dimensionValue user has selected.
 * @param sizing {Object} sizing 2.0 data for product
 */
function getMissingDimensionName(selectedSizing: SelectedSizing, { dimensionsSet, dimensionIdToName }: ProductSizing) {
  const missingDimId = dimensionsSet.find(dimId => !selectedSizing[dimId]);
  if (missingDimId) {
    return dimensionIdToName[missingDimId];
  }
  return;
}

function isSizeSelectionComplete(sizeData: ProductSizing, selectedSizing: SelectedSizing) {
  if (!sizeData?.dimensions || !selectedSizing) {
    return false;
  }
  const selectedDimensions = Object.keys(selectedSizing);
  return (
    sizeData.dimensions.length === selectedDimensions.length && selectedDimensions.every(dimension => sizeData.dimensionsSet.includes(dimension))
  );
}
/**
 * Returns the percentage off of the original price the item is on sale for rounded to the nearest integer.
 * @param  {Object} style object with price and originalPrice
 * @return {Integer}       number representing the percentage off
 */
function getPercentOff({ originalPrice, price }: Partial<{ originalPrice: string | number; price: string | number }>) {
  return originalPrice && price ? Math.round((1 - priceToFloat(price) / priceToFloat(originalPrice)) * 100) : 0;
}

/**
 * Return sharing link for a given productId/colorId
 * @param productId
 * @param colorId (optional)
 * @returns {string}
 */
function getSharingButtonLink(productId: string, colorId?: string | number) {
  const {
    links: {
      sharing: { baseUrl }
    }
  } = marketplace;
  return `${baseUrl}/product/${productId}${colorId ? `/color/${colorId}` : ''}`;
}

function generateShareLinkEvent(linkName: string, asin: string | null, sourcePage: string) {
  trackEvent('TE_PDP_SOCIAL_LINK_CLICK', `link:${linkName}:asin:${asin}:sourcePage:${sourcePage}`);
  track(() => [evSocialIcon, { linkName }]);
}

function generateShareIconClickEvent(productId: string, styleId: string) {
  track(() => [evShareIconTooltip, { productId, styleId }]);
}

interface ProductDimensionSelectedEventData {
  dimensionDirty: string | undefined;
  dimensionId: string;
  dimensionLabel: string;
  sourcePage: string;
  stock?: ProductStockData;
  asin?: string;
}

function generateProductDimensionSelectedAmethystEvent(
  dimensionDirty: string | undefined,
  dimensionLabel: string,
  sourcePage: string,
  options: SizingValue[],
  selectedSizing: SelectedSizing,
  dimension: string,
  value: string,
  sizing: FormattedProductSizing,
  colorId: string,
  selectedStyle: ProductStyle
) {
  const eventData: ProductDimensionSelectedEventData = {
    dimensionDirty,
    dimensionId: value,
    dimensionLabel,
    sourcePage
  };

  const selectedOption = options!.find((option: any) => option.id === value);
  const completedSizing = Object.assign({}, selectedSizing, {
    [dimension]: value
  });

  if (ProductUtils.isSizeSelectionComplete(sizing, completedSizing)) {
    const stock = ProductUtils.getStockBySize(sizing.stockData, colorId, completedSizing, true);

    // If stock is null, trigger secondary cloud cat call to get ASIN and emit event.
    if (!stock) {
      stockSelectionCompleted({
        value,
        selectedOption,
        source: CUSTOMER_DROPDOWN_DIMENSION_SELECTION
      });
      return;
    }

    eventData.stock = stock!;
    const stockStyle = selectedStyle?.stocks.find(({ stockId }: { stockId: string }) => stockId === stock?.id);

    // If stockStyle is undefined or there is not an ASIN in the returned object,
    // trigger secondary cloud cat call to get ASIN and emit event.
    if (!stockStyle || !stockStyle.asin) {
      stockSelectionCompleted({
        value,
        selectedOption,
        source: CUSTOMER_DROPDOWN_DIMENSION_SELECTION
      });
      return;
    }

    eventData.asin = stockStyle.asin;
  }
  track(() => [evProductDimensionSelected, eventData]);
}

function isShoeType(productType: string | undefined) {
  return productType && productType.toLowerCase() === 'shoes';
}

function isGiftCard(productType: string | undefined): boolean {
  return productType ? productType.toLowerCase() === 'gift cards' : false;
}

const RECO_NAME_TO_SOURCE: Record<string, string> = {
  pd_detail_2: 'p13nvisual'
};

function translateRecoNameToAnalyticsSource(recoName: string) {
  if (recoName in RECO_NAME_TO_SOURCE) {
    return RECO_NAME_TO_SOURCE[recoName];
  }
  return 'p13n';
}

/**
 * Returns the Product URL representing the given asin.
 * @param {Object} Product object
 * @param {String} asin the asin to look for
 * @return {String} the product URL including productId and colorId for the given asin.  If matching asin is not found returns the product's default url
 */
function getProductUrlFromAsin({ defaultProductUrl, styles }: ProductBundle, asin: string) {
  asin = asin && asin.toUpperCase();
  if (styles) {
    const style = styles.find(({ stocks }) => stocks.find(stock => stock.asin === asin));
    return (style && style.productUrl) || defaultProductUrl;
  } else {
    return;
  }
}

/**
 * Returns the Amethyst reco widget type based of title
 * @param {String} title the title of the reco
 */
function translateRecoTitleToAmethystWidget(title: string = '') {
  const reco = title.toLowerCase();
  const mapping: Record<string, string> = {
    'customers who viewed this item also viewed': 'CUSTOMERS_WHO_VIEWED_WIDGET',
    'customers who bought this item also bought': 'CUSTOMERS_WHO_BOUGHT_WIDGET',
    'similar styles you might like': 'SIMILAR_PRODUCT_WIDGET',
    'you may also like': 'YOU_MAY_ALSO_LIKE_WIDGET',
    'wear it with': 'COMPLETE_THE_LOOK',
    'similar items you may like!': 'SIMILAR_PRODUCT_WIDGET',
    'recommended for you': 'YOU_MAY_ALSO_LIKE_WIDGET'
  };
  return mapping[reco] || 'UNKNOWN_RECOMMENDATION_WIDGET';
}

/*
  Returns whether the Product Page is loaded and ready to render.  Takes into account that detail data, image data, sizing data, the isLoading flag, and whether the requested productId matches the currently loaded product.
 */
function isProductDataLoaded(
  {
    selectedSizing,
    detail,
    isLoading
  }: {
    selectedSizing?: SelectedSizing;
    detail?: FormattedProductBundle;
    isLoading?: boolean;
  },
  { productId }: { productId: string }
) {
  return (
    detail &&
    detail.productId === productId &&
    detail.styles &&
    detail.styles.length && // if product is oos and loaded [from reviews], force the fetch to occur so natural oos cycle occurs.
    selectedSizing &&
    !isLoading
  );
}

/**
 * Get the number of reviews for the given product
 *
 * @param product        state.product
 * @param valueIfNoData  the value to return if the data is loading or missing
 *                       (defaults to 0)
 */
function getNumberOfReviews(product: ProductDetailState, valueIfNoData = 0) {
  if (!product) {
    return valueIfNoData;
  }
  const { reviewData } = product;
  if (!reviewData) {
    return valueIfNoData;
  }
  const { isLoading, reviews } = reviewData;
  if (isLoading || !reviews) {
    return valueIfNoData;
  }
  return reviews.length;
}

/**
 * For a given style return a list of complete MSA image URLs for each angle of the style in thumbnail size.
 *
 * TODO figure out why these are arrays of objects and not arrays of strings
 *
 * @return {Array} array of objects with filename property for the image src
 */
function buildAngleThumbnailImages(style: ProductStyle, width: number, height: number) {
  if (!style || !style.images) {
    return [];
  }

  return style.images.map(({ imageId, type }) => ({
    filename: constructMSAImageUrl(imageId, { width, height, autoCrop: true }),
    retinaSrc: constructMSAImageUrl(imageId, generateRetinaImageParams({ width, height, autoCrop: true }, 2)),
    imageId,
    type
  }));
}

function buildSizeMessagingText(dimensionName: string) {
  return `Please select ${indefiniteArticleSelector(dimensionName)} ${dimensionName}`;
}

function getCurrentSelectedSizeName(sizeData: ProductSizing, selectedSizing: SelectedSizing) {
  const { dimensionIdToName = {} } = sizeData;
  const sizeKey = Object.keys(dimensionIdToName).find(key => dimensionIdToName[key] === 'size');
  const selectedSizeId = sizeKey && selectedSizing?.[sizeKey];
  if (selectedSizeId) {
    return sizeData.allValues.find(v => v.id === selectedSizeId)?.value;
  }
  return null;
}

function getGender(product: { gender?: string; genders?: string[] } | undefined) {
  const { gender = '', genders = [] } = product || {};
  if (gender) {
    return gender.toLowerCase();
  }
  const lowercaseGenders = genders.map(s => s.toLowerCase());
  if (lowercaseGenders.length === 1) {
    return lowercaseGenders[0]!;
  } else if (lowercaseGenders.length === 2 && lowercaseGenders.includes('boys') && lowercaseGenders.includes('girls')) {
    return 'kids';
  }
  return 'general';
}

export enum OnHandCountBySelectionCode {
  IN_STOCK,
  INCOMPLETE_SELECTION,
  OUT_OF_STOCK
}

export interface OnHandCountBySelection {
  code: OnHandCountBySelectionCode;
  onHand: number;
}

export function getOnHandCountBySelection(
  sizing: FormattedProductSizing,
  colorId: string,
  selectedSizing: Partial<Record<string, string>>
): OnHandCountBySelection {
  const stockMatchesSelection = (stock: ProductStockData, colorId: string, selectedSizing: Partial<Record<string, string>>) => {
    if (stock.color !== colorId) {
      return false;
    }
    for (const dimensionKey in selectedSizing) {
      if (stock[dimensionKey] !== selectedSizing[dimensionKey]) {
        return false;
      }
    }
    return true;
  };

  const { dimensionsSet, stockData } = sizing;
  for (const dimensionKey of dimensionsSet) {
    if (!(dimensionKey in selectedSizing)) {
      return {
        code: OnHandCountBySelectionCode.INCOMPLETE_SELECTION,
        onHand: 0
      };
    }
  }

  const stock = stockData.find(stock => stockMatchesSelection(stock, colorId, selectedSizing));
  if (!stock) {
    return { code: OnHandCountBySelectionCode.OUT_OF_STOCK, onHand: 0 };
  }

  const onHand = +(stock?.onHand || 0);
  const code = onHand > 0 ? OnHandCountBySelectionCode.IN_STOCK : OnHandCountBySelectionCode.OUT_OF_STOCK;
  return { code, onHand };
}

function hasAvailableStock(style: ProductStyle) {
  return style.stocks.some(({ onHand }) => onHand !== '0');
}

export function isSingleShoe(styles: ProductStyle[]) {
  return styles.some(({ taxonomyAttributes = [] }) => taxonomyAttributes.some(attr => attr.name === 'Single Shoes'));
}

const MAIN_IMAGE_PARAMS = { width: 700, height: 525, autoCrop: true };
type ImageParams = typeof MAIN_IMAGE_PARAMS;
export const generateRetinaImageParams = (baseParams: ImageParams, multiplier: number): ImageParams => ({
  width: baseParams.width * multiplier,
  height: baseParams.height * multiplier,
  autoCrop: baseParams.autoCrop
});

/**
 * Basic settings for the PDP Assets Gallery
 * @return {PDP_GALLERY_CONFIG_TYPE}
 */
export const PDP_GALLERY_CONFIG: PDP_GALLERY_CONFIG_TYPE = {
  image: {
    default: {
      // 4:5 RATIO
      width: 736,
      height: 920
    },
    get inverted() {
      // 5:4 RATIO
      const { width: height, height: width } = this.default;
      return { width, height };
    }
  },
  video: {
    // 16:9 RATIO
    width: 750,
    height: 420
  },
  invertAspectRatio: ['SHOES'], // Product categories that use the inverted image size 5:4
  carouselThreshold: 1024 // > 1024 = Thumbnails carousel and no featured carousel <= 1024 = Featured carousel and no thumbnails carousel
};

/**
 * Formats the raw image assets so they can be rendered in the galler
 * @returns {FeaturedImage[]} Array of images formatted
 */
export const getProductImagesFormatted = (images: ProductImage[], productType?: string): FeaturedImage[] =>
  images?.map(({ imageId, type }, index) => {
    const aspectRatio = !PDP_GALLERY_CONFIG.invertAspectRatio.includes(`${productType}`.toUpperCase())
      ? PDP_GALLERY_CONFIG.image.default
      : PDP_GALLERY_CONFIG.image.inverted;
    return {
      index,
      imageId,
      thumbnail: {
        src: constructMSAImageUrl(imageId, {
          width: Math.floor(aspectRatio.width * 0.08),
          height: Math.floor(aspectRatio.height * 0.08),
          autoCrop: true
        }),
        retinaSrc: `${constructMSAImageUrl(
          imageId,
          generateRetinaImageParams(
            {
              width: Math.floor(aspectRatio.width * 0.08),
              height: Math.floor(aspectRatio.height * 0.08),
              autoCrop: true
            },
            2
          )
        )} 2x`,
        webp: {
          src: constructMSAImageUrl(imageId, {
            width: Math.floor(aspectRatio.width * 0.08),
            height: Math.floor(aspectRatio.height * 0.08),
            autoCrop: true,
            useWebp: true
          }),
          retinaSrc: `${constructMSAImageUrl(imageId, {
            ...generateRetinaImageParams(
              {
                width: Math.floor(aspectRatio.width * 0.08),
                height: Math.floor(aspectRatio.height * 0.08),
                autoCrop: true
              },
              2
            ),
            useWebp: true
          })} 2x`
        }
      },
      featured: {
        src: constructMSAImageUrl(imageId, {
          width: aspectRatio.width,
          height: aspectRatio.height,
          autoCrop: true
        }),
        aspectRatio: aspectRatio.width / aspectRatio.height,
        retinaSrc: `${constructMSAImageUrl(
          imageId,
          generateRetinaImageParams(
            {
              width: aspectRatio.width,
              height: aspectRatio.height,
              autoCrop: true
            },
            2
          )
        )} 2x`,
        webp: {
          src: constructMSAImageUrl(imageId, {
            width: aspectRatio.width,
            height: aspectRatio.height,
            autoCrop: true,
            useWebp: true
          }),
          retinaSrc: `${constructMSAImageUrl(imageId, {
            ...generateRetinaImageParams(
              {
                width: aspectRatio.width,
                height: aspectRatio.height,
                autoCrop: true
              },
              2
            ),
            useWebp: true
          })} 2x`,
          aspectRatio: aspectRatio.width / aspectRatio.height,
          srcset: constructMSASrcset(imageId, {
            ...generateRetinaImageParams(
              {
                width: aspectRatio.width,
                height: aspectRatio.height,
                autoCrop: true
              },
              2
            ),
            useWebp: true
          })
        }
      },
      zoom: {
        src: constructMSAImageUrl(imageId, {
          width: Math.floor(aspectRatio.width * 1.5),
          height: Math.floor(aspectRatio.height * 1.5),
          autoCrop: true
        }),
        retinaSrc: `${constructMSAImageUrl(
          imageId,
          generateRetinaImageParams(
            {
              width: Math.floor(aspectRatio.width * 1.5),
              height: Math.floor(aspectRatio.height * 1.5),
              autoCrop: true
            },
            2
          )
        )} 2x`,
        webp: {
          src: constructMSAImageUrl(imageId, {
            width: Math.floor(aspectRatio.width * 1.5),
            height: Math.floor(aspectRatio.height * 1.5),
            autoCrop: true,
            useWebp: true
          }),
          retinaSrc: `${constructMSAImageUrl(imageId, {
            ...generateRetinaImageParams(
              {
                width: Math.floor(aspectRatio.width * 1.5),
                height: Math.floor(aspectRatio.height * 1.5),
                autoCrop: true
              },
              2
            ),
            useWebp: true
          })} 2x`
        }
      },
      type: 'image',
      angleType: type,
      alt: A11Y_IMAGE_TRANSLATIONS[type as keyof typeof A11Y_IMAGE_TRANSLATIONS] || `#${index + 1} of ${images?.length}`
    };
  });

/**
 * Formats the raw video asset adding useful props
 * @returns {PDPFeaturedVideo} Video formatted for MelodyVideoPlayer
 */
export const getProductVideoFormatted = (
  productVideos: ProductVideo[] = [],
  productId: string,
  index: number,
  isYouTubeVideo: boolean,
  youtubeSrc: string | undefined
): PDPFeaturedVideo | undefined => {
  const productVideo = productVideos?.length ? productVideos.find(video => video?.videoEncodingExtension === 'mp4') : undefined;
  if (productVideo) {
    return {
      index,
      isYouTubeVideo,
      type: 'video',
      angleType: 'VIDEO',
      alt: 'View product video',
      widthValue: `${PDP_GALLERY_CONFIG.video.width}px`,
      heightValue: `${PDP_GALLERY_CONFIG.video.height}px`,
      slotDetails: {
        productId,
        autoplay: false,
        componentName: DESKTOP_PDP_VIDEO,
        src: isYouTubeVideo ? youtubeSrc : productVideo?.filename ? `${marketplace.desktopBaseUrl}${productVideo.filename}` : undefined
      }
    };
  }
  return undefined;
};

/**
 * Renders the default tracking attributes for an element
 * @return {Object} data tracking attributes for elements
 */
export const getPDPTrackingPropsFormatted = (label: string, value: string): Object => ({
  'data-track-action': 'Product-Page',
  'data-track-label': label,
  'data-track-value': value
});

// Prefer to get from the data, but Janus is being deprecated and not accepting change requests.
// We can assume this will always be here, becuase if its not, the product image wont be showing which is a bigger problem.
export const extractColorIdFromJanusProductLink = ({ link }: Pick<FormattedJanusReco, 'link'>) => {
  const colorId = link.split('/color/')[1]?.split('?')[0];
  return colorId;
};

type HasHighlightsAccordionSection = {
  styles: ProductStyle[];
  colorId?: string;
  brandId: string;
  rewardsBrandPromos: string[];
  showProductCallout: boolean;
  hasRewardsTransparency: boolean;
};

function hasHighlightsAccordionSection({
  styles,
  colorId,
  brandId,
  rewardsBrandPromos,
  showProductCallout,
  hasRewardsTransparency
}: HasHighlightsAccordionSection) {
  if (showProductCallout) {
    const style = ProductUtils.getStyleByColor(styles, colorId);

    const hasRewards = ProductUtils.hasRewards(hasRewardsTransparency, rewardsBrandPromos, brandId);
    const hasAttributes = style.taxonomyAttributes.filter(attribute => productCalloutIconMap.get(attribute.value)).length > 0;
    if (hasRewards || hasAttributes) {
      return true;
    }
  }
  return false;
}

type HasSizeChartAccordionSection = {
  defaultProductType: string;
  description?: ProductBundleDescription;
  showDescriptionSizeChart: boolean;
};

function hasSizeChartAccordionSection({ defaultProductType, description, showDescriptionSizeChart }: HasSizeChartAccordionSection) {
  if (showDescriptionSizeChart) {
    const hasSizeCharts = getHasSizeCharts(description);

    // Match condition in the following <SizeCharts> because we can’t check if it returns `null` before rendering it
    if ((ProductUtils.isShoeType(defaultProductType) && !hasSizeCharts) || hasSizeCharts) {
      return true;
    }
  }

  return false;
}

const getReviewCount = (productDetail?: FormattedProductBundle) => {
  if (!productDetail) return 0;

  const { reviewCount } = productDetail;
  const countInt = parseInt(reviewCount, 10);

  if (isNaN(countInt)) {
    return 0;
  }

  return countInt;
};

const getProductViewData = (style?: ProductStyle, productDetail?: FormattedProductBundle) => {
  const { videos = [], description } = productDetail || {};

  const hasReviews = getReviewCount(productDetail) > 0;
  const hasVideo = videos.length > 0;
  const hasBrandSizeChart = getHasSizeCharts(description);

  return {
    ...style,
    hasReviews,
    hasVideo,
    hasBrandSizeChart
  };
};

export const getSizeChartImage = (index: number, description?: ProductBundleDescription): FeaturedImage | undefined => {
  const { sizeCharts = [] } = description || {};

  if (!description || sizeCharts.length !== 1) return undefined;

  return parseSizeChartImage(index, sizeCharts[0]);
};

export const parseSizeChartImage = (index: number, sizeChart?: string): FeaturedImage | undefined => {
  if (!sizeChart) {
    return undefined;
  }

  const sizeChartLink = parseSizeChartLink(sizeChart);

  if (isValidElement<HTMLAnchorElement>(sizeChartLink)) {
    const { props: { href = '', children } = {} } = sizeChartLink;

    if (href.endsWith('.pdf')) return undefined;

    const imageSrc = getAbsoluteMarketplaceUrl(marketplace.domain, '') + href;

    return {
      alt: `${children}`,
      angleType: 'CHART',
      index,
      imageId: `${children}`,
      thumbnail: { src: imageSrc, retinaSrc: imageSrc },
      featured: { src: imageSrc, retinaSrc: imageSrc },
      zoom: { src: imageSrc, retinaSrc: imageSrc },
      type: 'image'
    };
  }

  return undefined;
};

const getHasSizeCharts = (description?: ProductBundleDescription) => {
  const { sizeCharts = [] } = description || {};

  return sizeCharts.length > 0;
};

export const parseSizeChartLink = (link?: string): ReactElement | string | undefined => {
  if (!link) return undefined;

  return htmlToReactParser.parse(link.trim());
};

export const getNumProductCardsForWindow = (width: number) =>
  width <= PRODUCT_CARD_BREAKPOINT_MIN ? 2 : width <= PRODUCT_CARD_BREAKPOINT_MAX ? 3 : 4;

export const getCardIndex = (width: number, row: number, column: number) => {
  const numCards = getNumProductCardsForWindow(width);
  return numCards * (row - 1) + Math.min(column, numCards) - 1;
};

export const getLowestStylePrice = (styleId: number, lowestPrices?: LowestStylePrice[]) =>
  lowestPrices?.find(lowestStylePrice => lowestStylePrice.styleId === styleId)?.price || 0;

export const getIsLowestRecentPrice = (styleId: string, salePrice: string, lowestPrices?: LowestStylePrice[]) => {
  const lowestStylePrice = getLowestStylePrice(Number(styleId), lowestPrices);

  return priceToFloat(salePrice) <= lowestStylePrice;
};

/* swatch group rules as defined in DK-566
  - the last (5th) swatch is the grouped +n swatch.
  - do not display "color" or "undefined" swatches in any case.
  - these should always be part of the +n grouped swatch.
*/
export const groupVisibleSwatches = (styles: Product[] = [], visibleSwatches: number) => {
  const visibleFabricSwatches: Product[] = [];
  const groupedSwatches: Product[] = [];
  const notFabricSwatches: Product[] = [];

  styles.forEach(style => {
    //only display a max number of fabric swatches, group the rest.
    if (style.swatchUrl && visibleFabricSwatches.length < visibleSwatches) {
      visibleFabricSwatches.push(style);
    } else if (getImageIdFromImageMap(style.imageMap, imageMapTypes.SWCH) && visibleFabricSwatches.length < visibleSwatches) {
      visibleFabricSwatches.push(style);
    } else if (style.isFabricSwatch === false) {
      notFabricSwatches.push(style);
    } else {
      groupedSwatches.push(style);
    }
  });

  if (visibleFabricSwatches.length >= 1) {
    const updatedGrouppedSwatches = groupedSwatches.concat(notFabricSwatches);
    return { visibleFabricSwatches, groupedSwatches: updatedGrouppedSwatches, notFabricSwatches: [] };
  }

  return { visibleFabricSwatches, groupedSwatches, notFabricSwatches };
};

export const getGroupedSwatchLength = (groupedSwatches: Product[] = [], notFabricSwatches: Product[] = []): number | undefined => {
  const groupSwatchLength = notFabricSwatches ? groupedSwatches.length - notFabricSwatches.length : groupedSwatches.length;

  if (groupSwatchLength > 0) {
    return groupSwatchLength;
  }

  return;
};

export const replaceJpgToWebpSwatchUrl = (jpgUrl: string) => {
  //ex: https://swch-cl2.olympus.zappos.com/base/27567/27580/7166039/6115716.jpg
  //to ex: https://swch-cl2.olympus.zappos.com/webp/base/27567/27580/7166039/6115716.webp

  //ex: https://swch-cl2.olympus.zappos.com/fabric/27567/27580/7166039/6115716.jpg
  //to ex: https://swch-cl2.olympus.zappos.com/webp/fabric/27567/27580/7213526/4124817.webp
  //although base or fabric exist independently, this is the easiest way to capture the rest of the path and keep it responsive to changes on the fly.

  const webpUrl = jpgUrl
    .replace(/\/base\//, '/webp/base/')
    .replace(/\/fabric\//, '/webp/fabric/')
    .replace(/.jpg$/, '.webp');

  try {
    new URL(webpUrl);
    return webpUrl;
  } catch (e) {
    return undefined;
  }
};

export const hasOnSaleStyle = (productDetail?: FormattedProductBundle) => (productDetail?.styles || []).some(style => isStyleOnSale(style));

export const isRunningShoe = (subcategory?: string) => subcategory === 'Running Shoes';

export const isComparableProduct = ({ defaultSubCategory }: Pick<ProductBundle, 'defaultSubCategory'>) =>
  defaultSubCategory === null || isRunningShoe(defaultSubCategory);

export const getProductLabel = (
  productName: string,
  brandName: string,
  styleColor: string,
  isSponsored: boolean,
  hasBadges: boolean,
  isLowStock: boolean,
  preLabel: string
) => {
  let productLabel = `${brandName} - ${productName}.`;
  if (styleColor) {
    productLabel = `${productLabel} Color ${styleColor}.`;
  }
  if (isLowStock) {
    productLabel = `${productLabel} Low Stock.`;
  }
  if (isSponsored) {
    productLabel = `Sponsored Result. ${productLabel}`;
  }
  if (preLabel) {
    productLabel = `${preLabel}. ${productLabel}`;
  }
  if (hasBadges) {
    productLabel = `. ${productLabel}`;
  }
  return productLabel;
};

export enum genderTypes {
  MEN = 'men',
  WOMEN = 'women'
}

export const getProductGender = (genders: string[] = []): string | undefined => {
  if (!genders) {
    return;
  }

  const lowercaseGenders = genders?.map(s => s.toLowerCase());

  if (lowercaseGenders.includes(genderTypes.WOMEN) && lowercaseGenders.includes(genderTypes.MEN)) {
    return 'Unisex';
  } else if (lowercaseGenders.includes(genderTypes.WOMEN)) {
    return "Women's";
  } else if (lowercaseGenders.includes(genderTypes.MEN)) {
    return "Men's";
  }
  return;
};

export const isProductTypeShoesOrClothing = (productType: string | undefined): boolean =>
  productType?.toLowerCase() === 'shoes' || productType?.toLowerCase() === 'clothing';

// ImageMap TYPES
export enum imageMapTypes {
  SWCH = 'SWCH',
  PT01 = 'PT01',
  PT03 = 'PT03',
  TOPP = 'TOPP',
  LEFT = 'LEFT',
  RGHT = 'RIGHT',
  BACK = 'BACK',
  MAIN = 'MAIN',
  BOTT = 'BOTT',
  FRNT = 'FRNT',
  PAIR = 'PAIR',
  TSD = 'TSD'
}

export const getImageIdFromImageMap = (imageMap: ImageMap, imageType: keyof ImageMap): string | undefined => imageMap?.[imageType];

export const getFirstTenStyleIds = (list: Product[]) =>
  list
    .filter(product => product.styleId)
    .slice(0, 10)
    .map(product => product.styleId);

export const getMarketNameViaMerchantId = (merchantId: any) =>
  MERCHANTID_MARKET_NAME_MAPPING[merchantId as keyof typeof MERCHANTID_MARKET_NAME_MAPPING];

export const getFinalProductUrl = (originalQueryString: URLSearchParams, currentProductUrl: string) => {
  const originalProductUrl = originalQueryString ? appendQuery(currentProductUrl, originalQueryString.toString()) : currentProductUrl;

  // remove redirect from sponsored ads on swatch color change
  return originalProductUrl.replace(/&redirect=.*/, '');
};

export const isProductLowestRecentPrice = (
  hasLowestRecentPrice: boolean,
  styleId: string,
  style: { originalPrice?: number | string; price: string },
  lowestPrices: LowestStylePrice[] | undefined
) => hasLowestRecentPrice && isStyleOnSale(style) && getIsLowestRecentPrice(styleId, style.price, lowestPrices);

export const getInStockValues = (
  selectedDimensions: SelectedDimensions,
  dimensionsSet: DimensionId[],
  stockData: ProductStockData[],
  colorId: string
): InStockMap => {
  const inStockMap: InStockMap = {};
  dimensionsSet.forEach((dimension: string) => {
    const stockArray: string[] = stockData.map(stockEntry => {
      if (stockEntry.onHand === '0' || stockEntry.color !== colorId) return '';
      const isStockEntryForSelectedDimensions = Object.keys(selectedDimensions).every(
        selectedDimension => selectedDimension === dimension || selectedDimensions[selectedDimension] === stockEntry[selectedDimension]
      );
      if (!isStockEntryForSelectedDimensions) {
        return '';
      } else {
        return stockEntry[dimension]!;
      }
    });
    inStockMap[dimension] = new Set(stockArray);
  });
  return inStockMap;
};

export const formatDropdownNames = (
  dimensionValues: string[],
  valueIdToName: Record<string, ValueIDToName>,
  inStockValuesForDimension: Set<string> | undefined
): SelectMenuOption[] =>
  dimensionValues.map((dimensionValue: string) => ({
    value: dimensionValue,
    label: valueIdToName[dimensionValue]!.value,
    isInStock: inStockValuesForDimension !== undefined && inStockValuesForDimension?.has(dimensionValue)
  }));

export const generateLabelForProduct = (product: FormattedProductBundle, style: ProductStyle) => {
  const { brandName = '', productName = '', productRating = '' } = product;
  const { color = '', price = '' } = style || {};
  const encodedBrandName = makeAscii(brandName);
  const encodedProductName = makeAscii(productName);
  let label = `${encodedBrandName} - ${encodedProductName}.`;
  label = color ? `${label} Color ${color}.` : label;
  label = price ? `${label} Price ${price}.` : label;
  label = parseInt(productRating) > 0 ? `${label} ${productRating} out of 5 stars.` : label;
  return label;
};

/**
 * Helper function to translate ProductWithRelations to Product
 * Needed for mapping related styles in ColorSwatches
 * @param relatedStyles
 */
export const translateToProducts = (relatedStyles: ProductWithRelations[]): Product[] =>
  relatedStyles.map((value: ProductWithRelations) => ({
    ...value,
    imageMap: {},
    isCouture: false,
    isNew: '',
    onHand: 0,
    animationImages: value.animationImages,
    brandName: value.brandName,
    colorId: value.colorId,
    onSale: '',
    originalPrice: value.originalPrice,
    percentOff: value.percentOff,
    price: value.price,
    productId: value.productId,
    productName: value.productName,
    productRating: 1,
    productSeoUrl: value.productUrl,
    productUrl: value.productUrl,
    reviewCount: 0,
    reviewRating: 1,
    styleColor: value.color,
    styleId: value.styleId,
    swatchUrl: value.swatchUrl,
    color: value.color,
    sizing: value.sizing || {}
  }));

export const commonDefaultColorSwatchParams = {
  useMouseEnterEvent: true,
  animationImages: [],
  imageMap: {},
  isCouture: false,
  onHand: 0,
  productRating: 0,
  reviewCount: 0,
  reviewRating: 1,
  sizing: {},
  swatchUrl: '',
  allowSwatchGrouping: true
};

export const formatDimensionName = (dimensionName: string, airplaneCache?: AirplaneCache) => {
  if (dimensionName === 'size' && airplaneCache) {
    const {
      all: { genderOptions }
    } = airplaneCache;

    if (genderOptions.length > 1) {
      dimensionName = 'Size:';
    } else {
      const actualDimensionName = makeSizeLegendHeadingFromState(airplaneCache);

      !actualDimensionName.startsWith("Men's") && !actualDimensionName.startsWith("Women's")
        ? (dimensionName = 'Size:')
        : (dimensionName = actualDimensionName);
    }
  } else if (dimensionName === 'width') {
    dimensionName = `${dimensionName} Options:`;
  } else {
    dimensionName = `${dimensionName}:`;
  }
  return capitalize(dimensionName!);
};

const ProductUtils = {
  buildAngleThumbnailImages,
  buildSizeMessagingText,
  commonDefaultColorSwatchParams,
  generateRetinaImageParams,
  getColorsBySize,
  getCurrentSelectedSizeName,
  getSharingButtonLink,
  generateShareIconClickEvent,
  generateProductDimensionSelectedAmethystEvent,
  generateShareLinkEvent,
  getMissingDimensionName,
  getInStockValues,
  generateLabelForProduct,
  getNumberOfReviews,
  getPercentOff,
  getPriceForProduct,
  getGender,
  getProductGender,
  formatDropdownNames,
  formatDimensionName,
  isProductTypeShoesOrClothing,
  getHasSizeCharts,
  getProductUrlFromAsin,
  getProductViewData,
  getReviewCount,
  getSizeChartImage,
  getStockBySize,
  getSelectedStyleStockBySize,
  getStockEventPayload,
  getStyleByColor,
  filterStylesByRelatedStyleIdsInOrder,
  getStyleMap,
  hasAvailableStock,
  hasHighlightsAccordionSection,
  hasOnSaleStyle,
  hasSizeChartAccordionSection,
  isGiftCard,
  isProductDataLoaded,
  isSingleShoe,
  isSizeSelectionComplete,
  isStyleOnSale,
  MAIN_IMAGE_PARAMS,
  parseSizeChartLink,
  priceToFloat,
  isShoeType,
  translateRecoNameToAnalyticsSource,
  translateRecoTitleToAmethystWidget,
  translateToProducts,
  hasRewards,
  extractColorIdFromJanusProductLink,
  getNumProductCardsForWindow,
  getCardIndex,
  getLowestStylePrice,
  getIsLowestRecentPrice,
  groupVisibleSwatches,
  replaceJpgToWebpSwatchUrl,
  getProductLabel,
  getGroupedSwatchLength,
  getFirstTenStyleIds,
  isComparableProduct,
  getFinalProductUrl,
  isProductLowestRecentPrice
};

export default ProductUtils;
