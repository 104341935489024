import { parse, stringify } from 'query-string';
import appendQuery from 'append-query';

import timedFetch from 'middleware/timedFetch';
import marketplace from 'cfg/marketplace.json';
import { BRAND_URL_RE, FILTER_URL_RE } from 'common/regex';
import { makeQueryStringSearchTerm } from 'helpers';
import { cookieObjectToString } from 'helpers/Cookie';
import { fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { absolutifyItemImages } from 'helpers/productImageHelpers';
import { setSessionRequestedHeaders } from 'actions/session';

const {
  search: { hasBestForYou, hasTermBasedOverride, usesImageMap, hasCollapsedSizes, hasSingleShoes, enableColorSwatches, usesFacetNavData }
} = marketplace;

/**
 * Includes to be sent with all search requests
 */
export const COMMON_SEARCH_INCLUDES = usesFacetNavData
  ? [
      'productSeoUrl',
      'pageCount',
      'reviewCount',
      'productRating',
      'onSale',
      'isNew',
      'zsoUrls',
      'isCouture',
      'msaImageId',
      'facetPrediction',
      'phraseContext',
      'currentPage',
      'melodySearch',
      'styleColor',
      'seoBlacklist',
      'seoOptimizedData',
      'badges',
      'txAttrFacet_Gender',
      'productType'
    ]
  : [
      'productSeoUrl',
      'pageCount',
      'reviewCount',
      'productRating',
      'onSale',
      'isNew',
      'zsoUrls',
      'isCouture',
      'msaImageId',
      'facetPrediction',
      'phraseContext',
      'currentPage',
      'facets',
      'melodySearch',
      'styleColor',
      'seoBlacklist',
      'seoOptimizedData',
      'badges',
      'txAttrFacet_Gender',
      'productType'
    ];

/* Generates include params */
export const generateIncludes = appState => {
  const { filters: currentFilters } = appState;
  const includes = [];

  if (hasTermBasedOverride) {
    includes.push('termLanderAutoFacetOverride');
    includes.push('boostQueryOverride');
  }

  if (enableColorSwatches) {
    includes.push('enableSwatches');
  }

  // onHand is a default includes in order to send isLowStock data to Amethyst
  includes.push('onHand');

  if (hasBestForYou && currentFilters?.bestForYou) {
    includes.push('enableBestForYouSort');
  }

  if (hasBestForYou) {
    includes.push('applySyntheticTwins');
  }

  if (hasBestForYou && currentFilters?.personalizedSize?.facets?.[0]?.selected) {
    includes.push('enableSizeFilterPreference');
  }

  if (usesImageMap) {
    includes.push('imageMap');
  }

  if (hasCollapsedSizes) {
    includes.push('enableUniversalShoeSizeFacets');
  }

  // We are temp disabling this until we get better fix from backend
  // #inci-4791 - https://jira.zappos.net/browse/INCI-4791
  // if (currentFilters?.applySavedFilters) {
  //   includes.push('enableExplicitSizeFilterPreference');
  // }

  if (hasSingleShoes) {
    includes.push('enableSingleShoes');
  }

  return includesToString([...COMMON_SEARCH_INCLUDES, ...includes]);
};

export const makeQueryString = obj => appendQuery('', obj, { removeNull: true });

export const makeCalypsoOptions = function ({ cookies }, { client }) {
  let config = {
    method: 'get',
    credentials: 'include', // only good for clientside
    headers: {
      Cookie: cookieObjectToString(cookies) // only good for serverside
    }
  };

  /**
   * Proxying server-side headers for microsft ads (msft)
   */
  if (client?.request) {
    const { clientIp, clientUserAgent } = client.request;
    config.headers['User-Agent'] = clientUserAgent;
    config.headers['X-Forwarded-For'] = clientIp;
  }

  config = setSessionRequestedHeaders(config);

  return config;
};

/**
 * Convert a list of includes to a includes query param value
 * @param  {string[]} includes          list of fields to include
 * @param  {string[]} moreIncludes=[]]  additional includes to join before converting to param value
 * @return {string}                     includes querystring param value
 */
export const includesToString = (includes, moreIncludes = []) => {
  const includesStr = includes
    .concat(moreIncludes)
    .map(item => `"${item}"`)
    .join(',');
  return `[${includesStr}]`;
};

/**
 * Preprocess the criteria and build request components for a slash search request
 * @param {object}  criteria  search criteria
 * @return {object}           preprocessed criteria
 */
export const slashSearchPreprocessor = ({ location, page: criteriaPage, limit = 100, siteId, subsiteId, premadeIncludes = undefined }, state) => {
  const { page, sort, term } = parse(location.search);
  const limitFragment = `/limit/${limit}`;
  let paramUpdate;

  /* diamond related:
  if the includes were already generated, dont do it again.
  diamondDetails client side request accepts includes as a querystring. This allows us to pass the generatedIncludes based on the users clientside state to calypso when we are proxying the request.
  */
  const includes = premadeIncludes || generateIncludes(state, location);

  if (location.pathname.indexOf('.zso') > -1) {
    paramUpdate = `/Search/zso${location.pathname}`;
  } else {
    if (location.pathname === '/search' || term || page || sort) {
      paramUpdate = `/Search/${encodeURIComponent(term || null)}${page ? `/page/${encodeURIComponent(page)}` : ''}${limitFragment}${
        sort ? `/sort/${sort}` : ''
      }`;
    } else if (BRAND_URL_RE.test(location.pathname)) {
      const cleanedPath = location.pathname.replace(FILTER_URL_RE, '');
      const filterParams = cleanedPath.match(BRAND_URL_RE);
      const extraParams = filterParams[2] ? `/${filterParams[2]}` : '';
      paramUpdate = `/Search/${term || null}/filter/brandId/${filterParams[1]}${extraParams}`;
    } else {
      const newPage = criteriaPage + 1;
      paramUpdate = location.pathname
        .replace(/\/page\/(?:\d+)/gi, `/page/${newPage}`)
        // patron required the limit to appear before sort.. not sure if still true w/ calypso
        .replace(
          /(\/sort\/|$)/,
          (match, sort) =>
            sort
              ? `${limitFragment}/sort/` // there is already a sort so just include the limit
              : limitFragment // add limit
        )
        .replace(
          /(^|\/)[sS](earch)(.*)/,
          (
            val,
            m1,
            m2,
            m3 // 'search' either beginning of string or preceeded with '/'
          ) =>
            // make search uppercase and add ending / if missing
            `${m1}S${m2}${m3[0] !== '/' ? `/${m3}` : m3}`
        )
        .replace('&', '%26');
    }
  }

  return {
    path: `v2${paramUpdate}`,
    query: makeQueryString({
      includes,
      relativeUrls: true,
      siteId,
      subsiteId
    })
  };
};

/**
 * Preprocess the criteria and build request components for a zso search request
 * @param {object}  criteria  search criteria
 * @return {object}           preprocessed criteria
 */
export const zsoSearchPreprocessor = ({ path, query = {}, limit = 100, siteId, subsiteId, location }, state) => {
  const { si, sy } = query;

  const includes = generateIncludes(state, location);

  const newQuery = {
    limit,
    includes,
    relativeUrls: 'true',
    siteId,
    subsiteId
  };

  const page = query.page || query.p;
  if (page) {
    newQuery['page'] = parseInt(page, 10) + 1;
  }

  ['s', 't', 'ot'].forEach(param => {
    if (typeof query[param] !== 'undefined' && query[param] !== '') {
      newQuery[param] = param === 't' ? makeQueryStringSearchTerm(query[param]) : query[param];
    }
  });

  if (si) {
    newQuery['si'] = si;
    if (sy === '1') {
      newQuery['sy'] = 1;
    }
  }

  return {
    path: `Search/zso${path}`,
    query: makeQueryString(newQuery)
  };
};

/**
 * Execute a product Search and return a promise.
 * @param { object { calypso, criteria, cookies, state }
 *  - {object}   calypso          calypso configuration
 *  - {object}   criteria         request path and query
 *  - {object}   cookies          the cookies object
 *  - {object}   state            the application state
 * @param  {function} [fetcher=fetch]  fetch or fetch like implementation
 * @return {object}                    promise
 */
export const slashSearchProducts = (
  { calypso: { url, siteId, subsiteId }, criteria, cookies, state, includes },
  fetcher = timedFetch('calypsoSearch')
) => {
  const { path, query } = slashSearchPreprocessor({ ...criteria, siteId, subsiteId, premadeIncludes: includes }, state);

  return fetcher(`${url}/${path}${query}`, makeCalypsoOptions({ cookies }, state));
};

/**
 * Execute a ZSO Search and return a promise.
 * @param  {object}   calypso          calypso configuration
 * @param  {object}   location         request path and query
 * @param  {function} [fetcher=fetch]  fetch or fetch like implementation
 * @return {object}                    promise
 */
export const zsoSearchProducts = ({ url, siteId, subsiteId }, criteria, cookies, state, fetcher = timedFetch('calypsoZsoSearch')) => {
  const { path, query } = zsoSearchPreprocessor({ ...criteria, siteId, subsiteId }, state);

  return fetcher(`${url}/${path}${query}`, makeCalypsoOptions({ cookies }, state));
};

export function fetchSearchSimilarity(url, { styleId, type, page, siteId = 1, subsiteId, limit, opts }, fetcher = timedFetch('searchSimilarity')) {
  const { imageServerUrl } = opts;
  return fetcher(
    `${url}/Search/Similarity/type/${type}?relativeUrls=true&styleId=${styleId}&limit=${limit}&siteId=${siteId}&subsiteId=${subsiteId}&page=${page}`
  )
    .then(fetchErrorMiddleware)
    .then(recoResponse => {
      if (recoResponse && recoResponse.results) {
        absolutifyItemImages(recoResponse.results, imageServerUrl);
      }
      return recoResponse;
    });
}

export function searchAutoComplete(
  { url, siteId, subsiteId },
  { cookies, client },
  { term, categories },
  fetcher = timedFetch('searchAutoComplete')
) {
  return fetcher(
    `${url}/hesiod/autocomplete/${encodeURIComponent(term)}?${stringify({
      categories,
      siteId,
      subsiteId
    })}`,
    makeCalypsoOptions({ cookies }, { client })
  );
}

/**
 * Execute a search for related styles swatch information, given an array of styleIds
 * e.g. https://prod.olympus.zappos.com/v2/Search/null/filter/styleId/2117833%20OR%203435261?includes=%5B%22enableSwatches%22%2C%22enableSingleShoes%22%5D&relativeUrls=true&siteId=1&subsiteId=17
 * @param  {object}   calypso          calypso configuration
 * @param  string[]   styleIds         array of product styleIds
 * @param  {function} [fetcher=fetch]  fetch or fetch like implementation
 * @return {object}                    promise
 */
export function fetchProductRelations({ url, siteId, subsiteId }, styleIds, fetcher = timedFetch('productRelations')) {
  const includes = [
    'badges',
    'enableSingleShoes',
    'enableSwatches',
    'onHand',
    'productRating',
    'reviewCount',
    'styleColor',
    'txAttrFacet_Gender',
    'productType',
    'noNavigation'
  ];

  const searchIncludes = includesToString(includes);

  return fetcher(
    `${url}/v2/Search/null/limit/500/filter/styleId/${styleIds.join('%20OR%20')}?includes=${encodeURIComponent(
      searchIncludes
    )}&relativeUrls=true&siteId=${siteId}&subsiteId=${subsiteId}`
  );
}

/**
 * Execute a search for related styles swatch information, given an array of productIds
 * e.g. https://prod.olympus.zappos.com/v2/Search/null/filter/productIds/2117833%20OR%203435261?includes=%5B%22enableSwatches%22%2C%22enableSingleShoes%22%5D&relativeUrls=true&siteId=1&subsiteId=17
 * @param  {object}   calypso          calypso configuration
 * @param  string[]   productIds         array of product productIds
 * @param  {function} [fetcher=fetch]  fetch or fetch like implementation
 * @return {object}                    promise
 */
export function fetchProductRelationsByProductIds({ url, siteId, subsiteId }, productIds, fetcher = timedFetch('productRelations')) {
  const includes = [
    'badges',
    'enableSingleShoes',
    'enableSwatches',
    'onHand',
    'productRating',
    'reviewCount',
    'styleColor',
    'txAttrFacet_Gender',
    'productType',
    'noNavigation'
  ];

  const searchIncludes = includesToString(includes);

  return fetcher(
    `${url}/v2/Search/null/limit/500/filter/productId/${productIds.join('%20OR%20')}?includes=${encodeURIComponent(
      searchIncludes
    )}&relativeUrls=true&siteId=${siteId}&subsiteId=${subsiteId}`
  );
}

/**
 * Execute a search for product details information, given an array of styleIds
 * e.g. https://prod.olympus.zappos.com/v2/Search/null/limit/100/si/4101560,3900758?includes=%5B%22badges%22%5D&relativeUrls=true&siteId=1&subsiteId=17
 * @param  {object}   calypso          calypso configuration
 * @param styleIds                     array of product styleIds
 * @param signal                       to abort calls explicitly
 * @param  {function} [fetcher=fetch]  fetch or fetch like implementation
 * @return {object}                    promise
 */
export function fetchProductDetailsByStyleIds({ url, siteId, subsiteId }, styleIds, signal, fetcher = timedFetch('productRelationsFromStyleIds')) {
  const searchIncludes = includesToString([
    'badges',
    'enableSingleShoes',
    'enableSwatches',
    'onHand',
    'productRating',
    'reviewCount',
    'styleColor',
    'noNavigation'
  ]);

  return fetcher(
    `${url}/v2/Search/null/limit/40/si/${styleIds.join(',')}?includes=${encodeURIComponent(
      searchIncludes
    )}&relativeUrls=true&siteId=${siteId}&subsiteId=${subsiteId}`,
    signal
  );
}
