import badges from 'reducers/badges';
import account from 'reducers/account/account';
import ads from 'reducers/ads';
import autoComplete from 'reducers/autoComplete';
import payment from 'reducers/account/payment';
import authentication from 'reducers/authentication';
import deviceProps from 'store/ducks/deviceProps/reducer';
import drop from 'reducers/drop';
import eGiftCards from 'reducers/eGiftCards';
import facets, { isFacetsVisible } from 'reducers/search/facets';
import filters from 'reducers/search/filters';
import products from 'reducers/search/products';
import searchLayout from 'reducers/search/searchLayout';
import cookies from 'reducers/cookies';
import responseCookies from 'reducers/responseCookies';
import client from 'reducers/client';
import meta from 'reducers/meta';
import product from 'reducers/detail/productDetail';
import sharing from 'reducers/detail/sharing';
import headerFooter from 'reducers/headerfooter';
import shipping from 'reducers/account/shipping';
import order from 'reducers/account/order';
import orders from 'reducers/account/orders';
import returns from 'reducers/account/returns';
import hearts from 'reducers/hearts';
import ask from 'reducers/ask/ask';
import hmdSurvey from 'reducers/ask/hmdSurvey';
import brandPage from 'reducers/brand/brandPage';
import cart from 'reducers/cart/cart';
import environmentConfig from 'reducers/environmentConfig';
import landingPage from 'reducers/landing/landingPage';
import microsoftUetTag from 'reducers/microsoftUetTag';
import newsfeed from 'reducers/landing/newsfeed';
import iframe from 'reducers/landing/iframe';
import recos from 'reducers/recos';
import pixelServer from 'reducers/pixelServer';
import { error } from 'reducers/errors';
import pageLoad from 'reducers/pageLoad';
import pageView from 'reducers/pageView';
import redirect from 'reducers/redirect';
import rewards from 'reducers/account/rewards';
import reviews from 'reducers/reviews/reviews';
import shipmentTracking from 'reducers/shipmentTracking';
import subscriptionsInfo from 'reducers/subscriptionsInfo';
import sizingChooser from 'reducers/detail/sizingChooser';
import holmes from 'reducers/holmes';
import wildCard from 'reducers/wildCard';
import zfcSessionId from 'reducers/zfcSessionId';
import localStorage from 'reducers/localStorage';
import exchange from 'reducers/account/exchange';
import amethyst from 'reducers/amethyst';
import feedback from 'store/ducks/feedback/reducer';
import shipOption from 'store/ducks/shipOption/reducer';
import history from 'store/ducks/history/reducer';
import checkoutData from 'store/ducks/checkout/reducer';
import sharedPayment from 'store/ducks/payment/reducer';
import sharedRewards from 'store/ducks/rewards/reducer';
import address from 'store/ducks/address/reducer';
import exchangesData from 'store/ducks/exchanges/reducer';
import giftOptions from 'store/ducks/giftoptions/reducer';
import search from 'store/ducks/search/reducer';
import killswitch from 'store/ducks/killswitch/reducer';
import emergencyBanner from 'store/ducks/emergencyBanner/reducer';
import unleash from 'reducers/unleash';
import outfitRecos from 'reducers/outfitRecos';
import visualSearch from 'reducers/visualSearch';

// please alphabetize
export default {
  exchangesData,
  account,
  address,
  ads,
  amethyst,
  ask,
  hmdSurvey,
  authentication,
  autoComplete,
  badges,
  brandPage,
  cart,
  client,
  cookies,
  deviceProps,
  drop,
  eGiftCards,
  environmentConfig,
  emergencyBanner,
  error,
  exchange,
  facets,
  feedback,
  giftOptions,
  hearts,
  filters,
  headerFooter,
  history,
  holmes,
  iframe,
  isFacetsVisible,
  killswitch,
  landingPage,
  localStorage,
  checkoutData,
  meta,
  microsoftUetTag,
  newsfeed,
  order,
  orders,
  outfitRecos,
  pageLoad,
  pageView,
  payment,
  pixelServer,
  product,
  products,
  recos,
  redirect,
  responseCookies,
  returns,
  reviews,
  rewards,
  search,
  searchLayout,
  sharedPayment,
  sharedRewards,
  sharing,
  shipmentTracking,
  shipOption,
  shipping,
  sizingChooser,
  subscriptionsInfo,
  unleash,
  url: (state = {}) => state,
  visualSearch,
  wildCard,
  zfcSessionId
};
