import type { ReactNode } from 'react';

import UtilityDottedTrueToSizeMediumIcon from 'tailwind/components/Icons/UtilityDottedTrueToSizeMediumIcon';

export interface RecommendedSizingLayoutProps {
  children?: ReactNode;
  isIncludeIcon?: boolean;
}

const RecommendedSizingLayout = ({ children, isIncludeIcon = true }: RecommendedSizingLayoutProps) => {
  if (!isIncludeIcon) {
    return children;
  }

  return (
    <div className="flex h-fit w-fit gap-x-2">
      <UtilityDottedTrueToSizeMediumIcon size={24} className="flex-none" />
      {children}
    </div>
  );
};

export default RecommendedSizingLayout;
