import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { pluralize } from 'helpers/index';

import css from 'styles/components/rating.scss';

interface Props {
  additionalClasses?: string;
  countClass?: string;
  hasDisplayReviewCount?: boolean;
  rating: string | number;
  reviewCount?: string;
  isUseBarGraph?: boolean;
  ratingPercent?: string;
}

const Rating = (props: Props) => {
  const { countClass, additionalClasses, rating, reviewCount = 0, hasDisplayReviewCount, isUseBarGraph, ratingPercent } = props;
  const { marketplace, testId } = useMartyContext();
  const { features: { showRatings } = {} } = marketplace;

  if (!showRatings) {
    return null;
  }

  if (!(parseInt(String(rating)) > 0)) {
    return null;
  }

  const outerSpanProps: Record<string, any> = {
    // We want the stars on the rating to shrink when they come from the MelodyCardProduct component, so check for that prop to add the correct className
    'className': cn(!isUseBarGraph && css.rating, additionalClasses),
    'itemScope': true,
    'data-star-rating': rating,
    'data-test-id': testId('rating')
  };

  const style = {
    '--barPercentage': ratingPercent + '%'
  } as React.CSSProperties;

  const makeReviewCount = () => {
    if (reviewCount && parseInt(reviewCount) > 0) {
      return (
        <span aria-label={`${reviewCount} ${pluralize('review', reviewCount)}`} className={cn(css.count, countClass)}>
          ({reviewCount})
        </span>
      );
    } else {
      return;
    }
  };

  return (
    <>
      {isUseBarGraph && <span className={css.barGraphSpacing}>{rating}</span>}
      <span {...outerSpanProps}>
        {!isUseBarGraph ? (
          <>
            <span className={css.numberOfStars}>{rating}</span>
            <span className="sr-only">
              Rated {rating} {pluralize('star', rating)} out of 5
            </span>
          </>
        ) : (
          <span style={style} className={css.barGraph}></span>
        )}
      </span>
      {hasDisplayReviewCount && makeReviewCount()}
    </>
  );
};

export default Rating;
