import React from 'react';
import { connect, Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ExecutionEnv from 'exenv';

import UnleashFlagProvider from 'containers/UnleashFlagProvider';
import type { AppState } from 'types/app';
import type { EnvironmentConfigState } from 'types/environment';
import type { store as reduxStore } from 'entrypoints/bootstrapOnClient';
import MartyContextProvider from 'containers/MartyContextProvider';
import { AlertRoot, LogRoot, StatusRoot } from 'components/common/AriaLive';
import Themer from 'tailwind/components/Themer/Themer';
import * as Tooltip from 'components/tooltip/Tooltip'; // eslint-disable-line

interface RootProps {
  children: React.ReactNode;
  environmentConfig: EnvironmentConfigState;
  history: object;
  store: typeof reduxStore;
  serverState: any;
}

const helmetContext = ExecutionEnv.canUseDOM ? undefined : {};

const Root = (props: RootProps) => {
  const { children, environmentConfig, history, store, serverState } = props;

  const {
    api: { unleash }
  } = environmentConfig;

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Provider key={Math.random()} store={store} serverState={serverState}>
          <UnleashFlagProvider config={unleash}>
            <Themer />
            <MartyContextProvider history={history} environmentConfig={environmentConfig}>
              <Tooltip.Provider delayDuration={0}>{children}</Tooltip.Provider>
            </MartyContextProvider>
          </UnleashFlagProvider>
        </Provider>
        <AlertRoot />
        <StatusRoot />
        <LogRoot />
      </HelmetProvider>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  features: state.features
});

export default connect(mapStateToProps)(Root);
