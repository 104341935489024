import React from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import type { CurrencyObj } from 'helpers/DataFormatUtils';

import css from 'styles/components/common/card/cardPrice.scss';

interface CardPriceProps {
  price: CurrencyObj;
  msrp: CurrencyObj;
  onSale: boolean;
  prefix?: string;
}

const CardPrice = (props: CardPriceProps) => {
  const { testId } = useMartyContext();
  const { price, msrp, onSale, prefix } = props;
  const isOnSale = onSale && !prefix;
  return (
    <>
      <dt>Price</dt>
      <dd className={cn({ [css.onSale]: isOnSale })}>
        <span className={css.price} data-test-id={testId('price')}>
          {prefix}
          {price?.string}
        </span>
        {isOnSale && (
          <span className={css.msrpLabelPrice}>
            <span className={css.msrpLabel}>MSRP: </span>
            <span className={css.originalPrice} data-test-id={testId('originalPrice')}>
              {msrp.string}
            </span>
          </span>
        )}
      </dd>
    </>
  );
};

export default CardPrice;
