import type { ReactNode } from 'react';

import { cn } from 'helpers/classnames';
import type { ImageProps } from 'components/common/Image';
import Image from 'components/common/Image';

import css from 'styles/components/common/card/cardMedia.scss';

interface CardMediaProps {
  imageIndex?: number;
  imageClassName?: string;
  isHovered?: boolean;
  mainImage: ImageProps;
  hoverImage?: ImageProps;
  children?: ReactNode;
  className?: string;
  forceLoadIndex?: number;
  imageNoBackground?: boolean;
  fullSizeImage?: boolean;
  onHover?: () => void;
  disableHoverImage?: boolean;
}

const CardMedia = ({
  className,
  imageClassName,
  isHovered,
  mainImage,
  hoverImage,
  imageNoBackground = false,
  fullSizeImage = false,
  onHover,
  disableHoverImage = false,
  children
}: CardMediaProps) => {
  if (isHovered && onHover) {
    onHover();
  }

  return (
    <div
      className={cn(
        className,
        css.imageWrapper,
        { [css.hovered]: !disableHoverImage && isHovered && hoverImage?.src },
        // .imageNoBackground exists but is nested under a :not() which the linter
        // has trouble reading.
        // eslint-disable-next-line css-modules/no-undef-class
        { [css.imageNoBackground]: imageNoBackground }
      )}
    >
      {children}
      <figure>
        <Image className={cn(css.mainImage, { [css.fullSizeImage]: fullSizeImage }, imageClassName)} {...mainImage} />
        {hoverImage && <Image className={cn(css.hoverImage, imageClassName)} {...hoverImage} />}
      </figure>
    </div>
  );
};

export default CardMedia;
