import { toFloatInt } from 'helpers/NumberFormats';
import { getAmethystPageType } from 'helpers/analytics';
import { store } from 'entrypoints/bootstrapOnClient';
import { devLogger } from 'middleware/logger';

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/RecommendationImpression.proto
 *
 * @param {string} numberOfRecommendations - number of items/products
 * @param {string} recommendationType - See WebsiteEnums.proto file in AmethystEvents codebase.
 * UNKNOWN_RECOMMENDATION_TYPE, BRAND_RECOMMENDATION, PRODUCT_RECOMMENDATION, PRODUCT_CATEGORY_RECOMMENDATION
 * @param {string} recommendationSource - See WebsiteEnums.proto file in AmethystEvents codebase.
 * UNKNOWN_RECOMMENDATION_SOURCE, ZAPPOS_DATA_SCIENCE, EP13N, INDIA_MACHINE_LEARNING, SENTIENT
 * @param {string} widgetType - See WebsiteEnums.proto file in AmethystEvents codebase.
 * UNKNOWN_RECOMMENDATION_WIDGET, SIMILAR_PRODUCT_WIDGET, BRAND_RECOMMENDATION_WIDGET, IOS_HOMEPAGE_SUGGESTED_CATEGORIES, YOUR_RECENTLY_VIEWED_WIDGET
 */
export const evRecommendationImpression = ({ numberOfRecommendations, recommendationType, recommendationSource, widgetType }) => ({
  recommendationImpression: {
    numberOfRecommendations,
    recommendationType,
    recommendationSource,
    widgetType
  }
});

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/RecommendationImpressionWrapper.proto
 *
 * @param {array} recommendationImpression - Array of recommendationImpressions objects with keys of: numberOfRecommendations, recommendationType, recommendationSource, widgetType
 */
export const evRecommendationImpressionWrapper = ({
  products,
  recommendationImpression,
  viewableImpression = undefined,
  sourcePage = undefined
}) => {
  if (!recommendationImpression) {
    devLogger('WARNING: recommendationImpression is empty!');
  }

  const impressedProducts = products?.map(({ productId, styleId, colorId, badgeId }) => ({
    productId,
    styleId,
    colorId,
    supplementalData: { badgeId }
  }));
  return {
    recommendationImpressionWrapper: {
      recommendationImpression,
      impressedProducts,
      sourcePage: sourcePage || getAmethystPageType(store.getState().pageView.pageType),
      viewableImpression
    }
  };
};

/**
 * https://code.amazon.com/packages/AmethystEvents/blobs/mainline/--/configuration/include/com/zappos/amethyst/website/RecommendationClick.proto
 *
 * @param {integer} index - index of item clicked
 * @param {string} recommendationType - See WebsiteEnums.proto file in AmethystEvents codebase.
 * UNKNOWN_RECOMMENDATION_TYPE, BRAND_RECOMMENDATION, PRODUCT_RECOMMENDATION, PRODUCT_CATEGORY_RECOMMENDATION
 * @param {string} recommendationValue - sku/product_category/brand, ex '123456'(sku)
 * @param {Object} recommendedProduct - alternative to recommendationValue that is ProductIdentifiers.
 * @param {string} recommendationSource - See WebsiteEnums.proto file in AmethystEvents codebase.
 * UNKNOWN_RECOMMENDATION_SOURCE, ZAPPOS_DATA_SCIENCE, EP13N, INDIA_MACHINE_LEARNING, SENTIENT
 * @param {string} widgetType - See WebsiteEnums.proto file in AmethystEvents codebase.
 * UNKNOWN_RECOMMENDATION_WIDGET, SIMILAR_PRODUCT_WIDGET, BRAND_RECOMMENDATION_WIDGET, IOS_HOMEPAGE_SUGGESTED_CATEGORIES, YOUR_RECENTLY_VIEWED_WIDGET
 */

export const evRecommendationClick = ({
  index,
  recommendationType,
  recommendationValue,
  recommendedProduct,
  recommendationSource,
  widgetType,
  sourcePage,
  clickThrough
}) => ({
  recommendationClick: {
    index: toFloatInt(index),
    recommendationType,
    recommendationValue,
    recommendedProduct,
    recommendationSource,
    widgetType,
    sourcePage,
    clickThrough
  }
});
