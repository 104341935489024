import React from 'react';

import { safeJsonLdReplacer } from './JsonLDReplacer';

import getCardData from 'data/getCardData';

export interface WithJsonLdProductProps {
  brandName: string;
  color?: string;
  price?: string;
  productName: string;
  productUrl?: string;
  onHand?: number;
  productSeoUrl?: string;
  productId?: string;
  [other: string]: unknown; // Allow additional properties on HoC child components
}

const withJsonLdProduct =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: WithJsonLdProductProps) => {
    const { brandName, color, productName, productUrl, onHand, productSeoUrl, productId } = props;
    const { media, price, gender, reviews } = getCardData(props);
    const isAvailable = onHand && onHand > 0;
    const productLink = productSeoUrl || productUrl || null;
    const shouldRenderAggregateRating = parseInt(String(reviews.roundedRating)) > 0 && reviews.ratingCount && parseInt(reviews.ratingCount) > 0;

    const jsonLdData = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      'name': productName,
      ...(brandName && {
        brand: {
          name: brandName
        }
      }),
      ...(price && {
        offers: {
          '@type': 'Offer',
          'price': price.price?.int,
          'priceCurrency': 'USD',
          'availability': `https://schema.org/${isAvailable ? 'inStock' : 'OutOfStock'}`,
          'url': productLink
        }
      }),
      gender,
      color,
      'url': productUrl,
      'image': media?.mainImage?.src,
      ...(shouldRenderAggregateRating && {
        aggregateRating: {
          '@type': 'AggregateRating',
          'ratingValue': String(reviews.roundedRating),
          'reviewCount': reviews.ratingCount,
          'ratingCount': reviews.ratingCount
        }
      }),
      'sku': productId
    };

    return (
      <>
        <Component {...(props as P)} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData, safeJsonLdReplacer, 2) }} />
      </>
    );
  };

export default withJsonLdProduct;
