import ExecutionEnvironment from 'exenv';
import type { Dispatch, Store } from 'redux';
import type { AddEventCallback, AmethystEvent, TitaniteConfig } from 'titanite-javascript';
import Titanite from 'titanite-javascript';

import { clearEventQueue, storeEventInQueue } from 'actions/amethyst';
import { trackError } from 'helpers/ErrorUtils';
import { logDebug, logError, logToServer } from 'middleware/logger';
import type { Marketplace } from 'types/app';

type WrapperEventFunction<T> = (eventData: T) => AmethystEvent;
type WrapperEventMethod<T> = () => [WrapperEventFunction<T> | undefined, T];

function logTitaniteNotInstantiatedError(callingFunctionName: string) {
  logError(`Attempt to call ${callingFunctionName} before titanite was instantiated`);
}

export function initTitaniteConfig(isMartyEnvProduction: boolean, marketplace: Marketplace): Partial<TitaniteConfig> {
  const stage = isMartyEnvProduction ? 'prod' : 'beta';
  const titaniteConfigFromMarketplaceConfig = marketplace.api.titanite;
  const amethystEnvConfigFromMarketplaceConfig = titaniteConfigFromMarketplaceConfig.amethystEnv;
  const marketplaceName = amethystEnvConfigFromMarketplaceConfig.marketplace as TitaniteConfig['amethystEnv']['marketplace'];
  return {
    ...titaniteConfigFromMarketplaceConfig,
    amethystEnv: {
      ...amethystEnvConfigFromMarketplaceConfig,
      marketplace: marketplaceName,
      stage
    }
  };
}

let titaniteInstance: Titanite | undefined;
export function initTitanite(titaniteConfig: TitaniteConfig, zfcSessionId?: string) {
  titaniteInstance = new Titanite(titaniteConfig);

  if (ExecutionEnvironment.canUseDOM) {
    window.titanite = titaniteInstance;
  } else {
    logError('Attempting to initialize titanite server side');
  }

  if (zfcSessionId) {
    titaniteInstance.setDefaultFieldValue('sessionId', zfcSessionId);
  } else if (ExecutionEnvironment.canUseDOM && window.zfcSessionId) {
    titaniteInstance.setDefaultFieldValue('sessionId', window.zfcSessionId);
  }
}

const isTestEnv = process.env.NODE_ENV === 'test';

let queuedAmethystEvents: AmethystEvent[] = [];

const addEventCallback: AddEventCallback = r => {
  const failed = !r.success || (r.failedEvents && r.failedEvents.length);
  if (failed) {
    return trackError('NON-FATAL', 'Track Amethyst Event: Titanite FAILED', JSON.stringify(r));
  } else {
    logDebug('Track Amethyst Event: Titanite SUCCESS', JSON.stringify(r));
  }
};

interface TrackWithAmethystArgs<T> {
  canUseDOM: boolean;
  dispatch?: Dispatch;
  eventData: T;
  eventFunction?: WrapperEventFunction<T>;
  overrideDebounce?: boolean;
  titanite: Titanite | undefined;
}

function trackWithAmethyst<T>(args: TrackWithAmethystArgs<T>) {
  const { canUseDOM, dispatch, eventData, eventFunction, titanite, overrideDebounce } = args;

  let payload;

  // if event is not passed in
  if (!eventData) {
    logDebug('Please pass in an amethyst event');
    return;
  }

  // if using middleware ( 2 params vs original 3 )
  if (!eventFunction) {
    payload = { ...eventData };
  } else {
    payload = { ...eventFunction(eventData) };
  }

  if (!canUseDOM) {
    if (!dispatch) {
      // log the keys of the payload since they won't contain PII and will help track down when/where this is coming from
      const fields = Object.keys(payload).filter(val => val !== 'abTests');
      logError(`Use serverTrack if attempting to fire an event server-side, payload fields were [${fields.join()}]`);
      return;
    }
    dispatch(storeEventInQueue(payload));
  } else if (!hasView) {
    // Queue events that have been fired before titaniteView() has been initiated.
    // Once we have a view then fire the queued events.
    queuedAmethystEvents.push(payload);
    return;
  } else {
    if (!titanite) {
      logTitaniteNotInstantiatedError('trackWithAmethyst');
      return;
    }
    // if no current zfc session id is set
    if (!titanite?.defaultFieldValues?.sessionId) {
      logToServer(`[AMETHYST]: No ZFC session ID to set: ${eventFunction?.name || Object.keys(eventFunction || {})?.[0]}`);
    }
    // dispatch amethyst event
    titanite.addEvent(payload, addEventCallback, overrideDebounce);
  }
}

export const flushServerSideQueue = (store: Store, titanite = titaniteInstance) => {
  if (!titanite) {
    logTitaniteNotInstantiatedError('flushServerSideQueue');
    return;
  }
  const {
    amethyst: { queue }
  } = store.getState();
  if (queue.length) {
    titanite.addEvents(queue, addEventCallback);
    store.dispatch(clearEventQueue());
  }
};

const fireQueuedAmethystEvents = (titanite = titaniteInstance) => {
  if (!titanite) {
    logTitaniteNotInstantiatedError('fireQueuedAmethystEvents');
    return;
  }
  if (queuedAmethystEvents.length) {
    queuedAmethystEvents.forEach(payload => titanite.addEvent(payload, addEventCallback));
    queuedAmethystEvents = [];
  }
};

let hasView = false;
const trackViewWithTitanite = (titanite: Titanite) => {
  if (hasView) {
    titanite.endView();
  }

  hasView = true;
  titanite.startView();

  fireQueuedAmethystEvents(titanite);
};

/**
 * tracking amethyst event Server-Side
 * @param  {Function} eventMethod     function that returns an array that contains the event function and object
 *                                    track(() => ([eventFunction, eventObject]))
 * @param  {Function} dispatch
 */
export function serverTrack<T>(eventMethod: WrapperEventMethod<T>) {
  return (dispatch: Dispatch) => track(eventMethod, dispatch);
}

function trackHelper<T>(
  eventMethod: WrapperEventMethod<T>,
  dispatch?: Dispatch,
  canUseDOM = ExecutionEnvironment.canUseDOM,
  titanite = titaniteInstance,
  overrideTestEnv = false,
  overrideDebounce = false,
  sendTrackError = trackError
) {
  try {
    const [eventFunction, eventData] = eventMethod();
    if (eventFunction) {
      (!isTestEnv || overrideTestEnv) &&
        trackWithAmethyst({
          eventFunction,
          eventData,
          titanite,
          dispatch,
          overrideDebounce,
          canUseDOM
        });
    }
  } catch (err: any) {
    sendTrackError('NON-FATAL', 'Amethyst data error.', err);
  }
}

/**
 * tracking amethyst event
 * @param  {Function} eventMethod     function that returns an array that contains the event function and object
 *                                    track(() => ([eventFunction, eventObject]))
 * @param  {Boolean} dispatch         needed if event is triggered server-side
 * All params below are for mocking and testing.
 * @param  {Boolean} canUseDOM        is the DOM available?
 * @param  {Object} titanite          Is the instance of titanite from the library?
 * @param  {Boolean} overrideTestEnv  Are we faking the test environment?
 * @param  {Function} sendTrackError  for tracking errors
 */
export function track(
  eventMethod: (...args: any[]) => any,
  dispatch?: Dispatch,
  canUseDOM = ExecutionEnvironment.canUseDOM,
  titanite = titaniteInstance,
  overrideTestEnv = false,
  overrideDebounce = false,
  sendTrackError = trackError
) {
  trackHelper(eventMethod, dispatch, canUseDOM, titanite, overrideTestEnv, overrideDebounce, sendTrackError);
}

// new fire event middleware
export function middlewareTrack<T>(eventData: T, canUseDOM = ExecutionEnvironment.canUseDOM, titanite = titaniteInstance, overrideTestEnv = false) {
  return (!isTestEnv || overrideTestEnv) && trackWithAmethyst({ eventData, titanite, canUseDOM });
}

// fire start/end view
export function titaniteView(canUseDOM = ExecutionEnvironment.canUseDOM, titanite = titaniteInstance) {
  if (!canUseDOM) {
    return;
  }
  if (!titanite) {
    logTitaniteNotInstantiatedError('titaniteView');
    return;
  }
  trackViewWithTitanite(titanite);
}
