import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { cn } from 'helpers/classnames';
import ContentBox from 'components/common/ContentBox';
import useMartyContext from 'hooks/useMartyContext';
import LandingPageLink from 'components/landing/LandingPageLink';
import LandingPageMedia from 'components/landing/LandingPageMedia';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { evHeroClick, evHeroImpression } from 'events/symphony';
import { track } from 'apis/amethyst';
import { parseTextToStyle } from 'helpers/parseToReact';
import { getIsGoodsLabelPage } from 'utils/landing';
import { dashOrSnakeCaseToCamelCaseDeep } from 'helpers/DataFormatUtils';

/* eslint-disable css-modules/no-unused-class */
/** barfs on image ..  debug later **/
import css from 'styles/components/landing/zapposHero.scss';

/*
  Potential positions
  'inside-left-top|inside-left-center|inside-left-bottom|
  inside-center-top|inside-center-center|inside-center-bottom|
  inside-right-top|inside-right-center|inside-right-bottom|
  outside-left|outside-top|outside-right|outside-bottom
  between
*/
const xPositionInsideClassMap = {
  left: css.left,
  center: css.centerX,
  right: css.right
};

const yPositionInsideClassMap = {
  top: css.top,
  center: css.centerY,
  bottom: css.bottom
};

const positionOutsideClassMap = {
  top: css.outsideTop,
  left: css.outsideLeft,
  bottom: css.outsideBottom
  // right is the default and does not require a class
};

export const ZapposHero = ({
  slotName,
  slotIndex,
  slotDetails = {},
  onComponentClick,
  shouldLazyLoad,
  isFullWidth,
  containerStyle,
  contentBoxContainerStyle,
  contentBoxHeadingStyle,
  contentBoxCopyStyle,
  contentBoxLinksStyle,
  pageName,
  pageInfo
}) => {
  const { testId } = useMartyContext();
  const { componentName, heading, containerBreak = false, copy, subCopy, links = [], eventLabel, media = {}, content = {} } = slotDetails;
  const label = eventLabel || componentName;
  const firstLink = links.length > 0 ? links[0] : null;
  const { primary, secondary, content: contentMedia } = media;
  const heroCount = primary && secondary ? 2 : 1;
  const {
    position = 'outside-left',
    useImageAsHeading,
    backgroundColor,
    offImageColor = '',
    color,
    textAlign,
    contentWidth,
    widthFitContent,
    containerStyle: containerStyleSlotDetail,
    contentBoxContainerStyle: contentBoxContainerStyleSlotDetail,
    contentBoxHeadingStyle: contentBoxHeadingStyleSlotDetail,
    contentBoxCopyStyle: contentBoxCopyStyleSlotDetail,
    contentBoxLinksStyle: contentBoxLinksStyleSlotDetail
  } = content;

  const useFullWidth = containerBreak || isFullWidth;

  useEffect(() => {
    track(() => [evHeroImpression, { slotDetails, slotIndex, slotName, heroCount }]);
  }, [heroCount, slotDetails, slotIndex, slotName]);

  const onClick = useCallback(
    evt => {
      onComponentClick?.(evt);
      const {
        target: {
          dataset: { eventvalue: identifier }
        }
      } = evt;
      track(() => [evHeroClick, { slotDetails, slotIndex, slotName, heroCount, identifier }]);
    },
    [heroCount, onComponentClick, slotDetails, slotIndex, slotName]
  );

  let containerClass;
  let contentBoxClassPositioning;

  const [positionType, ...positions] = position?.split('-') || [];
  if (positionType === 'inside') {
    const [xPosition, yPosition] = positions;
    containerClass = css.insideContainer;
    contentBoxClassPositioning = cn(xPositionInsideClassMap[xPosition], yPositionInsideClassMap[yPosition]);
  } else if (positionType === 'outside') {
    const [outsidePosition] = positions;
    containerClass = positionOutsideClassMap[outsidePosition];
  } else if (positionType === 'between' && heroCount === 2) {
    containerClass = css.between;
  }

  // ensure videos maintain aspect ratio
  const primaryWithEmbeddedVideo = primary && !secondary && primary.type === 'video' && primary.isEmbedded && heading;
  const heroStyles = { ...parseTextToStyle(containerStyleSlotDetail), ...parseTextToStyle(containerStyle) };

  if (primaryWithEmbeddedVideo) {
    if (primary.embeddedRatio) {
      // TODO: curently `primaryembeddedRatio` is a percentage (e.g. 56.25%); moving forward should be a decimal (e.g. .5625), so we can (hopefully) eventually remove the ternary
      heroStyles['--iframe-video-ratio'] = primary.embeddedRatio.endsWith('%') ? parseFloat(primary.embeddedRatio) / 100 : primary.embeddedRatio;
    }

    if (contentWidth) {
      heroStyles['--hero-content-width'] = contentWidth;
    }
  }

  const contentBoxStyles = {
    containerStyle: { ...parseTextToStyle(contentBoxContainerStyleSlotDetail), ...parseTextToStyle(contentBoxContainerStyle) },
    headingStyle: { ...parseTextToStyle(contentBoxHeadingStyleSlotDetail), ...parseTextToStyle(contentBoxHeadingStyle) },
    copyStyle: { ...parseTextToStyle(contentBoxCopyStyleSlotDetail), ...parseTextToStyle(contentBoxCopyStyle) },
    linksStyle: { ...parseTextToStyle(contentBoxLinksStyleSlotDetail), ...parseTextToStyle(contentBoxLinksStyle) }
  };

  return (
    <div
      className={cn(css.mainContainer, { [css.containerBreak]: containerBreak })}
      data-slot-id={slotName}
      data-slotindex={slotIndex}
      data-test-id={testId(`${componentName}${position ? `:${position}` : ''}`)}
    >
      <div className={css.containerFull}>
        <div className={css.innerContainer}>
          <article
            className={cn(
              css.container,
              { [css.fullWidth]: useFullWidth },
              'heroComponent',
              containerClass,
              { [css.twoHeroes]: heroCount === 2 },
              { [css.widthFitContent]: widthFitContent },
              { [css.embeddedVideoContainer]: primaryWithEmbeddedVideo }
            )}
            style={dashOrSnakeCaseToCamelCaseDeep(heroStyles)}
          >
            <LandingPageMedia
              {...primary}
              className={cn(css.primary)}
              shouldLazyLoad={shouldLazyLoad}
              slotName={slotName}
              slotIndex={slotIndex}
              isFullWidth={useFullWidth}
            />
            <LandingPageMedia
              {...secondary}
              className={cn(css.secondary)}
              shouldLazyLoad={shouldLazyLoad}
              slotName={slotName}
              slotIndex={slotIndex}
              isFullWidth={useFullWidth}
            />
            <ContentBox
              className={cn(css.contentBox, contentBoxClassPositioning, { [css.bgColorPadding]: backgroundColor })}
              styles={contentBoxStyles}
              heading={heading}
              copy={copy}
              subCopy={subCopy}
              imageData={contentMedia}
              links={links}
              eventLabel={label}
              onClick={onClick}
              useImageAsHeading={useImageAsHeading}
              backgroundColor={backgroundColor}
              color={color}
              mobileColor={offImageColor}
              textAlign={textAlign}
              contentWidth={contentWidth}
              shouldLazyLoad={shouldLazyLoad}
              isFullWidth={useFullWidth}
              pageName={pageName}
              pageInfo={pageInfo}
            />
            {/* use the first link to Cover the entire component with that click area using this hidden link */}
            {firstLink && !getIsGoodsLabelPage(pageName) && (
              <LandingPageLink
                className={css.coverLink}
                onClick={onClick}
                aria-hidden={true}
                tabIndex="-1"
                newWindow={firstLink.newWindow}
                url={firstLink.href}
                data-eventvalue={firstLink.gae}
                data-eventlabel={label}
              >
                {firstLink.text}
              </LandingPageLink>
            )}
          </article>
        </div>
      </div>
    </div>
  );
};

ZapposHero.propTypes = {
  slotName: PropTypes.string,
  slotIndex: PropTypes.number,
  onComponentClick: PropTypes.func,
  shouldLazyLoad: PropTypes.bool,
  slotDetails: PropTypes.shape({
    componentName: PropTypes.string,
    heading: PropTypes.string,
    containerBreak: PropTypes.bool,
    offImageColor: PropTypes.string,
    copy: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
        newWindow: PropTypes.bool,
        gae: PropTypes.string
      })
    ),
    eventLabel: PropTypes.string,
    media: PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        sources: PropTypes.arrayOf(
          PropTypes.shape({
            media: PropTypes.string,
            srcset: PropTypes.string
          })
        ),
        src: PropTypes.string,
        srcset: PropTypes.string,
        alt: PropTypes.string,
        // video specific props
        embeddedRatio: PropTypes.string,
        isEmbedded: PropTypes.bool,
        autoplay: PropTypes.bool,
        poster: PropTypes.string,
        loop: PropTypes.bool,
        tracks: PropTypes.array
      })
    ),
    content: PropTypes.shape({
      position: PropTypes.string,
      useImageAsHeading: PropTypes.bool,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
      textAlign: PropTypes.string,
      contentWidth: PropTypes.string,
      widthFitContent: PropTypes.bool
    })
  }),
  pageName: PropTypes.string
};

export default withErrorBoundary('ZapposHero', ZapposHero);
